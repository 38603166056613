/* eslint-disable @typescript-eslint/no-explicit-any */
/** 各類表單驗證 function FP 版本 */

import { isNil } from 'lodash-es';
import { ValidationRule } from 'quasar';

import {
  isEmpty, anyPass
} from 'remeda';

/** 建立符合 Quasar field rule 參數
 * 
 * @example
 * ```typescript
 * // 單一規則
 * const notEmptyRule = createRule([notEmpty], '不可為空');

 * // 加入 isOptional 變成選填
 * const mailRule = createRule([isOptional, isEmail], '必須為 E-mail');

 * // 展開成矩陣可以組成複合規則
 * const nameRule = [
 *  ...createRule([notEmpty], '不可為空'),
 *  ...createRule([(value: string) => value.length <= 20], '最多 20 個字元'),
 * ];
 * ```
 */
export function createRule(rules: any[], message?: string) {


  return [
    (data: any) => {
      try {
        for (const rule of rules) {
          const result = rule(data);
          if (typeof result === 'string') {
            return result;
          }

          if (result === true) {
            return true;
          }
        }
      } catch (error) {
        return `${error}`;
      }

      return message || true;
    },
  ] as ValidationRule[]
}

/** 數值是否大 > 0 */
export function isPositiveNumber(value: any) {
  return /^\d*[1-9]\d*$/.test(`${value}`);
}

/** 數值是否 >= 0 */
export function isNonNegativeNumber(value: any) {
  return /^\d*[0-9]\d*$/.test(`${value}`);
}

/** 數值是否為 `null`、`undefined` 或 `''` */
export function isOptional(value: any) {
  return isNil(value) || value === '';
}

export function notEmpty(value: any) {
  return !isEmpty(value);
}

/** YYYY-MM-DD 或 YYYY/MM/DD 格式 */
export function isDate(value: any) {
  const regex = /^(\d{4})[-/](\d{2})[-/](\d{2})$/;
  if (!regex.test(value)) {
    return false;
  }

  const [, year, month, day] = (regex.exec(value) as RegExpExecArray).map(Number);
  const date = new Date(year!, month! - 1, day);

  if (date.getFullYear() !== year ||
    date.getMonth() + 1 !== month ||
    date.getDate() !== day) {
    return false;
  }

  return true;
}

/** 僅限 YYYY-MM-DD HH:mm:ss 格式 */
export function isDateTime(value: any) {
  const regex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/;
  if (!regex.test(value)) {
    return false;
  }

  const [, year, month, day, hour, minute, second] = (regex.exec(value) as RegExpExecArray).map(Number);
  const date: Date = new Date(year!, month! - 1, day, hour, minute, second);

  if (date.getFullYear() != year || date.getMonth() + 1 != month ||
    date.getDate() != day || date.getHours() != hour ||
    date.getMinutes() != minute || date.getSeconds() != second) {
    return false;
  }

  return true;
}

export function isIframe(iframe: string): boolean {
  const regex = /<iframe[^>]*src="([^"]*)"[^>]*><\/iframe>/i;
  return regex.test(iframe);
}

export function isUrl(value: string): boolean {
  const regex = new RegExp(/^https?:\/\//);
  return !!regex.test(value);
}

export function isEmail(value: string): boolean {
  const regex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);
  return !!regex.test(value);
}

/** 是否為指定長度 */
export const isLengthToBe = (level: number) => {
  return (value: string) => value.length === level;
};
