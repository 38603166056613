import { defineConstants } from "./define";
/** 課程真實狀態 */
export const { COURSE_ACTUAL_STATE_KV: COURSE_ACTUAL_STATE, COURSE_ACTUAL_STATE_MAP_BY_KEY, COURSE_ACTUAL_STATE_MAP_BY_VALUE, COURSE_ACTUAL_STATE_VALUES } = defineConstants([
    {
        key: 'PENDING_REVIEW',
        value: 'pending-review',
        label: '待審核',
        colorName: 'negative',
    },
    {
        key: 'OVER',
        value: 'over',
        label: '已結束',
        colorName: 'grey',
    },
    {
        key: 'REJECTED',
        value: 'rejected',
        label: '駁回',
        colorName: 'amber',
    },
    {
        key: 'NOT_STARTED',
        value: 'not-started',
        label: '未開始',
        colorName: 'blue',
    },
    {
        key: 'IN_PROGRESS',
        value: 'in-progress',
        label: '報名中',
        colorName: 'green',
    },
], 'COURSE_ACTUAL_STATE');
/** 運送方式常數定義 */
export const { COURSE_DELIVERY_METHOD_KV: COURSE_DELIVERY_METHOD, COURSE_DELIVERY_METHOD_MAP_BY_KEY, COURSE_DELIVERY_METHOD_MAP_BY_VALUE, COURSE_DELIVERY_METHOD_VALUES } = defineConstants([
    {
        key: 'HOME',
        value: 'home',
        label: '宅配',
    },
    {
        key: 'SELF_PICKUP',
        value: 'self-pickup',
        label: '報到取物',
    },
    {
        key: 'MY_SHIP_711_CASH_ON_DELIVERY',
        value: 'my-ship-711-cash-on-delivery',
        label: '交貨便 - 貨到付款',
    },
    {
        key: 'MY_SHIP_711_PICKUP',
        value: 'my-ship-711-pickup',
        label: '交貨便 - 取貨',
    },
], 'COURSE_DELIVERY_METHOD');
/** 付款方式常數定義 */
export const { COURSE_PAYMENT_METHOD_KV: COURSE_PAYMENT_METHOD, COURSE_PAYMENT_METHOD_MAP_BY_KEY, COURSE_PAYMENT_METHOD_MAP_BY_VALUE, COURSE_PAYMENT_METHOD_VALUES } = defineConstants([
    {
        key: 'REMITTANCE',
        value: 'remittance',
        label: '匯款',
    },
    {
        key: 'CREDIT_CARD',
        value: 'credit-card',
        label: '刷卡',
    },
    {
        key: 'CASH_ON_DELIVERY_IN_STORE',
        value: 'cash-on-delivery-in-store',
        label: '門市貨到付款',
    },
], 'COURSE_PAYMENT_METHOD');
export const { COURSE_STATUS_KV: COURSE_STATUS, COURSE_STATUS_MAP_BY_KEY, COURSE_STATUS_MAP_BY_VALUE, COURSE_STATUS_VALUES } = defineConstants([
    {
        key: 'PENDING_REVIEW',
        value: 'pending-review',
        label: '待審核',
        description: '需管理方批准',
    },
    {
        key: 'AVAILABLE',
        value: 'available',
        label: '可用',
        description: '自動依照設定時間開始或結束',
    },
    {
        key: 'TERMINATED',
        value: 'terminated',
        label: '提早終止',
        description: '課程發起人可以提早結束課程',
    },
    {
        key: 'REJECT',
        value: 'reject',
        label: '駁回',
        description: '駁回課程項目，課程發起人只能重啟新課程',
    },
], 'COURSE_STATUS');
export const { COURSE_PLAN_EXPENSE_TYPE_KV: COURSE_PLAN_EXPENSE_TYPE, COURSE_PLAN_EXPENSE_TYPE_MAP_BY_KEY, COURSE_PLAN_EXPENSE_TYPE_MAP_BY_VALUE, COURSE_PLAN_EXPENSE_TYPE_VALUES } = defineConstants([
    {
        key: 'WITHOUT_MATERIALS',
        value: 'without-materials',
        label: '不含材料',
        subLabel: '不含任何材料，僅上課',
        description: '',
    },
    {
        key: 'WITH_MATERIALS',
        value: 'with-materials',
        label: '包含材料',
        subLabel: '課程包含材料費用',
        description: '',
    },
], 'COURSE_PLAN_EXPENSE_TYPE');
