import { computed, ref, shallowRef, triggerRef, watch } from 'vue';
import {
  useCareerModelRecruitApi,
  useUserCollectionApi
} from '@jgo-idea/api';
import {
  CareerModelRecruit, CareerModelRecruitSalaryType
} from '@jgo-idea/types';
import { defineStore, acceptHMRUpdate } from 'pinia';
import { instance } from '../common/api';
import { useArraySome, useAsyncState } from '@vueuse/core';
import { clone, equals, pipe } from 'remeda';
import { debounce, defaults } from 'lodash-es';
import { simplifyToDocIdDeep } from '@jgo-idea/common';
import { useUserStore } from './user.store';

export interface Filter {
  sortBy: 'publishedAt';
  isDescending: boolean;
  keyword: string;
  county: string;
  township: string;
  salaryType?: CareerModelRecruitSalaryType;
  salaryMax?: number;
  salaryMin?: number;
}

const descendingSortKeyMap: Record<
  Filter['sortBy'], boolean
> = {
  publishedAt: true,
  // popularity: true,
}
const filterDefault: Filter = {
  sortBy: 'publishedAt',
  isDescending: true,
  keyword: '',
  county: '',
  township: '',
  salaryType: undefined,
  salaryMax: undefined,
  salaryMin: undefined,
}

const QUANTITY_EACH_TIME = 30;

/**
 * TODO: 資料在指定條件排序時，_id 順序並非連續，所以使用 startId gt、lt 查詢，
 * 會導致資料缺失或重複，目前先使用 skip 來解決
 */
export const useCareerModelStore = defineStore('career-model', () => {
  const userStore = useUserStore();

  const careerModelRecruitApi = useCareerModelRecruitApi(instance);
  const userCollectionApi = useUserCollectionApi(instance);

  const filter = ref<Filter>(clone(filterDefault));
  watch(filter, () => refresh(), { deep: true });

  const pagination = ref({
    limit: QUANTITY_EACH_TIME,
    skip: 0,
  });
  const list = shallowRef<CareerModelRecruit[]>([]);

  const {
    isLoading: isCollectionListLoading,
    state: collectionList,
    execute: refreshCollectionList
  } = useAsyncState(
    async () => {
      if (!userStore.userInfo) {
        return [];
      }

      return userCollectionApi.findByList({
        careerModels: list.value.map((item) => item._id),
      });
    },
    [],
    {
      resetOnExecute: false,
      immediate: false,
    }
  );
  watch(list, () => refreshCollectionList());
  function isCollected(id: string) {
    return collectionList.value.some((item) => item.careerModel?._id === id);
  }

  const {
    isLoading, execute
  } = useAsyncState(
    async () => {
      const result = await careerModelRecruitApi.find({
        ...pagination.value,
        ...simplifyToDocIdDeep(filter.value),
        actualState: 'in-progress',
      });
      return result.data;
    },
    [],
    {
      shallow: true,
      resetOnExecute: false,
      immediate: false,
      onSuccess(data) {
        list.value.push(...data);
        triggerRef(list);
      }
    }
  );

  const refresh = debounce(() => {
    list.value.length = 0;
    pagination.value.skip = 0;
    return execute();
  }, 1000, {
    leading: true,
    trailing: false,
  });

  return {
    isLoading: useArraySome([isLoading], Boolean),
    list: computed(() => clone(list.value)),
    /** 清空資料，重新讀取 */
    refresh,
    /** 是否已收藏此項目 */
    isCollected,
    refreshCollectionList,

    filter: computed(() => clone(filter.value)),
    updateFilter(data: Partial<Filter>) {
      filter.value = defaults(
        {
          ...clone(data),
          keyword: data.keyword ?? '',
        },
        clone(filter.value),
      );

      if (data.sortBy) {
        filter.value.isDescending = descendingSortKeyMap[data.sortBy];
      }
    },
    /** 清空 filter 指定欄位，不指定則全部清空
     * 
     * values 表示指定要清空矩陣內的指定值，不指定則全部清空
     */
    clearFilter<
      Key extends (keyof Filter)
    >(keys?: Key[], rejectValues?: (typeof filterDefault[Key])) {
      if (!keys) {
        filter.value = clone(filterDefault);
        return;
      }

      keys.forEach((key) => {
        const newValue = pipe(filter.value[key],
          clone,
          (data) => {
            if (!Array.isArray(data) || !Array.isArray(rejectValues)) {
              return clone(filterDefault[key]);
            }

            return data.filter((datum) => {
              return !rejectValues.some((value) => {
                if (
                  typeof value === 'object'
                  && '_id' in value
                  && '_id' in datum
                ) {
                  return value._id === datum._id;
                }

                return equals(value, datum);
              });
            });
          },
        );

        // filterDefault 型別沒錯就一定沒問題
        (filter.value[key] as any) = newValue;
      });
    },

    /** startId 順序問題解決之前，只能使用 skip 解決 */
    next() {
      pagination.value.skip = list.value.length + QUANTITY_EACH_TIME;
      return execute();
    },
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCareerModelStore, import.meta.hot))
}
