import { useRequest } from './utils';
export function useUserCollectionApi(instance) {
    const request = useRequest(instance);
    async function create(param) {
        return request({
            method: 'post',
            url: `/api/v1/user-collection`,
            data: param
        });
    }
    async function findByList(param) {
        return request({
            method: 'post',
            url: `/api/v1/user-collections/list`,
            data: param
        });
    }
    async function findByFeature(param) {
        return request({
            method: 'get',
            url: `/api/v1/user-collections/feature`,
            data: param
        });
    }
    async function remove(param) {
        return request({
            method: 'delete',
            url: `/api/v1/user-collection`,
            data: param,
        });
    }
    return {
        create,
        findByList,
        findByFeature,
        remove,
    };
}
