<template>
  <div class=" rounded border border-[#F5F3EC]">
    <!-- header -->
    <div class="flex flex-nowrap px-4 py-2 bg-[#F5F3EC]">
      <div class="text-xs text-[#636363]">
        {{ creator?.groupBuyName }}
      </div>
      <q-space />
      <div
        class="text-xs text-[#198EE2]"
        @click="toCreator"
      >
        查看團購主
      </div>
    </div>

    <div class=" flex flex-col gap-2">
      <item-select-creator-table
        v-for="item, i in props.list"
        :key="i"
        v-model="modelValue"
        :group-buy-item="item"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, watchEffect } from 'vue';
import { ShoppingCartGroupBuy } from '../../stores/group-buy-shopping-cart.store';
import { ROUTE_NAME } from '../../router/router';

import ItemSelectCreatorTable from './item-select-creator-table.vue';

import { useVModel } from '@vueuse/core';
import { useRouter } from 'vue-router';

interface Props {
  /** 已選擇的團購 */
  modelValue: ShoppingCartGroupBuy[];
  /** 依團購主分類後的團購清單 */
  list: ShoppingCartGroupBuy[];
}
const props = withDefaults(defineProps<Props>(), {});


const emit = defineEmits<{
  (e: 'update:modelValue', value: Props['modelValue']): void;
}>();

const router = useRouter();

const modelValue = useVModel(props, 'modelValue', emit, { deep: true });

const creator = computed(() => props.list[0]?.groupBuy.creator);

function toCreator() {
  router.push({
    name: ROUTE_NAME.GROUP_BUY_CREATOR,
    params: {
      id: creator.value?._id,
    },
  });
}
</script>

<style scoped lang="sass">
</style>
