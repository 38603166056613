import { GROUP_BUY_ACTUAL_STATE_MAP_BY_KEY, } from '@jgo-idea/types';
import { piped, pipe, reduce, unique, filter } from 'remeda';
import dayjs from 'dayjs';
/** 判斷團購真實狀態。例如：未開始、團購中、已結束 */
export const getGroupBuyStateInfo = piped(({ status, startAt, endAt }) => {
    if (status === 'pending-review') {
        return GROUP_BUY_ACTUAL_STATE_MAP_BY_KEY.PENDING_REVIEW;
    }
    if (status === 'terminated') {
        return GROUP_BUY_ACTUAL_STATE_MAP_BY_KEY.OVER;
    }
    const currentTimestamp = dayjs().unix();
    if (status === 'reject') {
        return GROUP_BUY_ACTUAL_STATE_MAP_BY_KEY.REJECTED;
    }
    if (currentTimestamp > endAt) {
        return GROUP_BUY_ACTUAL_STATE_MAP_BY_KEY.OVER;
    }
    if (currentTimestamp < startAt) {
        return GROUP_BUY_ACTUAL_STATE_MAP_BY_KEY.NOT_STARTED;
    }
    return GROUP_BUY_ACTUAL_STATE_MAP_BY_KEY.IN_PROGRESS;
});
/** 取得 commodities 價格上下限 */
export function getGroupBuyPriceRange(data) {
    return pipe(data.commodities, reduce((acc, commodity) => {
        commodity.combinations.forEach((combination) => {
            acc[0] = Math.min(acc[0], combination.groupBuyPrice);
            acc[1] = Math.max(acc[1], combination.groupBuyPrice);
        });
        return acc;
    }, [Infinity, 0]), unique(), filter((value) => value !== Infinity));
}
