<template>
  <v-show-dialog
    :model-value="cartStore.visible"
    @update:model-value="cartStore.setVisible"
  >
    <flea-market-shopping-cart
      :key="key"
      class="!rounded-t-lg"
      @close="cartStore.setVisible(false)"
    />
  </v-show-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { whenever } from '@vueuse/core';
import { nanoid } from 'nanoid';

import VShowDialog from './v-show-dialog.vue';
import FleaMarketShoppingCart from './flea-market-shopping-cart.vue';

import { useFleaMarketShoppingCartStore } from '../stores/flea-market-shopping-cart.store';

const cartStore = useFleaMarketShoppingCartStore();

const key = ref('');
whenever(() => cartStore.visible, () => {
  key.value = nanoid();
});
</script>

<style scoped lang="sass">
</style>
