import { FeatureName } from '@jgo-idea/types';
import { useToggle } from '@vueuse/core';
import { defineStore, acceptHMRUpdate } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

export const useMainStore = defineStore('main', () => {
  const route = useRoute();
  const prevFeature = ref<FeatureName>();

  const [headerVisible, toggleHeader] = useToggle(true);
  const [footerVisible, toggleFooter] = useToggle(true);

  watch(() => route.path, () => {
    headerVisible.value = true;
    footerVisible.value = true;
  });

  const deepLinkUrl = ref('');
  /** path 為空，表示清空 */
  function setDeepLinkUrl(path = '') {
    deepLinkUrl.value = path;
  }

  return {
    /** deep link 開啟的連結 */
    deepLinkUrl: computed(() => deepLinkUrl.value),
    setDeepLinkUrl,

    headerVisible: computed(() => headerVisible.value),
    toggleHeader,
    footerVisible: computed(() => footerVisible.value),
    toggleFooter,

    prevFeature: computed(() => prevFeature.value),
    setPrevFeature(name: FeatureName) {
      prevFeature.value = name;
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMainStore, import.meta.hot))
}