import { useRequest } from './utils';
;
export function useFleaMarketSaleApi(instance) {
    const request = useRequest(instance);
    async function create(param) {
        return request({
            method: 'post',
            url: `/api/v1/flea-market-sale`,
            data: param
        });
    }
    async function findOne(id) {
        return request({
            method: 'get',
            url: `/api/v1/flea-market-sale/${id}`,
        });
    }
    async function find(param) {
        return request({
            method: 'get',
            url: `/api/v1/flea-market-sales`,
            data: param
        });
    }
    async function findVisitable(param) {
        return request({
            method: 'get',
            url: `/api/v1/flea-market-sales/visitable`,
            data: param
        });
    }
    async function findOrderable(param) {
        return request({
            method: 'get',
            url: `/api/v1/flea-market-sales/orderable`,
            data: param
        });
    }
    async function update(id, param) {
        return request({
            method: 'patch',
            url: `/api/v1/flea-market-sale/${id}`,
            data: param
        });
    }
    async function remove(id) {
        return request({
            method: 'delete',
            url: `/api/v1/flea-market-sale/${id}`,
        });
    }
    async function findLogs(id, param) {
        return request({
            method: 'get',
            url: `/api/v1/flea-market-sale/${id}/logs`,
            data: param,
        });
    }
    return {
        create,
        findOne,
        find,
        findVisitable,
        findOrderable,
        update,
        remove,
        findLogs,
    };
}
