<template>
  <div class="flex-col">
    <q-item
      v-for="option, i in props.options"
      :key="i"
      v-ripple
      tag="label"
      class="option-item rounded border mb-[0.375rem] py-4 "
      :active="modelValue === option.method"
      :active-class="props.activeClass"
      @click="setModelValue(option)"
    >
      <q-item-section side>
        <q-radio
          :model-value="modelValue"
          :val="option.method"
          color="white"
          class=" pointer-events-none"
        />
      </q-item-section>

      <q-item-section class=" flex flex-row !justify-start items-center flex-nowrap gap-4">
        <q-img
          :src="option.icon"
          class=" w-10"
        />
        <q-item-label>{{ option.label }}</q-item-label>
      </q-item-section>

      <q-item-section
        side
        class=" flex flex-row !items-center gap-2"
      >
        <div class="option-item-text text-xs">
          費用
        </div>
        <q-chip
          :label="`$${option.cost}`"
          outline
          square
          :color="modelValue === option.method ? 'white' : 'grey-7'"
          class=" justify-center"
        />
      </q-item-section>
    </q-item>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { useVModel } from '@vueuse/core';

interface Option {
  icon: string;
  label: string;
  method: string;
  cost: number;
}

interface Props {
  modelValue?: string;
  options: Option[];
  activeClass?: string;
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  activeClass: '',
});

const emit = defineEmits<{
  'update:modelValue': [value: Option]
}>()

// defineSlots<{
//   default?: () => any
//   item?: (props: { id: number }) => any
// }>();

const modelValue = useVModel(props, 'modelValue');
function setModelValue(value: Option) {
  modelValue.value = value.method;
}
</script>

<style scoped lang="sass">
:deep(.option-item)
  &.q-item--active
    .q-badge
      color: white !important
    .option-item-text
      color: white
</style>
