import { defineConstants } from "./define";
export const { CAREER_MODEL_RECRUIT_ACTUAL_STATE_KV: CAREER_MODEL_RECRUIT_ACTUAL_STATE, CAREER_MODEL_RECRUIT_ACTUAL_STATE_MAP_BY_KEY, CAREER_MODEL_RECRUIT_ACTUAL_STATE_MAP_BY_VALUE, CAREER_MODEL_RECRUIT_ACTUAL_STATE_VALUES } = defineConstants([
    {
        key: 'PENDING_REVIEW',
        value: 'pending-review',
        label: '待審核',
        colorName: 'negative',
    },
    {
        key: 'OVER',
        value: 'over',
        label: '已結束',
        colorName: 'grey',
    },
    {
        key: 'REJECTED',
        value: 'rejected',
        label: '駁回',
        colorName: 'amber',
    },
    {
        key: 'NOT_STARTED',
        value: 'not-started',
        label: '未開始',
        colorName: 'blue',
    },
    {
        key: 'IN_PROGRESS',
        value: 'in-progress',
        label: '招募中',
        colorName: 'green',
    },
], 'CAREER_MODEL_RECRUIT_ACTUAL_STATE');
export const { CAREER_MODEL_RECRUIT_STATUS_KV: CAREER_MODEL_RECRUIT_STATUS, CAREER_MODEL_RECRUIT_STATUS_MAP_BY_KEY, CAREER_MODEL_RECRUIT_STATUS_MAP_BY_VALUE, CAREER_MODEL_RECRUIT_STATUS_VALUES } = defineConstants([
    {
        key: 'DRAFT',
        value: 'draft',
        label: '草稿',
        description: '',
        colorName: 'orange',
    },
    {
        key: 'PENDING_REVIEW',
        value: 'pending-review',
        label: '待審核',
        description: '',
        colorName: 'negative',
    },
    {
        key: 'IN_PROGRESS',
        value: 'in-progress',
        label: '進行中',
        colorName: 'green',
    },
    {
        key: 'OVER',
        value: 'over',
        label: '已結束',
        colorName: 'grey',
    },
    {
        key: 'REJECTED',
        value: 'rejected',
        label: '駁回',
        colorName: 'amber',
    },
], 'CAREER_MODEL_RECRUIT_STATUS');
export const { CAREER_MODEL_RECRUIT_SALARY_TYPE_KV: CAREER_MODEL_RECRUIT_SALARY_TYPE, CAREER_MODEL_RECRUIT_SALARY_TYPE_MAP_BY_KEY, CAREER_MODEL_RECRUIT_SALARY_TYPE_MAP_BY_VALUE, CAREER_MODEL_RECRUIT_SALARY_TYPE_VALUES } = defineConstants([
    {
        key: 'RECIPROCITY',
        value: 'reciprocity',
        label: '互惠',
    },
    {
        key: 'COST',
        value: 'cost',
        label: '工本費',
    },
    {
        key: 'ONE_TIME',
        value: 'one-time',
        label: '單次酬勞',
    },
    {
        key: 'HOURLY',
        value: 'hourly',
        label: '時薪',
    },
], 'CAREER_MODEL_RECRUIT_SALARY_TYPE');
