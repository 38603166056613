import { defineConstants } from "./define";
export const { ART_PIECE_STATUS_KV: ART_PIECE_STATUS, ART_PIECE_STATUS_MAP_BY_KEY, ART_PIECE_STATUS_MAP_BY_VALUE, ART_PIECE_STATUS_VALUES } = defineConstants([
    {
        key: 'PENDING_REVIEW',
        value: 'pending-review',
        label: '待審核',
        description: '',
        colorName: 'negative',
    },
    {
        key: 'DRAFT',
        value: 'draft',
        label: '草稿',
        description: '',
        colorName: 'orange',
    },
    {
        key: 'AVAILABLE',
        value: 'available',
        label: '上線中',
        description: '',
        colorName: 'green',
    },
    {
        key: 'UNAVAILABLE',
        value: 'unavailable',
        label: '已下架',
        description: '',
        colorName: 'grey',
    },
], 'ART_PIECE_STATUS');
