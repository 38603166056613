import { useRequest } from './utils';
;
export function useCourseSettingApi(instance) {
    const request = useRequest(instance);
    async function get() {
        return request({
            method: 'get',
            url: `/api/v1/course-setting`,
        });
    }
    async function update(param) {
        return request({
            method: 'patch',
            url: `/api/v1/course-setting`,
            data: param
        });
    }
    async function findLogs(param) {
        return request({
            method: 'get',
            url: `/api/v1/course-setting/logs`,
            data: param,
        });
    }
    return {
        get,
        update,
        findLogs,
    };
}
