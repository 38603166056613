<template>
  <q-item
    v-ripple
    clickable
    class=" btn-shadow w-full px-6 py-3 h-auto"
  >
    <q-item-section>
      <q-item-label
        class=" text-base font-bold mb-1"
        :class="props.labelClass"
      >
        {{ props.label }}
      </q-item-label>
      <q-item-label
        v-if="props.caption"
        caption
        class=" text-[#999898]"
        :class="props.captionClass"
      >
        {{ props.caption }}
      </q-item-label>
    </q-item-section>

    <q-item-section
      v-if="props.icon"
      side
    >
      <q-icon
        :name="props.icon"
        :color="props.iconColor"
        size="1.5rem"
      />
    </q-item-section>

    <q-inner-loading
      :showing="props.loading"
      :color="loadingColor"
    />
  </q-item>
</template>

<!-- 清單按鈕 -->
<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  icon?: string;
  iconColor?: string;
  label: string;
  labelClass?: string;
  caption?: string;
  captionClass?: string;
  loading?: boolean;
  /** 如果沒提供則預設使用 iconColor */
  loadingColor?: string;
}
const props = withDefaults(defineProps<Props>(), {
  icon: '',
  iconColor: '',
  labelClass: '',
  captionClass: '',
  caption: '',
  loading: false,
  loadingColor: '',
});

const loadingColor = computed(() => props.loadingColor || props.iconColor);

// const emit = defineEmits<{
//   'update:modelValue': [value: string]
// }>()

// defineSlots<{
//   default?: () => any
//   item?: (props: { id: number }) => any
// }>();
</script>

<style scoped lang="sass">
</style>
