import { computed, readonly, ref, shallowRef, triggerRef, watch } from 'vue';
import {
  useFleaMarketSaleApi,
  useUserCollectionApi
} from '@jgo-idea/api';
import { Brand, CommodityCategory, FleaMarketCommodityCategory, FleaMarketSale, FleaMarketSaleCondition, User } from '@jgo-idea/types';
import { defineStore, acceptHMRUpdate } from 'pinia';
import { instance } from '../common/api';
import { refWithControl, useArraySome, useAsyncState, watchDebounced } from '@vueuse/core';
import { clone } from 'remeda';
import { defaultsDeep } from 'lodash-es';
import { simplifyToDocIdDeep } from '@jgo-idea/common';
import { useUserStore } from './user.store';

interface Filter {
  sortBy: 'publishedAt' | 'endAt';
  isDescending: boolean;
  /** 最低金額 */
  minPrice?: number;
  /** 最高金額 */
  maxPrice?: number;
  keyword: string;
  categories: FleaMarketCommodityCategory[];
  conditions: FleaMarketSaleCondition[];
}

const descendingSortKeyMap: Record<Filter['sortBy'], boolean> = {
  publishedAt: true,
  endAt: false,
}
const filterDefault: Filter = {
  sortBy: 'publishedAt',
  isDescending: true,
  keyword: '',
  minPrice: undefined,
  maxPrice: undefined,
  categories: [],
  conditions: [],
}

const QUANTITY_EACH_TIME = 30;

/**
 * TODO: 資料在指定條件排序時，_id 順序並非連續，所以使用 startId gt、lt 查詢，
 * 會導致資料缺失或重複，目前先使用 skip 來解決
 */
export const useFleaMarketStore = defineStore('flea-market', () => {
  const userStore = useUserStore();

  const fleaMarketApi = useFleaMarketSaleApi(instance);
  const userCollectionApi = useUserCollectionApi(instance);

  const filter = ref<Filter>(clone(filterDefault));
  watch(filter, () => refresh(), { deep: true });

  const pagination = ref({
    limit: QUANTITY_EACH_TIME,
    skip: 0,
  });
  const list = shallowRef<FleaMarketSale[]>([]);

  const {
    isLoading: isCollectionListLoading,
    state: collectionList,
    execute: refreshCollectionList
  } = useAsyncState(
    async () => {
      if (!userStore.userInfo) {
        return [];
      }

      return userCollectionApi.findByList({
        fleaMarkets: list.value.map((item) => item._id),
      });
    },
    [],
    {
      resetOnExecute: false,
      immediate: false,
    }
  );
  watch(list, () => refreshCollectionList());
  function isCollected(id: string) {
    return collectionList.value.some((item) => item.fleaMarket?._id === id);
  }

  const {
    isLoading, execute
  } = useAsyncState(
    async () => {
      const result = await fleaMarketApi.findOrderable({
        ...pagination.value,
        ...simplifyToDocIdDeep(filter.value),
      });
      return result.data;
    },
    [],
    {
      shallow: true,
      resetOnExecute: false,
      immediate: false,
      onSuccess(data) {
        list.value.push(...data);
        triggerRef(list);
      }
    }
  );

  function refresh() {
    list.value.length = 0;
    pagination.value.skip = 0;
    return execute();
  }

  return {
    isLoading: useArraySome([isLoading], Boolean),
    list: computed(() => clone(list.value)),
    /** 清空資料，重新讀取 */
    refresh,
    /** 是否已收藏此項目 */
    isCollected,
    refreshCollectionList,

    filter: computed(() => clone(filter.value)),
    updateFilter(data: Partial<Filter>) {
      filter.value = defaultsDeep(
        {
          ...data,
          keyword: data.keyword ?? '',
          minPrice: data.minPrice ? data.minPrice : undefined,
          maxPrice: data.maxPrice ? data.maxPrice : undefined,
        },
        clone(filter.value),
      );

      if (data.sortBy) {
        filter.value.isDescending = descendingSortKeyMap[data.sortBy];
      }
    },
    /** 清空 filter 指定欄位，不指定則全部清空 */
    clearFilter(keys?: (keyof Filter)[]) {
      if (!keys) {
        filter.value = clone(filterDefault);
        return;
      }

      keys.forEach((key) => {
        // filterDefault 型別沒錯就一定沒問題
        (filter.value[key] as any) = clone(filterDefault[key]);
      });
    },

    /** startId 順序問題解決之前，只能使用 skip 解決 */
    next() {
      pagination.value.skip = list.value.length + QUANTITY_EACH_TIME;
      return execute();
    },
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFleaMarketStore, import.meta.hot))
}
