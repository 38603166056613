import { createPipe, pipe, reduce } from 'remeda';
/** 取得團購訂單價格統計資料 */
export const getGroupBuyOrderPriceTotalData = createPipe((order) => {
    const { packingFee, commoditySubtotal, } = pipe(order.items, reduce((acc, item) => {
        const packingFeeSetting = item.groupBuy.packingFeeSetting;
        if (packingFeeSetting.method !== 'none') {
            const method = packingFeeSetting.method;
            if (method === 'per-order') {
                acc.packingFee = packingFeeSetting.value;
            }
            if (method === 'per-item') {
                acc.packingFee += packingFeeSetting.value * item.quantity;
            }
        }
        acc.commoditySubtotal += item.combination.groupBuyPrice * item.quantity;
        return acc;
    }, {
        packingFee: 0,
        commoditySubtotal: 0,
    }));
    return {
        packingFee,
        commoditySubtotal,
    };
});
