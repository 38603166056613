import { computed, ref, shallowRef, watch } from 'vue';
import {
  GroupBuyCommodityCombination,
  Commodity, GroupBuy
} from '@jgo-idea/types';
import { defineStore, acceptHMRUpdate } from 'pinia';
import { isIdEq, isCommodityCombinationEq, getGroupBuyStateInfo } from '@jgo-idea/common';
import { clone, filter, map, pipe, reject, } from 'remeda';
import { useUserStore } from './user.store';
import { useQuasar } from 'quasar';
import { createEventHook } from '@vueuse/core';

export interface ShoppingCartGroupBuy {
  groupBuy: GroupBuy;
  commodities: ShoppingCartCommodityItem[];
}

interface ShoppingCartCommodityItem {
  commodity: Commodity;
  combination: GroupBuyCommodityCombination;
  quantity: number;
}
export const useGroupBuyShoppingCartStore = defineStore('group-buy-shopping-cart', () => {
  const userStore = useUserStore();
  const $q = useQuasar();

  const visible = ref(false);
  function setVisible(value: boolean) {
    if (userStore.isUserInfoLoading) {
      return;
    }

    if (value && !userStore.userInfo) {
      $q.notify({
        type: 'negative',
        message: `請先登入會員`
      });
      visible.value = false;
      return;
    }

    visible.value = value;
  }

  const list = shallowRef<ShoppingCartGroupBuy[]>(
    pipe(
      localStorage.getItem('group-buy-shopping-cart'),
      (data) => {
        if (!data) return []
        return JSON.parse(data);
      }
    ),
  );
  watch(list, (value) => {
    localStorage.setItem('group-buy-shopping-cart', JSON.stringify(value));
  }, {
    deep: true,
  })

  function put(groupBuy: GroupBuy, item: ShoppingCartCommodityItem) {
    const existedGroupBuy = list.value.find((item) => isIdEq(item.groupBuy, groupBuy));
    /** 建立新團購 */
    if (!existedGroupBuy) {
      list.value.unshift(clone({
        groupBuy,
        commodities: [item],
      }));
      return;
    }

    /** 變更 item 數量 */
    const existedItem = existedGroupBuy.commodities.find(
      ({ commodity, combination }) => {
        if (!isIdEq(item.commodity, commodity)) {
          return false;
        }
        if (!isCommodityCombinationEq(item.combination.list, combination.list)) {
          return false;
        }

        return true;
      }
    );

    if (!existedItem) {
      existedGroupBuy.commodities.push(item);
      return;
    }

    existedItem.quantity = item.quantity;
  }

  /** 清除數量為 0 的項目或無任何商品的團購 */
  function removeInvalidItem(list: ShoppingCartGroupBuy[]) {
    const result = pipe(
      list,
      // clone,
      /** 清除數量為 0 的商品 */
      map((item) => ({
        ...item,
        commodities: filter(item.commodities, ({ quantity }) => quantity !== 0),
      })),
      /** 清除無任何商品團購 */
      filter((item) => item.commodities.length !== 0),
    );

    return result;
  }

  const groupBuysRefreshHook = createEventHook<GroupBuy[]>();

  return {
    visible: computed(() => clone(visible.value)),
    setVisible,

    list: computed(() => clone(list.value)),

    /** 新增或變更數量，要刪除項目將數量改為 0 即可刪除 */
    put: (...arg: Parameters<typeof put>) => {
      put(...arg);

      list.value = removeInvalidItem(list.value);
    },

    /** 清除 list 中無效團購 */
    clean() {
      list.value = pipe(
        list.value,
        // clone,
        filter((item) => {
          const info = getGroupBuyStateInfo(item.groupBuy);
          return info.value === 'in-progress';
        }),
      );
    },

    /** 用以購物車檢查團購庫存時，通知更新 */
    groupBuysRefreshHook,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGroupBuyShoppingCartStore, import.meta.hot))
}