import { CAREER_STANDARD_RECRUIT_ACTUAL_STATE_MAP_BY_KEY, } from '@jgo-idea/types';
import { createPipe } from 'remeda';
import dayjs from 'dayjs';
/** 判斷招募真實狀態。例如：未開始、招募中、已結束 */
export const getCareerStandardRecruitStateInfo = createPipe(({ status, startAt, endAt }) => {
    if (status === 'pending-review') {
        return CAREER_STANDARD_RECRUIT_ACTUAL_STATE_MAP_BY_KEY.PENDING_REVIEW;
    }
    if (status === 'over') {
        return CAREER_STANDARD_RECRUIT_ACTUAL_STATE_MAP_BY_KEY.OVER;
    }
    const currentTimestamp = dayjs().unix();
    if (status === 'rejected') {
        return CAREER_STANDARD_RECRUIT_ACTUAL_STATE_MAP_BY_KEY.REJECTED;
    }
    if (currentTimestamp > endAt) {
        return CAREER_STANDARD_RECRUIT_ACTUAL_STATE_MAP_BY_KEY.OVER;
    }
    if (currentTimestamp < startAt) {
        return CAREER_STANDARD_RECRUIT_ACTUAL_STATE_MAP_BY_KEY.NOT_STARTED;
    }
    return CAREER_STANDARD_RECRUIT_ACTUAL_STATE_MAP_BY_KEY.IN_PROGRESS;
});
