<template>
  <transition
    :name="transitionName"
    mode="out-in"
  >
    <slot />
  </transition>
</template>

<script setup lang="ts">
import { refDefault } from '@vueuse/core';
import { ref, toRef } from 'vue';
import { onBeforeRouteUpdate, onBeforeRouteLeave } from 'vue-router';

interface Props {
  name?: string;
}
const props = withDefaults(defineProps<Props>(), {
  name: 'opacity',
});

const name = ref<string | undefined>(props.name)
const transitionName = refDefault(name, 'opacity');

onBeforeRouteLeave(() => {
  name.value = undefined;
})

onBeforeRouteUpdate((to, from) => {
  const fromDepth = from.path.split('/').length
  const toDepth = to.path.split('/').length

  if (fromDepth === toDepth) {
    name.value = props.name;
    return;
  }

  name.value = toDepth > fromDepth ? 'page-in' : 'page-out'
})
</script>
