<template>
  <div class="flex-col flex-nowrap">
    <q-form
      class=" flex-col flex-1"
      @submit="handleSubmit"
    >
      <!-- 表單 -->
      <q-tab-panels
        :model-value="props.deliveryMethod"
        class="p-0 flex-1"
      >
        <q-tab-panel
          :name="DELIVERY_METHOD.HOME"
          class="flex-col flex-nowrap py-4 px-5 gap-6"
        >
          <q-btn
            class="flex-center p-4 text-[#198EE2] shadow-base"
            unelevated
            @click="openBuyerInfoSelectForm()"
          >
            (新增/選擇) 訂購人、收件人資料
          </q-btn>

          <form-item title="訂購人資料">
            <div class="border divide-y divide-solid divide-[#F1F1F1] rounded">
              <q-input
                v-model="buyer.name"
                label="姓名"
                v-bind="inputDefaultProps"
                :rules="notEmptyRule"
              />
              <q-input
                v-model="buyer.phone"
                label="電話"
                v-bind="inputDefaultProps"
                :rules="notEmptyRule"
              />
              <q-input
                v-model="buyer.email"
                label="電子信箱"
                v-bind="inputDefaultProps"
                :rules="emailRule"
              />
            </div>
          </form-item>

          <form-item>
            <template #title>
              <div class="flex-center mb-2">
                <div class="text-sm font-medium text-[#636363]">
                  收件人資料
                </div>
                <q-space />
                <div class="text-[#198EE2]">
                  <q-checkbox
                    v-model="isSameAsBuyer"
                    dense
                  />
                  同訂購人
                </div>
              </div>
            </template>

            <div class="border divide-y divide-solid divide-[#F1F1F1] rounded">
              <q-input
                v-model="receiver.name"
                label="姓名"
                v-bind="inputDefaultProps"
                :disable="isSameAsBuyer"
                :rules="notEmptyRule"
              />
              <q-input
                v-model="receiver.phone"
                label="電話"
                v-bind="inputDefaultProps"
                :disable="isSameAsBuyer"
                :rules="notEmptyRule"
              />
              <q-input
                v-model="receiver.email"
                label="電子信箱"
                v-bind="inputDefaultProps"
                :disable="isSameAsBuyer"
                :rules="emailRule"
              />
              <q-input
                v-model="receiver.address"
                label="地址"
                v-bind="inputDefaultProps"
                :rules="notEmptyRule"
              />
            </div>
          </form-item>
        </q-tab-panel>

        <q-tab-panel
          :name="DELIVERY_METHOD.SELF_PICKUP"
          class="flex-col flex-nowrap py-4 px-5 gap-6"
        >
          <div
            class="flex-center p-4 text-[#198EE2] shadow-base rounded"
            @click="openBuyerInfoSelectForm({ hideReceiver: true })"
          >
            (新增/選擇) 訂購人資料
          </div>

          <form-item
            title="訂購人資料"
            class=" flex-col gap-3"
          >
            <div class="border divide-y divide-solid divide-[#F1F1F1] rounded">
              <q-input
                v-model="buyer.name"
                label="姓名"
                v-bind="inputDefaultProps"
                :rules="notEmptyRule"
              />
              <q-input
                v-model="buyer.phone"
                label="電話"
                v-bind="inputDefaultProps"
                :rules="notEmptyRule"
              />
              <q-input
                v-model="buyer.email"
                label="電子信箱"
                v-bind="inputDefaultProps"
                :rules="emailRule"
              />
            </div>
          </form-item>

          <form-item
            title="收件人資料"
            class=" flex-col gap-3"
          >
            <div class="border border-negative text-negative text-center p-6 rounded">
              不需填寫收件人資料
            </div>
          </form-item>
        </q-tab-panel>

        <q-tab-panel
          v-for="method in [
            DELIVERY_METHOD.MY_SHIP_711_CASH_ON_DELIVERY,
            DELIVERY_METHOD.MY_SHIP_711_PICKUP
          ]"
          :key="method"
          :name="method"
          class="flex-col flex-nowrap py-4 px-5 gap-6"
        >
          <q-btn
            class="flex-center p-4 text-[#198EE2] shadow-base "
            unelevated
            @click="openMyShip711InfoSelectForm()"
          >
            (新增/選擇) 超商取貨資料
          </q-btn>

          <q-field
            v-model="myShip711.storeName"
            :rules="notEmptyRule"
            class="hidden"
          />

          <div
            v-if="myShip711.storeName"
            class="flex-col text-xs text-[#999898] border rounded p-4 gap-3"
          >
            <div class="flex-col">
              <div class=" text-xs text-[#999898]">
                門市名稱
              </div>
              <div class="text-sm font-medium text-[#636363]">
                {{ myShip711.storeName }} ({{ myShip711.storeNumber }})
              </div>
            </div>
            <div class="flex-col">
              <div class=" text-xs text-[#999898]">
                收件人名稱
              </div>
              <div class="text-sm font-medium text-[#636363]">
                {{ myShip711.receiverName }}
              </div>
            </div>
            <div class="flex-col">
              <div class=" text-xs text-[#999898]">
                連絡電話
              </div>
              <div class="text-sm font-medium text-[#636363]">
                {{ myShip711.receiverPhone }}
              </div>
            </div>
          </div>

          <div
            v-else
            class="border border-negative text-negative text-center p-6 rounded"
          >
            請新增或選擇收件資料
          </div>
        </q-tab-panel>
      </q-tab-panels>

      <div class=" bg-white">
        <div
          class="flex flex-nowrap items-center py-4 px-8
              bg-[#F5F3EC] text-group-buy text-xs font-medium"
          placeholder=""
        >
          <div class=" ">
            <q-icon name="payments" />
            總金額
          </div>
          <q-space />
          <div class="text-xs">
            NT${{ toPriceFormat(props.totalPrice) }}
          </div>
        </div>

        <!-- 按鈕 -->
        <div class="flex p-5 gap-4">
          <q-btn
            label="上一步"
            padding="1rem"
            unelevated
            class="flex-1 text-group-buy shadow-base"
            @click="emit('back')"
          />
          <q-btn
            label="送出訂單"
            padding="1rem"
            color="group-buy"
            class="flex-[2]"
            type="submit"
          />
        </div>
      </div>
    </q-form>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { DeliveryMethod, DELIVERY_METHOD } from '@jgo-idea/types';
import { toPriceFormat } from '@jgo-idea/common';
import { dialogPromisify, openBaseDialog, openUsingDialog } from '../../common/utils-quasar';
import { GroupBuyOrder } from '@jgo-idea/types';
import to from 'await-to-js';
import { pick } from 'remeda';
import { createRule, isEmail, notEmpty } from '../../common/validator';

import FormItem from '../form-item.vue';
import UserBuyerReceiverInfoSelectForm from '../user-buyer-receiver-info-select-form.vue';
import UserMyShip711InfoSelectForm from '../user-my-ship-711-info-select-form.vue';

import { useVModels } from '@vueuse/core';


interface Props {
  order?: GroupBuyOrder;
  buyer?: GroupBuyOrder['buyerInfo'];
  receiver?: GroupBuyOrder['receiverInfo'];
  myShip711?: GroupBuyOrder['myShip711Info'];
  deliveryMethod?: DeliveryMethod;
  totalPrice: number;
}
const props = withDefaults(defineProps<Props>(), {
  order: undefined,
  buyer: () => ({
    name: '',
    email: '',
    phone: '',
  }),
  receiver: () => ({
    name: '',
    email: '',
    phone: '',
    township: '',
    county: '',
    address: '',
  }),
  myShip711: () => ({
    storeName: '',
    storeNumber: '',
    storeAddress: '',
    receiverName: '',
    receiverPhone: '',
  }),
  deliveryMethod: undefined,
});

const emit = defineEmits<{
  (e: 'update:buyer', value: Props['buyer']): void;
  (e: 'update:receiver', value: Props['receiver']): void;
  (e: 'update:myShip711', value: Props['myShip711']): void;
  (e: 'back'): void;
  (e: 'next'): void;
}>();

const inputDefaultProps = {
  borderless: true,
  color: 'group-buy',
  class: 'px-4 !border-x-none',
  hideBottomSpace: true,
}

const { buyer, receiver, myShip711 } = useVModels(props, emit, { deep: true });
// 若 isSameAsBuyer 為 true，要同步 buyer 資料至 receiver
watch(buyer, (newValue) => {
  if (!isSameAsBuyer.value) return;

  receiver.value = {
    ...receiver.value,
    ...pick(newValue, ['name', 'email', 'phone']),
  }
}, {
  deep: true,
})

const notEmptyRule = createRule([notEmpty], '不可為空');
const emailRule = createRule([isEmail], '必須為 E-mail');

const isSameAsBuyer = ref(false);
watch(isSameAsBuyer, async (value) => {
  if (value) {
    const [error, result] = await to(
      dialogPromisify(
        openBaseDialog({
          title: '動作確認',
          message: `確認將訂購人資料填入收件人嗎？`
        })
      ),
    );
    if (error || result === 'n') {
      isSameAsBuyer.value = false;
      return;
    }

    receiver.value = {
      ...receiver.value,
      ...pick(buyer.value, ['name', 'email', 'phone']),
    }
    return;
  }
});

function openBuyerInfoSelectForm(param?: {
  hideBuyer?: boolean;
  hideReceiver?: boolean;
}) {
  const dialog = openUsingDialog(
    UserBuyerReceiverInfoSelectForm,
    {
      ...param,
      buyer: buyer.value,
      receiver: receiver.value,
      color: 'group-buy',
      'onUpdate:buyer'(data) {
        buyer.value = data;
        dialog.hide();
      },
      'onUpdate:receiver'(data) {
        receiver.value = data;
        dialog.hide();
      },
      onCancel() {
        dialog.hide();
      }
    },
    undefined,
    {
      // position: 'bottom',
      maximized: true,
      transitionShow: 'slide-up',
      transitionHide: 'slide-down',
    }
  );
}
function openMyShip711InfoSelectForm() {
  const dialog = openUsingDialog(
    UserMyShip711InfoSelectForm,
    {
      modelValue: myShip711.value,
      color: 'group-buy',
      'onUpdate:modelValue'(data) {
        myShip711.value = data;
        dialog.hide();
      },
      onCancel() {
        dialog.hide();
      }
    },
    undefined,
    {
      // position: 'bottom',
      maximized: true,
      transitionShow: 'slide-up',
      transitionHide: 'slide-down',
    }
  );
}


function handleSubmit() {
  emit('next');
}
</script>

<style scoped lang="sass">
:deep(.q-field__bottom)
  padding-bottom: 0.75rem
  border-top: 1px solid rgba(#444, 0.1)
</style>
