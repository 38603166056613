import to from 'await-to-js';
import { useRequest } from './utils';
export function useGroupBuySettingApi(instance) {
    const request = useRequest(instance);
    async function get() {
        return request({
            method: 'get',
            url: `/api/v1/group-buy-setting`,
        });
    }
    async function update(data) {
        return request({
            method: 'patch',
            url: `/api/v1/group-buy-setting`,
            data,
        });
    }
    async function findLogs() {
        const [err, data] = await to(request({
            method: 'get',
            url: `/api/v1/group-buy-setting/logs`,
        }));
        if (err) {
            return Promise.reject(err);
        }
        return data;
    }
    return {
        get,
        update,
        findLogs
    };
}
