import { RouteRecordRaw } from 'vue-router'
import { defineConstants } from '@jgo-idea/types';

export const {
  CAREER_STANDARD_ROUTE_NAME_KV: CAREER_STANDARD_ROUTE_NAME,
  CAREER_STANDARD_ROUTE_NAME_LIST,
  CAREER_STANDARD_ROUTE_NAME_VALUES,
} = defineConstants(
  [
    {
      key: 'CAREER_STANDARD',
      value: 'career-standard'
    },
    {
      key: 'CAREER_STANDARD_RECRUIT_LAUNCHER',
      value: 'career-standard-recruit-launcher',
    },
    {
      key: 'CAREER_STANDARD_RECRUIT_DETAIL',
      value: 'career-standard-recruit-detail'
    },
    {
      key: 'CAREER_STANDARD_RESUME_LAUNCHER',
      value: 'career-standard-resume-launcher'
    },
  ] as const,
  'CAREER_STANDARD_ROUTE_NAME'
);

export const careerStandardRoutes: RouteRecordRaw[] = [
  {
    path: `/career-standard`,
    name: CAREER_STANDARD_ROUTE_NAME.CAREER_STANDARD,
    component: () => import('../views/the-career-standard.vue'),
    meta: {
      mainColorName: 'career-standard'
    }
  },
  {
    path: `/career-standard-recruit-launcher`,
    name: CAREER_STANDARD_ROUTE_NAME.CAREER_STANDARD_RECRUIT_LAUNCHER,
    component: () => import('../views/the-career-standard-recruit-launcher.vue'),
    meta: {
      mainColorName: 'career-standard'
    }
  },

  {
    path: `/career-standard-recruit-detail/:id`,
    name: CAREER_STANDARD_ROUTE_NAME.CAREER_STANDARD_RECRUIT_DETAIL,
    component: () => import('../views/the-career-standard-recruit-detail.vue'),
    meta: {
      mainColorName: 'career-standard'
    }
  },

  {
    path: `/career-standard-resume-launcher`,
    name: CAREER_STANDARD_ROUTE_NAME.CAREER_STANDARD_RESUME_LAUNCHER,
    component: () => import('../views/the-career-standard-resume-launcher.vue'),
    meta: {
      mainColorName: 'career-standard'
    }
  },
]

