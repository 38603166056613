import { defineConstants } from "./define";
export const { CAREER_COMPANY_STATUS_KV: CAREER_COMPANY_STATUS, CAREER_COMPANY_STATUS_MAP_BY_KEY, CAREER_COMPANY_STATUS_MAP_BY_VALUE, CAREER_COMPANY_STATUS_VALUES } = defineConstants([
    {
        key: 'NORMAL',
        value: 'normal',
        label: '正常',
        colorName: 'positive',
    },
    {
        key: 'PENDING_REVIEW',
        value: 'pending-review',
        label: '待審核',
        description: '需管理方批准',
        colorName: 'red',
    },
    {
        key: 'SUSPENDED',
        value: 'suspended',
        label: '已停權',
        colorName: 'red',
    },
], 'CAREER_COMPANY_STATUS');
