<template>
  <div class="card flex-col bg-white">
    <div class=" text-base font-medium text-group-buy p-[0.875rem] text-center border-b">
      填寫轉帳後五碼
    </div>

    <q-form
      class="flex-col flex-1"
      @submit="handleSubmit"
    >
      <div class=" px-5 py-4 flex-col gap-6">
        <form-item title="匯款資料">
          <q-input
            v-model="inputValue"
            label="轉帳後五碼*"
            borderless
            :rules="notEmptyRule"
            v-bind="inputProp"
          />
        </form-item>
      </div>

      <q-space />

      <div class=" p-4 flex gap-4 bg-white">
        <q-btn
          label="取消"
          class=" shadow-base p-3 flex-1 text-group-buy"
          unelevated
          @click="emit('cancel')"
        />
        <q-btn
          label="確認"
          :color="props.submitBtnColor"
          class=" shadow-base p-3 flex-1 "
          unelevated
          type="submit"
        />
      </div>
    </q-form>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { createRule, notEmpty } from '../common/validator';
import { FIELD_DEFAULT_PROP } from '../constants';

import FormItem from './form-item.vue';

import { useVModel } from '@vueuse/core';

interface Props {
  modelValue?: string;
  inputColor?: string;
  submitBtnColor?: string;
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  inputColor: '',
  submitBtnColor: '',
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: Props['modelValue']): void;
  (e: 'submit', value: NonNullable<Props['modelValue']>): void;
  (e: 'cancel'): void;
}>();

const inputProp = computed(() => ({
  ...FIELD_DEFAULT_PROP,
  color: props.inputColor,
}));

const modelValue = useVModel(props, 'modelValue');
const inputValue = ref(modelValue.value || '');

const notEmptyRule = createRule([notEmpty], '不可為空');

function handleSubmit() {
  modelValue.value = inputValue.value;
  emit('submit', inputValue.value);
}
</script>

<style scoped lang="sass">
.card
  border-radius: 0.5rem 0.5rem 0 0 !important

.input-vertical-group
  display: flex
  flex-direction: column
  border: 1px solid rgba(#000, 0.2)
  border-radius: 0.25rem
  .q-field
    padding: 0 0.75rem
    border-bottom: 1px solid rgba(#000, 0.2)
    &:last-child
      border-bottom: none
</style>
