<template>
  <div class="p-3 gap-2 flex flex-nowrap justify-between items-center border-t border-[#F1F1F1] rounded relative">
    <q-img
      :src="image?.publicUrl"
      class=" w-16 h-16 rounded"
    />

    <div class=" text-[#636363]">
      <div class=" text-sm font-medium ">
        {{ variantLabel }}
      </div>
      <span class=" text-xs">
        團購價
        <span class="price">
          {{ combination.groupBuyPrice }}
        </span>
      </span>

      <number-picker
        v-model="quantity"
        dense
        size="0.7rem"
        :max="combination.remainingStockQuantity"
        :min="1"
        input-class="text-xs"
      />
    </div>

    <q-icon
      name="do_not_disturb_on"
      color="negative"
      size="0.75rem"
      class=" px-4 py-3 bg-white rounded shadow-sm"
      @click="remove"
    />
  </div>
</template>

<script setup lang="ts">
import { GroupBuy } from '@jgo-idea/types';
import { join, map, prop } from 'remeda';
import { clone, pipe, groupBy } from 'remeda';
import { computed, ref, watch } from 'vue';
import { openBaseDialog } from '../../common/utils-quasar';
import {
  ShoppingCartGroupBuy, useGroupBuyShoppingCartStore
} from '../../stores/group-buy-shopping-cart.store';
import { Dialog } from '@capacitor/dialog';

import NumberPicker from '../number-picker.vue';

interface Props {
  groupBuy: GroupBuy;
  commodityItem: ShoppingCartGroupBuy['commodities'][0];
}
const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  (e: 'update', data: Props['commodityItem']): void;
  (e: 'remove', data: Props['commodityItem']): void;
}>();

const shoppingCartStore = useGroupBuyShoppingCartStore();

const combination = computed(() => props.commodityItem.combination);
const image = computed(() => props.commodityItem.combination.image);

const variantLabel = computed(() => {
  const result = pipe(
    props.commodityItem.combination.list,
    map(prop('attribute')),
    join(', ')
  );

  return result;
});

async function remove() {
  const name = pipe(
    props.commodityItem.combination.list,
    map(prop('attribute')),
    join(', '),
  );

  const { value } = await Dialog.confirm({
    title: '確認刪除？',
    message: `確定要刪除「${props.commodityItem.commodity.name}-${name}」嗎？`,
  });
  if (value) {
    shoppingCartStore.put(props.groupBuy, clone({
      ...props.commodityItem,
      quantity: 0,
    }));

    emit('remove', props.commodityItem);
  }

  // Quasar Dialog 在 iOS 上非常卡
  // openBaseDialog({
  //   title: `確認刪除？`,
  //   message: `確定要刪除「${props.commodityItem.commodity.name}-${name}」嗎？`,
  // }).onOk((result) => {
  //   if (result !== 'y') return;

  //   shoppingCartStore.put(props.groupBuy, clone({
  //     ...props.commodityItem,
  //     quantity: 0,
  //   }));

  //   emit('remove', props.commodityItem);
  // });
}

const quantity = ref(props.commodityItem.quantity);
watch(quantity, (value) => {
  const newData = clone({
    ...props.commodityItem,
    quantity: value,
  });

  shoppingCartStore.put(props.groupBuy, newData);

  emit('update', newData);
});
</script>

<style scoped lang="sass">
</style>
