import { defineConstants } from "./define";
/** 品牌狀態常數定義 */
export const { BRAND_STATUS_KV: BRAND_STATUS, BRAND_STATUS_MAP_BY_KEY, BRAND_STATUS_MAP_BY_VALUE, BRAND_STATUS_VALUES } = defineConstants([
    {
        key: 'NORMAL',
        value: 'normal',
        label: '正常',
        colorName: 'positive',
    },
    {
        key: 'PENDING_REVIEW',
        value: 'pending-review',
        label: '待審核',
        colorName: 'red',
    },
], 'BRAND_STATUS');
