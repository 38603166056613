<template>
  <transition name="dialog-up">
    <div
      v-show="modelValue"
      class="v-show-dialog fixed left-0 bottom-0 w-full h-full z-[5000] "
    >
      <!-- backdrop -->
      <div
        class="backdrop absolute inset-0 bg-black/50"
        @click.stop="modelValue = false"
      />

      <div class="content w-full absolute bottom-0 max-h-[96vh] overflow-auto">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
/** 基於 v-show 的 dialog，避免在 iOS 上性能悲劇 */
import { ref, watch } from 'vue';

import { useVModel } from '@vueuse/core';
import { useRoute } from 'vue-router';

interface Props {
  modelValue: boolean;
  duration?: string;
}
const props = withDefaults(defineProps<Props>(), {
  duration: '0.3s'
});

const emit = defineEmits<{
  'update:modelValue': [value: boolean]
}>()

const route = useRoute();
const modelValue = useVModel(props, 'modelValue');

watch(() => route.fullPath, () => {
  modelValue.value = false;
});
</script>

<style scoped lang="sass">
.dialog-up-enter-active, .dialog-up-leave-active
  transition-duration: v-bind('props.duration')
  .backdrop, .content
    transition-duration: v-bind('props.duration')
.dialog-up-enter-from, .dialog-up-leave-to
  .backdrop
    opacity: 0
  .content
    transform: translateY(100%) !important

</style>


<style lang="sass">
.v-show-dialog
  .content
    & > div
      padding-bottom: calc(var(--dialog-offset) - constant(safe-area-inset-bottom)) !important
      padding-bottom: calc(var(--dialog-offset) - env(safe-area-inset-bottom)) !important
</style>