import { defineConstants } from "./define";
export const { GROUP_BUY_ACTUAL_STATE_KV: GROUP_BUY_ACTUAL_STATE, GROUP_BUY_ACTUAL_STATE_MAP_BY_KEY, GROUP_BUY_ACTUAL_STATE_MAP_BY_VALUE, GROUP_BUY_ACTUAL_STATE_VALUES } = defineConstants([
    {
        key: 'PENDING_REVIEW',
        value: 'pending-review',
        label: '待審核',
        colorName: 'negative',
    },
    {
        key: 'OVER',
        value: 'over',
        label: '已結束',
        colorName: 'grey',
    },
    {
        key: 'REJECTED',
        value: 'rejected',
        label: '駁回',
        colorName: 'amber',
    },
    {
        key: 'NOT_STARTED',
        value: 'not-started',
        label: '未開始',
        colorName: 'blue',
    },
    {
        key: 'IN_PROGRESS',
        value: 'in-progress',
        label: '團購中',
        colorName: 'green',
    },
], 'GROUP_BUY_ACTUAL_STATE');
export const { GROUP_BUY_STATUS_KV: GROUP_BUY_STATUS, GROUP_BUY_STATUS_MAP_BY_KEY, GROUP_BUY_STATUS_MAP_BY_VALUE, GROUP_BUY_STATUS_VALUES } = defineConstants([
    {
        key: 'PENDING_REVIEW',
        value: 'pending-review',
        label: '待審核',
        description: '需管理方批准',
    },
    {
        key: 'AVAILABLE',
        value: 'available',
        label: '可用',
        description: '自動依照設定時間開始或結束',
    },
    {
        key: 'TERMINATED',
        value: 'terminated',
        label: '提早終止',
        description: '團購主可以提早結束團購',
    },
    {
        key: 'REJECT',
        value: 'reject',
        label: '駁回',
        description: '駁回團購項目，團購主只能重啟新團購',
    },
], 'GROUP_BUY_STATUS');
/** 團購異常原因 */
export const { GROUP_BUY_ISSUE_KV: GROUP_BUY_ISSUE, GROUP_BUY_ISSUE_MAP_BY_KEY, GROUP_BUY_ISSUE_MAP_BY_VALUE, GROUP_BUY_ISSUE_VALUES } = defineConstants([
    {
        key: 'NO_QUALIFICATION_FOR_BRAND_LISTING',
        value: 'no-qualification-for-brand-listing',
        label: '無品牌上架資格',
        description: '通常是該團購主沒有被設定可上架的品牌時會出現此狀態，假設團購主 A 的可上架品牌有「GOGOBASH」、「杯豆」，但今天他想開一個「lifin.Lin」的團購商品，則在申請團購後就會進入待審核狀態，查看後台的待審核團購時會發現無法上架原因為「團購主無該品牌上架資格」',
    },
    {
        key: 'CUSTOMIZED_COMMODIFY',
        value: 'customized-commodify',
        label: '自行建立商品',
        description: '如果團購主上架是選擇自行建立新商品就會標註此狀態，因為選擇「自行建立商品」送出申請時會是待審核的團購',
    },
    {
        key: 'BRAND_PENDING_REVIEW',
        value: 'brand-pending-review',
        label: '品牌待審核',
        description: '如果團購主上架是選擇自行建立新商品，且在品牌是選擇自行建立的品牌，送出申請時會是待審核的團購，此時要先將品牌審核通過才行接續上架流程',
    },
], 'GROUP_BUY_ISSUE');
export const { GROUP_BUY_PACKING_FEE_CALCULATION_METHOD_KV: GROUP_BUY_PACKING_FEE_CALCULATION_METHOD, GROUP_BUY_PACKING_FEE_CALCULATION_METHOD_MAP_BY_KEY, GROUP_BUY_PACKING_FEE_CALCULATION_METHOD_MAP_BY_VALUE, GROUP_BUY_PACKING_FEE_CALCULATION_METHOD_VALUES } = defineConstants([
    {
        key: 'PER_ORDER',
        value: 'per-order',
        label: '每一筆訂單收一筆',
        description: '每一筆訂單收一筆',
    },
    {
        key: 'PER_ITEM',
        value: 'per-item',
        label: '每件商品收一筆',
        description: '每件商品收一筆',
    },
    {
        key: 'NONE',
        value: 'none',
        label: '無費用',
        description: '無費用',
    },
], 'GROUP_BUY_PACKING_FEE_CALCULATION_METHOD');
