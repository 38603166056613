<template>
  <q-menu
    ref="menuRef"
    anchor="top middle"
    self="bottom middle"
    :offset="[0, 0]"
    class="w-[100vw] !max-w-[100vw] shadow-none !bg-transparent"
  >
    <q-card class="shadow-3 bg-white m-4  rounded-lg overflow-hidden">
      <q-card-section class="bg-[#F5F3EC] p-4 text-base text-career-standard text-center">
        應徵功能選單
      </q-card-section>

      <q-card-section class="p-0 px-5">
        <q-tabs
          v-model="tab"
          active-color="primary"
        >
          <q-tab
            label="找工作"
            :name="TabName.APPLICANT"
          />
          <q-tab
            label="招募方功能"
            :name="TabName.RECRUITER"
          />
        </q-tabs>
      </q-card-section>

      <q-tab-panels
        v-model="tab"
        animated
      >
        <q-tab-panel
          :name="TabName.APPLICANT"
          class=" p-4 flex-col gap-2"
        >
          <q-item
            v-for="btn, i in applicantBtnList"
            :key="i"
            v-ripple
            clickable
            class="btn-shadow px-6 py-4 !h-auto"
            :class="btn.class"
            @click="btn.onClick"
          >
            <q-item-section>
              <q-item-label
                class="text-base font-medium"
                :class="`text-${btn.color}`"
              >
                {{ btn.label }}
              </q-item-label>
              <q-item-label
                v-if="btn.caption"
                caption
                :class="btn.captionClass"
              >
                {{ btn.caption }}
              </q-item-label>
            </q-item-section>
            <q-item-section avatar>
              <q-icon
                :color="btn.color"
                :name="btn.icon"
              />
            </q-item-section>
          </q-item>
        </q-tab-panel>

        <q-tab-panel
          :name="TabName.RECRUITER"
          class=" p-4 flex-col gap-2 relative"
        >
          <q-item
            v-for="btn, i in recruiterBtnList"
            :key="i"
            v-ripple
            clickable
            class="btn-shadow px-6 py-4 !h-auto"
            :class="btn.class"
            @click="btn.onClick"
          >
            <q-item-section>
              <q-item-label
                class="text-base font-medium"
                :class="`text-${btn.color}`"
              >
                {{ btn.label }}
              </q-item-label>
              <q-item-label
                v-if="btn.caption"
                caption
                :class="btn.captionClass"
              >
                {{ btn.caption }}
              </q-item-label>
            </q-item-section>
            <q-item-section avatar>
              <q-icon
                :color="btn.color"
                :name="btn.icon"
              />
            </q-item-section>
          </q-item>

          <div
            v-if="companies.length === 0"
            class="flex-col flex-center gap-3 absolute inset-0 m-4 px-9
                 text-[#198EE2] border-[#198EE2] border rounded bg-white"
          >
            <div class=" text-base font-medium">
              尚未建立公司資料
            </div>

            <q-btn
              unelevated
              class=" btn-shadow "
              @click="toSeller()"
            >
              <div class="flex flex-nowrap px-6">
                <div class=" px-10 whitespace-nowrap text-base">
                  招募方功能
                </div>
                <q-icon name="other_admission" />
              </div>
            </q-btn>

            <div class=" text-sm text-center">
              功能路徑：會員功能 → 應徵相關 → 招募方功能 → 公司及招募職缺管理
            </div>

            <q-inner-loading :showing="isLoading" />
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
  </q-menu>
</template>

<script setup lang="ts">
import { map, pipe } from 'remeda';
import { computed, nextTick, ref, watch } from 'vue';
import { instance } from '../../common/api';
import { ROUTE_NAME } from '../../router/router';
import { CareerCompany, FEATURE_KV, PaginatedData } from '@jgo-idea/types';

import { useRouter } from 'vue-router';
// import { useCareerStandardStore } from '../../stores/career-standard.store';
import { useUserStore } from '../../stores/user.store';
import { useAsyncState } from '@vueuse/core';
import { useCareerCompanyApi } from '@jgo-idea/api';
import { QMenu, useQuasar } from 'quasar';
import { useCareerStandardStore } from '../../stores/career-standard.store';

enum TabName {
  APPLICANT = '找工作',
  RECRUITER = '招募方功能',
}

interface Btn {
  class?: string;
  label: string;
  caption?: string;
  captionClass?: string;
  icon?: string;
  color?: string;
  onClick?: () => void;
}

const emit = defineEmits<{
  'update': [value: `${TabName}`]
}>()

const router = useRouter();
const userStore = useUserStore();
const $q = useQuasar();
const careerCompanyApi = useCareerCompanyApi(instance);
const careerStandardStore = useCareerStandardStore();

const menuRef = ref<InstanceType<typeof QMenu>>();

const tab = ref(TabName.APPLICANT);
watch(tab, async () => {
  emit('update', tab.value)
  await nextTick();
  menuRef.value?.updatePosition();
});

const {
  state: companies,
  isLoading,
} = useAsyncState(async () => {
  const owner = userStore.userInfo?._id;
  if (!owner) return [];

  let result: PaginatedData<CareerCompany>;

  result = await careerCompanyApi.find({
    limit: 1,
    skip: 0,
    owner,
  });
  if (result.data.length > 0) {
    return result.data;
  }

  result = await careerCompanyApi.find({
    limit: 1,
    skip: 0,
    coManager: owner,
  });

  return result.data;
}, []);

const applicantBtnList: Btn[] = [
  {
    icon: 'task',
    color: 'career-standard',
    label: '已應徵的項目',
    caption: '追蹤投遞給多家公司的履歷狀態',
    onClick() {
      if (!userStore.userInfo) {
        $q.notify({
          type: 'warning',
          message: `請先登入會員`
        });

        router.push({ name: ROUTE_NAME.ACCOUNT_LOGIN });
        return;
      }

      router.push({
        name: ROUTE_NAME.CAREER_APPLICATION,
        query: {
          tab: FEATURE_KV.CAREER_STANDARD,
        },
      });
    }
  },
  {
    icon: 'badge',
    color: 'career-standard',
    label: '我的應徵履歷',
    caption: '編輯應徵履歷內容',
    onClick() {
      if (!userStore.userInfo) {
        $q.notify({
          type: 'warning',
          message: `請先登入會員`
        });

        router.push({ name: ROUTE_NAME.ACCOUNT_LOGIN });
        return;
      }

      router.push({
        name: ROUTE_NAME.CAREER_STANDARD_RESUME_LAUNCHER,
      });
    }
  },
  {
    icon: 'cached',
    color: 'white',
    class: 'bg-career-standard',
    label: '重新整理 - 應徵列表頁面',
    caption: '重新讀取最新內容',
    captionClass: 'text-white',
    onClick() {
      careerStandardStore.refresh();
    }
  },
]

const recruiterBtnList: Btn[] = [
  {
    icon: 'work_history',
    color: 'career-standard',
    label: '公司及招募職缺管理',
    caption: '管理公司及應徵中的職缺內容',
    onClick() {
      router.push({
        name: ROUTE_NAME.ACCOUNT_CAREER_COMPANY_MANAGER,
      });
    }
  },
  {
    icon: 'group_add',
    color: 'career-standard',
    label: '發起招募',
    caption: '建立應徵的職缺',
    onClick() {
      router.push({
        name: ROUTE_NAME.CAREER_STANDARD_RECRUIT_LAUNCHER,
      });
    }
  },
  {
    icon: 'data_loss_prevention',
    color: 'career-standard',
    label: '查看履歷',
    caption: '查看應徵者或是模特的履歷',
    onClick() {
      router.push({
        name: ROUTE_NAME.CAREER_APPLICATION_MANAGER,
      });
    }
  },
]

function toSeller() {
  if (!userStore.userInfo) {
    $q.notify({
      message: '請先登入會員',
      color: 'negative',
    });

    router.push({ name: ROUTE_NAME.ACCOUNT_LOGIN });
    return;
  }

  router.push({
    name: ROUTE_NAME.ACCOUNT_PROFILE_CAREER,
    query: {
      tab: 'recruit',
    }
  });
}
</script>

<style scoped lang="sass">
</style>
