<template>
  <div class="flex-col gap-3 border border-[#F5F3EC]">
    <!-- header -->
    <div class=" text-xs bg-[#F5F3EC] text-[#636363] py-[0.375rem] px-4">
      {{ creator?.name }}
    </div>

    <!-- 團購 -->
    <div class="flex-col gap-2 px-3">
      <div
        v-for="item in fleaMarketList"
        :key="item.fleaMarket._id"
        class=" border flex-col rounded flex-nowrap"
      >
        <div class="flex flex-nowrap">
          <q-img
            :src="item.imageSrc"
            class=" w-16 h-16 m-4 rounded"
          />

          <div class=" py-3 flex-1 text-sm text-[#636363]">
            <div class=" font-medium whitespace-pre-line">
              {{ item.fleaMarket.name }}
            </div>

            <div class=" text-xs text-[#999898]">
              共 {{ item.itemQuantity }} 個項目
            </div>

            <div class=" mt-2">
              小計：NT${{ toPriceFormat(item.totalPrice) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class=" flex-col flex-center pb-3">
      <div class="flex text-sm font-medium gap-4">
        <div class=" w-14 ">
          商品小計
        </div>
        <div class="w-14">
          NT${{ toPriceFormat(commodityTotalPrice) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  StorageFile
} from '@jgo-idea/types';
import { toPriceFormat } from '@jgo-idea/common';
import { pipe, map, clone, reduce, first, prop, equals, createPipe, find, isDefined } from 'remeda';
import { computed, ref, watch } from 'vue';
import { ShoppingCartFleaMarket } from '../../stores/flea-market-shopping-cart.store';
import { cond } from '@jgo-idea/common';

import FormItem from '../form-item.vue';

import placeholder from '../../assets/placeholder.jpg';

import { useVModels } from '@vueuse/core';

interface Props {
  /** 已選擇團購 */
  fleaMarkets: ShoppingCartFleaMarket[];
  commodityTotalPrice?: number;
}
const props = withDefaults(defineProps<Props>(), {
  commodityTotalPrice: 0,
});

const emit = defineEmits<{
  (e: 'update:deliverySetting', value: string): void;
  (e: 'update:commodityTotalPrice', value: string): void;
}>();

const {
  commodityTotalPrice
} = useVModels(props, emit, { deep: true });

const fleaMarketList = computed(() => pipe(
  props.fleaMarkets,
  clone,
  map((item) => {
    const totalPrice = pipe(
      item.commodities,
      reduce((acc, commodity) => {
        const price = commodity.specification.price * commodity.quantity;
        acc += price;
        return acc;
      }, 0),
    );

    const imageSrc = pipe(
      [
        item.fleaMarket.cover?.publicUrl,
        item.fleaMarket.images?.[0]?.publicUrl,
        item.fleaMarket.specifications?.[0]?.image?.publicUrl,
        placeholder,
      ],
      find(isDefined),
    );

    const itemQuantity = pipe(
      item.commodities,
      reduce((acc, commodity) => {
        acc += commodity.quantity;
        return acc;
      }, 0),
    );

    return {
      ...item,
      totalPrice,
      imageSrc,
      itemQuantity,
    }
  }),
));

watch(fleaMarketList, (list) => {
  commodityTotalPrice.value = pipe(
    list,
    reduce((acc, item) => acc + item.totalPrice, 0),
  );
}, {
  immediate: true,
  deep: true,
});

const creator = computed(() => props.fleaMarkets[0]?.fleaMarket.creator);

</script>

<style scoped lang="sass">
</style>
