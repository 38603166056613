import axios from 'axios';
import router, { ROUTE_NAME } from '../router/router';
import to from 'await-to-js';
import { Quasar, Platform } from 'quasar'

const baseUrl = Platform.is.nativeMobile
  ? import.meta.env.VITE_APP_API_BASE_URL
  : import.meta.env.VITE_WEB_API_BASE_URL;

export const instance = axios.create({
  baseURL: `${baseUrl ?? ''}`,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;

    // if ([401].includes(response.status)) {
    //   router.push({ name: ROUTE_NAME.ACCOUNT_LOGIN });
    // }

    const message = response?.data?.message ?? `${error}`;
    return Promise.reject(new Error(message));
  }
)
