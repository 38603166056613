import to from 'await-to-js';
export function useRequest(instance) {
    return async (params) => {
        const { method, url, data, headers } = params;
        const inBody = ['get', 'delete'].includes(method);
        const [err, result] = await to(instance.request({
            method,
            url,
            data: inBody ? undefined : data,
            params: !inBody ? undefined : data,
            headers,
        }));
        if (err) {
            return Promise.reject(err);
        }
        return result.data;
    };
}
