<template>
  <div class="flex-col gap-1">
    <div
      v-for="item, i in list"
      :key="i"
    >
      <q-item
        v-ripple
        tag="label"
        class=" px-2 py-0"
        :class="item.class"
        :dense="dense"
      >
        <q-item-section avatar>
          <q-radio
            :model-value="toString(selectedValue)"
            :val="toString(item.value)"
            :color="color"
            @click="handleUpdate(item.value)"
          />
        </q-item-section>

        <slot
          name="option"
          :option="item"
        >
          <q-item-section>
            <q-item-label class=" text-sm">
              {{ item.label }}
            </q-item-label>
            <q-item-label
              v-if="item.caption"
              caption
            >
              {{ item.caption }}
            </q-item-label>
          </q-item-section>
        </slot>
      </q-item>

      <slot
        v-if="toString(selectedValue) === toString(item.value)"
        :name="`after-${item.name}-option`"
        :option="item"
      />
    </div>
  </div>
</template>

<script lang="ts">
export interface Option<Value = undefined> {
  value: Value;
  activeClass?: string;
  class?: string;
  label?: string;
  caption?: string;
  /** 用於 option slot */
  name?: string;
}
</script>

<script setup lang="ts" generic="ModelValue">
import { useVModel } from '@vueuse/core';
import { clone, equals } from 'remeda';
import { Ref, ref, UnwrapRef, watch } from 'vue';
import { computedWatch } from '../composables/computed-watch';

interface Props {
  modelValue?: ModelValue;
  options: Option<ModelValue>[];
  color?: string;
  dense?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  color: '',
  dense: false,
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: ModelValue): void;
}>();

/** 
 * 可以使用 `after-${item.name}-option` 動態 slot，會在
 * 
 * 目前已選擇選項的後面，
 */
// TODO: 研究動態 slot 怎麼定義
// defineSlots<{
//   option?: (props: {
//     option: NonNullable<typeof list['value']>[number]
//   }) => any;

//   [`after-[name]-option`]?: (props: {
//     option: NonNullable<typeof list['value']>[number]
//   }) => any;
// }>();

const modelValue = useVModel(props, 'modelValue');
const selectedValue = ref(clone(props.modelValue)) as Ref<ModelValue | undefined>;
watch(modelValue, (value) => {
  selectedValue.value = clone(value);
});

function handleUpdate(value: ModelValue | UnwrapRef<ModelValue>) {
  selectedValue.value = value as ModelValue;
  emit('update:modelValue', value as ModelValue);
}

const list = computedWatch(() => ({
  options: props.options,
  form: selectedValue.value,
}), undefined, (data) => {
  if (!data) {
    return [];
  }

  return data.options.map((option) => {
    const classes = [option.class];
    const selected = equals(option.value, data.form);

    if (selected) {
      classes.push(option.activeClass);
    }

    return {
      ...option,
      class: classes,
      selected,
    }
  });
});

function toString(data: any) {
  if (typeof data === 'boolean') {
    return String(data)
  }

  try {
    if ('_id' in data) {
      return `${data._id}`;
    }

    return JSON.stringify(data);
  } catch (error) {
    return String(data)
  }
}
</script>

<style scoped lang="sass">
</style>
