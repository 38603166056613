import { defineConstants } from './define';
export const { FEATURE_KV, FEATURE_MAP_BY_KEY, FEATURE_MAP_BY_VALUE, FEATURE_LIST, FEATURE_VALUES, FEATURE_KEYS, } = defineConstants([
    {
        key: 'GROUP_BUY',
        value: 'group-buy',
        label: '團購',
        colorName: 'group-buy',
        iconName: 'local_mall',
    },
    {
        key: 'COURSE',
        value: 'course',
        label: '課程',
        colorName: 'course',
        iconName: 'school',
    },
    {
        key: 'FLEA_MARKET',
        value: 'flea-market',
        label: '二手',
        colorName: 'flea-market',
        iconName: 'handshake',
    },
    {
        key: 'ART_PIECE',
        value: 'art-piece',
        label: '作品',
        colorName: 'art-piece',
        iconName: 'palette',
    },
    {
        key: 'CAREER_STANDARD',
        value: 'career-standard',
        label: '應徵',
        colorName: 'career-standard',
        iconName: 'work',
    },
    {
        key: 'CAREER_MODEL',
        value: 'career-model',
        label: '模特',
        colorName: 'career-model',
        iconName: 'groups_3',
    },
], 'FEATURE');
