import { DeliveryMethod } from '@jgo-idea/types'
import { trim } from 'ramda';
import { pipe, filter, isDefined, map, createPipe } from 'remeda';

import icon711 from '../assets/7-eleven.svg';
import iconDelivery from '../assets/delivery.svg';
import iconShippingProduct from '../assets/shipping-product.svg';

export const FIELD_DEFAULT_PROP = {
  outlined: true,
}

export const DELIVERY_METHOD_ICON_MAP: Record<string, string> = {
  'home': iconDelivery,
  'my-ship-711-cash-on-delivery': icon711,
  'my-ship-711-pickup': icon711,
  'self-pickup': iconShippingProduct,
}

export const BANK_LIST = pipe(
  `4 臺灣銀行股份有限公司
5 臺灣土地銀行股份有限公司
6 合作金庫商業銀行股份有限公司
7 第一商業銀行股份有限公司
8 華南商業銀行股份有限公司
9 彰化商業銀行股份有限公司
11 上海商業儲蓄銀行股份有限公司
12 台北富邦商業銀行股份有限公司
13 國泰世華商業銀行股份有限公司
15 中國輸出入銀行
16 高雄銀行股份有限公司
17 兆豐國際商業銀行股份有限公司
20 日商瑞穗銀行股份有限公司
21 花旗(台灣)商業銀行股份有限公司
22 美商美國銀行股份有限公司
23 泰國盤谷銀行股份有限公司
25 菲商菲律賓首都銀行股份有限公司
28 美商美國紐約梅隆銀行股份有限公司
29 新加坡商大華銀行有限公司
30 美商道富銀行股份有限公司
37 法商法國興業銀行股份有限公司
39 澳商澳盛銀行集團股份有限公司
48 王道商業銀行股份有限公司
50 臺灣中小企業銀行股份有限公司
52 渣打國際商業銀行股份有限公司
53 台中商業銀行股份有限公司
54 京城商業銀行股份有限公司
60 兆豐票券金融股份有限公司
61 中華票券金融股份有限公司
62 國際票券金融股份有限公司
63 大中票券金融股份有限公司
65 台灣票券金融股份有限公司
66 萬通票券金融股份有限公司
72 德商德意志銀行股份有限公司
75 香港商東亞銀行有限公司
76 美商摩根大通銀行股份有限公司
78 新加坡商星展銀行股份有限公司
81 滙豐(台灣)商業銀行股份有限公司
82 法商法國巴黎銀行股份有限公司
83 英商渣打銀行股份有限公司
85 新加坡商新加坡華僑銀行股份有限公司
86 法商東方匯理銀行股份有限公司
92 瑞士商瑞士銀行股份有限公司
93 荷蘭商安智銀行股份有限公司
97 美商富國銀行股份有限公司
98 日商三菱日聯銀行股份有限公司
101 瑞興商業銀行股份有限公司
102 華泰商業銀行股份有限公司
103 臺灣新光商業銀行股份有限公司
104 有限責任台北市第五信用合作社
108 陽信商業銀行股份有限公司
114 有限責任基隆第一信用合作社
115 有限責任基隆市第二信用合作社
118 板信商業銀行股份有限公司
119 有限責任淡水第一信用合作社
120 有限責任新北市淡水信用合作社
124 有限責任宜蘭信用合作社
127 有限責任桃園信用合作社
130 有限責任新竹第一信用合作社
132 有限責任新竹第三信用合作社
146 有限責任台中市第二信用合作社
147 三信商業銀行股份有限公司
158 有限責任彰化第一信用合作社
161 有限責任彰化第五信用合作社
162 有限責任彰化第六信用合作社
163 有限責任彰化第十信用合作社
165 保證責任彰化縣鹿港信用合作社
178 保證責任嘉義市第三信用合作社
188 有限責任臺南第三信用合作社
204 保證責任高雄市第三信用合作社
215 有限責任花蓮第一信用合作社
216 有限責任花蓮第二信用合作社
222 保證責任澎湖縣第一信用合作社
223 有限責任澎湖第二信用合作社
224 有限責任金門縣信用合作社
251 華南金融控股股份有限公司
252 富邦金融控股股份有限公司
253 中華開發金融控股股份有限公司
254 國泰金融控股股份有限公司
255 中國信託金融控股股份有限公司
256 永豐金融控股股份有限公司
257 玉山金融控股股份有限公司
258 元大金融控股股份有限公司
259 台新金融控股股份有限公司
260 新光金融控股股份有限公司
261 兆豐金融控股股份有限公司
262 第一金融控股股份有限公司
264 國票金融控股股份有限公司
265 臺灣金融控股股份有限公司
266 合作金庫金融控股股份有限公司
321 日商三井住友銀行股份有限公司
324 美商花旗銀行股份有限公司
325 香港商香港上海滙豐銀行股份有限公司
326 西班牙商西班牙對外銀行股份有限公司
328 法商法國外貿銀行股份有限公司
329 印尼商印尼人民銀行股份有限公司
330 韓商韓亞銀行股份有限公司
372 大慶票券金融股份有限公司
375 合作金庫票券金融股份有限公司
380 大陸商中國銀行股份有限公司
381 大陸商交通銀行股份有限公司
382 大陸商中國建設銀行股份有限公司
388 全盈支付金融科技股份有限公司
389 全支付電子支付股份有限公司
390 悠遊卡股份有限公司
391 一卡通票證股份有限公司
392 愛金卡股份有限公司
394 國際連股份有限公司
395 橘子支行動支付股份有限公司
396 街口電子支付股份有限公司
397 歐付寶電子支付股份有限公司
398 簡單行動支付股份有限公司
803 聯邦商業銀行股份有限公司
805 遠東國際商業銀行股份有限公司
806 元大商業銀行股份有限公司
807 永豐商業銀行股份有限公司
808 玉山商業銀行股份有限公司
809 凱基商業銀行股份有限公司
810 星展(台灣)商業銀行股份有限公司
812 台新國際商業銀行股份有限公司
816 安泰商業銀行股份有限公司
822 中國信託商業銀行股份有限公司
823 將來商業銀行股份有限公司
824 連線商業銀行股份有限公司
826 樂天國際商業銀行股份有限公司
849 臺灣大來卡股份有限公司
956 財團法人聯合信用卡處理中心
958 新加坡商萬事達卡股份有限公司台灣分公司
959 台灣威士卡股份有限公司
960 台灣樂天信用卡股份有限公司
975 台灣美國運通國際股份有限公司
979 香港商台灣環滙亞太信用卡股份有限公司台灣分公司
980 台灣吉世美國際股份有限公司
`.trim().split('\n'),
  filter(isDefined),
  map(
    createPipe(
      trim,
      (data) => data.split(' '),
      ([code, name]) => ({ code: code?.padStart(3, '0'), name })
    ),
  ),
)

export const TAIWAN_COUNTY_TOWNSHIP_MAP: Record<string, string[]> = {
  '新北市': [
    '板橋區', '三重區', '中和區', '永和區', '新莊區', '新店區', '樹林區',
    '鶯歌區', '三峽區', '淡水區', '汐止區', '瑞芳區', '土城區', '蘆洲區',
    '五股區', '泰山區', '林口區', '深坑區', '石碇區', '坪林區', '三芝區',
    '石門區', '八里區', '平溪區', '雙溪區', '貢寮區', '金山區', '萬里區',
    '烏來區'
  ],
  '臺北市': [
    '松山區', '信義區', '大安區', '中山區', '中正區',
    '大同區', '萬華區', '文山區', '南港區', '內湖區', '士林區', '北投區'
  ],
  '桃園市': [
    '桃園區', '中壢區', '大溪區', '楊梅區', '蘆竹區', '大園區',
    '龜山區', '八德區', '龍潭區', '平鎮區', '新屋區', '觀音區', '復興區'
  ],
  '臺中市': [
    '中區', '東區', '南區', '西區', '北區', '西屯區', '南屯區', '北屯區',
    '豐原區', '東勢區', '大甲區', '清水區', '沙鹿區', '梧棲區', '后里區',
    '神岡區', '潭子區', '大雅區', '新社區', '石岡區', '外埔區', '大安區',
    '烏日區', '大肚區', '龍井區', '霧峰區', '太平區', '大里區', '和平區'
  ],
  '臺南市': [
    '新營區', '鹽水區', '白河區', '柳營區', '後壁區', '東山區', '麻豆區',
    '下營區', '六甲區', '官田區', '大內區', '佳里區', '學甲區', '西港區',
    '七股區', '將軍區', '北門區', '新化區', '善化區', '新市區', '安定區',
    '山上區', '玉井區', '楠西區', '南化區', '左鎮區', '仁德區', '歸仁區',
    '關廟區', '龍崎區', '永康區', '東區', '南區', '北區', '安南區', '安平區',
    '中西區'
  ],
  '高雄市': [
    '鹽埕區', '鼓山區', '左營區', '楠梓區', '三民區', '新興區', '前金區',
    '苓雅區', '前鎮區', '旗津區', '小港區', '鳳山區', '林園區', '大寮區',
    '大樹區', '大社區', '仁武區', '鳥松區', '岡山區', '橋頭區', '燕巢區',
    '田寮區', '阿蓮區', '路竹區', '湖內區', '茄萣區', '永安區', '彌陀區',
    '梓官區', '旗山區', '美濃區', '六龜區', '甲仙區', '杉林區', '內門區',
    '茂林區', '桃源區', '那瑪夏區'
  ],
  '基隆市': ['中正區', '七堵區', '暖暖區', '仁愛區', '中山區', '安樂區', '信義區'],
  '新竹市': ['東區', '北區', '香山區'],
  '嘉義市': ['東區', '西區'],
  '宜蘭縣': [
    '宜蘭市', '羅東鎮', '蘇澳鎮', '頭城鎮', '礁溪鄉', '壯圍鄉',
    '員山鄉', '冬山鄉', '五結鄉', '三星鄉', '大同鄉', '南澳鄉'
  ],
  '新竹縣': [
    '竹北市', '關西鎮', '新埔鎮', '竹東鎮', '湖口鄉', '橫山鄉',
    '新豐鄉', '芎林鄉', '寶山鄉', '北埔鄉', '峨眉鄉', '尖石鄉', '五峰鄉'
  ],
  '苗栗縣': [
    '苗栗市', '頭份市', '苑裡鎮', '通霄鎮', '竹南鎮', '後龍鎮',
    '卓蘭鎮', '大湖鄉', '公館鄉', '銅鑼鄉', '南庄鄉', '頭屋鄉', '三義鄉',
    '西湖鄉', '造橋鄉', '三灣鄉', '獅潭鄉', '泰安鄉'
  ],
  '彰化縣': [
    '彰化市', '員林市', '鹿港鎮', '和美鎮', '北斗鎮', '溪湖鎮',
    '田中鎮', '二林鎮', '線西鄉', '伸港鄉', '福興鄉', '秀水鄉', '花壇鄉',
    '芬園鄉', '大村鄉', '埔鹽鄉', '埔心鄉', '永靖鄉', '社頭鄉', '二水鄉',
    '田尾鄉', '埤頭鄉', '芳苑鄉', '大城鄉', '竹塘鄉', '溪州鄉'
  ],
  '南投縣': [
    '南投市', '埔里鎮', '草屯鎮', '竹山鎮', '集集鎮', '名間鄉',
    '鹿谷鄉', '中寮鄉', '魚池鄉', '國姓鄉', '水里鄉', '信義鄉', '仁愛鄉'
  ],
  '雲林縣': [
    '斗六市', '斗南鎮', '虎尾鎮', '西螺鎮', '土庫鎮', '北港鎮',
    '古坑鄉', '大埤鄉', '莿桐鄉', '林內鄉', '二崙鄉', '崙背鄉', '麥寮鄉',
    '東勢鄉', '褒忠鄉', '臺西鄉', '元長鄉', '四湖鄉', '口湖鄉', '水林鄉'
  ],
  '嘉義縣': [
    '太保市', '朴子市', '布袋鎮', '大林鎮', '民雄鄉', '溪口鄉',
    '新港鄉', '六腳鄉', '東石鄉', '義竹鄉', '鹿草鄉', '水上鄉', '中埔鄉',
    '竹崎鄉', '梅山鄉', '番路鄉', '大埔鄉', '阿里山鄉'
  ],
  '屏東縣': [
    '屏東市', '潮州鎮', '東港鎮', '恆春鎮', '萬丹鄉', '長治鄉',
    '麟洛鄉', '九如鄉', '里港鄉', '鹽埔鄉', '高樹鄉', '萬巒鄉', '內埔鄉',
    '竹田鄉', '新埤鄉', '枋寮鄉', '新園鄉', '崁頂鄉', '林邊鄉', '南州鄉',
    '佳冬鄉', '琉球鄉', '車城鄉', '滿州鄉', '枋山鄉', '三地門鄉', '霧臺鄉',
    '瑪家鄉', '泰武鄉', '來義鄉', '春日鄉', '獅子鄉', '牡丹鄉'
  ],
  '臺東縣': [
    '臺東市', '成功鎮', '關山鎮', '卑南鄉', '大武鄉', '太麻里鄉',
    '東河鄉', '長濱鄉', '鹿野鄉', '池上鄉', '綠島鄉', '延平鄉', '海端鄉',
    '達仁鄉', '金峰鄉', '蘭嶼鄉'
  ],
  '花蓮縣': [
    '花蓮市', '鳳林鎮', '玉里鎮', '新城鄉', '吉安鄉', '壽豐鄉',
    '光復鄉', '豐濱鄉', '瑞穗鄉', '富里鄉', '秀林鄉', '萬榮鄉', '卓溪鄉'
  ],
  '澎湖縣': ['馬公市', '湖西鄉', '白沙鄉', '西嶼鄉', '望安鄉', '七美鄉'],
  '金門縣': ['金城鎮', '金湖鎮', '金沙鎮', '金寧鄉', '烈嶼鄉', '烏坵鄉'],
  '連江縣': ['南竿鄉', '北竿鄉', '莒光鄉', '東引鄉'],
}
