<template>
  <div>
    <slot
      name="title"
      :title="props.title"
      :title-class="props.titleClass"
    >
      <div
        v-if="props.title"
        class="text-sm font-medium text-[#636363] mb-1 flex"
        :class="props.titleClass"
      >
        {{ props.title }}
        <slot name="title-right" />
      </div>
    </slot>

    <slot
      name="caption"
      :caption="props.caption"
      :caption-class="props.captionClass"
    >
      <div
        v-if="props.caption"
        class="text-xs text-[#999898]"
        :class="props.captionClass"
      >
        {{ props.caption }}
      </div>
    </slot>

    <div
      v-if="slot.default"
      :class="props.contentClass"
    >
      <slot name="default" />
    </div>
  </div>
</template>

<script
  setup
  lang="ts"
>
import { ref } from 'vue';

interface Props {
  title?: string;
  titleClass?: string;
  caption?: string;
  captionClass?: string;
  contentClass?: string;
}
const props = withDefaults(defineProps<Props>(), {
  title: '',
  titleClass: '',
  caption: undefined,
  captionClass: '',
  contentClass: 'pt-3',
});

const slot = defineSlots<{
  default?: () => any
  'title-right'?: () => any
  title?: (props: {
    title: string;
    titleClass?: string;
  }) => any
  caption?: (props: {
    caption?: string;
    captionClass: string;
  }) => any
}>();
</script>

<style
  scoped
  lang="sass"
>
</style>
