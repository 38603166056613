import { defineConstants } from "./define";
export const { CAREER_STANDARD_APPLICATION_STATUS_KV: CAREER_STANDARD_APPLICATION_STATUS, CAREER_STANDARD_APPLICATION_STATUS_MAP_BY_KEY, CAREER_STANDARD_APPLICATION_STATUS_MAP_BY_VALUE, CAREER_STANDARD_APPLICATION_STATUS_VALUES } = defineConstants([
    {
        key: 'TO_BE_VIEWED',
        value: 'to-be-viewed',
        label: '待確認',
        colorName: 'negative',
        description: '店家尚未確認履歷資訊',
    },
    {
        key: 'TO_BE_CONTACTED',
        value: 'to-be-contacted',
        label: '待聯絡',
        colorName: 'orange',
        description: '等待店家主動聯繫',
    },
    {
        key: 'ADMITTED',
        value: 'admitted',
        label: '已錄取',
        colorName: 'green',
        description: '已確認錄用',
    },
    /** 投遞履歷時直接拒絕 */
    {
        key: 'DECLINED',
        value: 'declined',
        label: '已婉拒',
        colorName: 'grey',
        description: '店家已婉拒',
    },
    /** 面試後未錄用 */
    {
        key: 'NOT_HIRED',
        value: 'not-hired',
        label: '未錄用',
        colorName: 'blue',
        description: '店家未錄用',
    },
], 'CAREER_STANDARD_APPLICATION_STATUS');
