<template>
  <div class="flex flex-nowrap border rounded-sm">
    <q-btn
      icon="remove"
      v-bind="btnBindProps"
      class=" px-3"
      @click="add(-1)"
    />
    <q-input
      v-model.lazy="inputValue"
      v-bind="inputBindProps"
      borderless
      class=" w-12 border-l border-r"
    />
    <q-btn
      icon="add"
      v-bind="btnBindProps"
      class=" px-3"
      @click="add(1)"
    />
  </div>
</template>

<script setup lang="ts">
import { syncRef, useVModels } from '@vueuse/core';
import { pick } from 'lodash-es';
import { computed, ref, watch } from 'vue';

interface Props {
  modelValue: number;
  dense?: boolean;
  max?: number;
  min?: number;
  size?: string;
  inputClass?: string;
}
const props = withDefaults(defineProps<Props>(), {
  dense: false,
  max: Infinity,
  min: 0,
  size: '0.8rem',
  inputClass: '',
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const inputValue = ref(props.modelValue);
watch(inputValue, (newValue) => {
  if (
    typeof newValue !== 'number' &&
    !/^-?\d+$/.test(`${newValue}`)
  ) {
    inputValue.value = modelValue.value;
    return;
  }

  let value = Number(newValue);
  if (value > props.max) {
    value = props.max;
  } else if (value < props.min) {
    value = props.min;
  }

  modelValue.value = value;
});

const { modelValue } = useVModels(props, emit);
watch(modelValue, (value) => {
  inputValue.value = value;
});


const inputBindProps = computed(() => {
  return {
    ...pick(props, ['dense']),
    unelevated: true,
    inputClass: `text-center p-0 h-full ${props.inputClass}`
  }
});

const btnBindProps = computed(() => {
  return {
    ...pick(props, ['dense', 'size']),
    unelevated: true,
  }
});

function add(value: number) {
  const newValue = Number(inputValue.value) + value;

  if (newValue > props.max || newValue < props.min) return;

  inputValue.value = newValue;
}
</script>

<style scoped lang="sass">
:deep(.q-input)
  .q-field__control, .q-field__control-container
    height: 100%
</style>
