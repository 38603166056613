import { computed, readonly, ref, shallowRef, triggerRef, watch } from 'vue';
import { useGroupBuyApi, useUserCollectionApi } from '@jgo-idea/api';
import { Brand, CommodityCategory, GroupBuy, LeanGroupBuy, User } from '@jgo-idea/types';
import to from 'await-to-js';
import { defineStore, acceptHMRUpdate } from 'pinia';
import { instance } from '../common/api';
import { refWithControl, useArraySome, useAsyncState, watchDebounced } from '@vueuse/core';
import { clone } from 'remeda';
import { defaultsDeep } from 'lodash-es';
import { simplifyToDocIdDeep } from '@jgo-idea/common';
import { useMainStore } from './main.store';
import { useUserStore } from './user.store';

interface Filter {
  sortBy: 'startAt' | 'popularity' | 'endAt';
  isDescending: boolean;
  /** 最低金額 */
  minPrice?: number;
  /** 最高金額 */
  maxPrice?: number;

  /** 商品類別，OR 查詢 */
  categories: CommodityCategory[];
  /** 商品品牌，OR 查詢 */
  brands: Brand[];
  /** 團購剩餘天數 */
  remainingDays?: number;
  keyword: string;
}

const descendingSortKeyMap: Record<Filter['sortBy'], boolean> = {
  startAt: true,
  popularity: true,
  endAt: false,
}
const filterDefault: Filter = {
  sortBy: 'startAt',
  isDescending: true,
  keyword: '',
  minPrice: undefined,
  maxPrice: undefined,
  categories: [],
  brands: [],
  remainingDays: undefined,
}

const QUANTITY_EACH_TIME = 30;

/**
 * TODO: 資料在指定條件排序時，_id 順序並非連續，所以使用 startId gt、lt 查詢，
 * 會導致資料缺失或重複，目前先使用 skip 來解決
 */
export const useGroupBuyStore = defineStore('group-buy', () => {
  const userStore = useUserStore();

  const groupBuyApi = useGroupBuyApi(instance);
  const userCollectionApi = useUserCollectionApi(instance);

  const filter = ref<Filter>(clone(filterDefault));
  watchDebounced(filter, () => refresh(), {
    deep: true,
    debounce: 300,
  });

  const pagination = ref({
    limit: QUANTITY_EACH_TIME,
    skip: 0,
  });
  const list = shallowRef<LeanGroupBuy[]>([]);

  const {
    state: collectionList,
    execute: refreshCollectionList
  } = useAsyncState(
    async () => {
      if (!userStore.userInfo) {
        return [];
      }

      return userCollectionApi.findByList({
        groupBuys: list.value.map((item) => item._id),
      });
    },
    [],
    {
      resetOnExecute: false,
      immediate: false,
    }
  );
  watch(list, () => refreshCollectionList());
  function isCollected(id: string) {
    return collectionList.value.some((item) => item.groupBuy?._id === id);
  }

  const {
    isLoading, execute
  } = useAsyncState(
    async () => {
      const result = await groupBuyApi.findVisitable({
        ...pagination.value,
        ...simplifyToDocIdDeep(filter.value),
        lean: true,
      });
      return result.data;
    },
    [],
    {
      shallow: true,
      resetOnExecute: false,
      immediate: false,
      onSuccess(data) {
        if (data?.[0] && 'priceRange' in data[0]) {
          const leanData = data as LeanGroupBuy[];

          list.value.push(...leanData);
          triggerRef(list);
        }
      },
    }
  );

  function refresh() {
    list.value.length = 0;
    pagination.value.skip = 0;
    return execute();
  }

  return {
    isLoading: useArraySome([isLoading], Boolean),
    list: computed(() => clone(list.value)),
    /** 清空資料，重新讀取 */
    refresh,
    /** 是否已收藏此項目 */
    isCollected,
    refreshCollectionList,

    pagination: computed(() => clone(pagination.value)),
    filter: computed(() => clone(filter.value)),
    updateFilter(data: Partial<Filter>) {
      filter.value = defaultsDeep(
        {
          ...data,
          categories: data.categories || [],
          brands: data.brands || [],
          keyword: data.keyword ?? '',
          minPrice: data.minPrice ? data.minPrice : undefined,
          maxPrice: data.maxPrice ? data.maxPrice : undefined,
          remainingDays: data.remainingDays ? data.remainingDays : undefined,
        },
        clone(filter.value),
      );

      if (data.sortBy) {
        filter.value.isDescending = descendingSortKeyMap[data.sortBy];
      }
    },
    /** 清空 filter 指定欄位，不指定則全部清空 */
    clearFilter(keys?: (keyof Filter)[]) {
      if (!keys) {
        filter.value = clone(filterDefault);
        return;
      }

      keys.forEach((key) => {
        // filterDefault 型別沒錯就一定沒問題
        (filter.value[key] as any) = clone(filterDefault[key]);
      });
    },

    /** startId 順序問題解決之前，只能使用 skip 解決 */
    next() {
      pagination.value.skip = list.value.length + QUANTITY_EACH_TIME;
      return execute();
    },
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGroupBuyStore, import.meta.hot))
}
