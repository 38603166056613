import { useRequest } from './utils';
export function useAuthApi(instance) {
    const request = useRequest(instance);
    async function localLogin(params) {
        return request({
            method: 'post',
            url: `/api/v1/auth/local`,
            data: params
        });
    }
    async function refresh(refreshToken) {
        return request({
            method: 'post',
            url: `/api/v1/auth/refresh`,
            headers: {
                Authorization: `Bearer ${refreshToken}`
            }
        });
    }
    async function lineLogin(params) {
        return request({
            method: 'post',
            url: `/api/v1/auth/line`,
            data: params
        });
    }
    async function appleLogin(params) {
        return request({
            method: 'post',
            url: `/api/v1/auth/apple`,
            data: params
        });
    }
    async function googleLogin(params) {
        return request({
            method: 'post',
            url: `/api/v1/auth/google`,
            data: params
        });
    }
    async function logout() {
        return request({
            method: 'delete',
            url: `/api/v1/auth`,
        });
    }
    return {
        login: localLogin,
        lineLogin,
        appleLogin,
        googleLogin,
        refresh,
        logout,
    };
}
