<template>
  <div class="bg-white">
    <transition
      name="fade-up"
      mode="out-in"
    >
      <div
        :key="stepInfo.title"
        class=" sticky top-0 text-base font-medium text-group-buy p-[0.875rem] text-center z-10"
      >
        {{ stepInfo.title }}
      </div>
    </transition>

    <q-stepper
      ref="stepper"
      v-model="step"
      contracted
      alternative-labels
      inactive-color="grey-4"
      done-color="group-buy"
      active-color="group-buy"
      done-icon=" "
      active-icon=" "
      animated
      flat
      header-class="bg-[#F5F3EC]"
      keep-alive
    >
      <template #message>
        <div class="message-bar text-group-buy bg-[#F5F3EC] text-center pb-3">
          <transition
            name="fade-down"
            mode="out-in"
          >
            <div
              :key="stepInfo.caption"
              class="text-xs "
            >
              {{ stepInfo.caption }}
            </div>
          </transition>
        </div>
      </template>

      <!-- 團購購物車 -->
      <q-step
        :name="1"
        title=""
        :done="step > 1"
      >
        <item-select
          v-model="form.selectedGroupBuys"
          :list="cartStore.list"
          @back="emit('close')"
          @next="nextStep()"
        />
      </q-step>

      <!-- 選擇運送及付款方式 -->
      <q-step
        :name="2"
        title=""
        :done="step > 2"
      >
        <delivery-form
          v-if="form.selectedGroupBuys.length > 0"
          v-model:delivery-method="form.deliveryMethod"
          :group-buys="form.selectedGroupBuys"
          :total-price="totalPrice"
          @back="prevStep"
          @next="nextStep"
        />
        <div
          v-else
          class="step-content text-2xl flex flex-center opacity-20"
        >
          請選擇要結帳的團購項目
        </div>
      </q-step>

      <!-- 填寫寄件資料 -->
      <q-step
        :name="3"
        title=""
        :done="step > 3"
      >
        <shipping-info-form
          v-if="form.selectedGroupBuys.length > 0"
          v-model:buyer="form.buyerInfo"
          v-model:receiver="form.receiverInfo"
          v-model:my-ship711="form.myShip711Info"
          :group-buys="form.selectedGroupBuys"
          :delivery-method="form.deliveryMethod"
          :total-price="totalPrice"
          @back="prevStep"
          @next="handleSubmitOrder"
        />

        <div
          v-else
          class="step-content text-2xl flex flex-center opacity-20"
        >
          請選擇要結帳的團購項目
        </div>
      </q-step>

      <!-- 預覽完成訂單 -->
      <q-step
        :name="4"
        title=""
      >
        <order-complete
          :order="order"
          @close="emit('close')"
        />
      </q-step>
    </q-stepper>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { ShoppingCartGroupBuy } from '../stores/group-buy-shopping-cart.store';
import { DeliveryMethod, GroupBuy, GroupBuyOrder, GroupBuyPackingFeeCalculationMethod, UserBuyerInfo, UserMyShip711Info, UserReceiverInfo } from '@jgo-idea/types';
import to from 'await-to-js';
import { instance } from '../common/api';
import { clone, find, prop, flatMap, flatten, map, pipe, reduce, uniq, isNil, join, filter, reject } from 'remeda';
import { getGroupBuyStateInfo, isCommodityCombinationEq, simplifyToDocIdDeep, then } from '@jgo-idea/common';
import { ROUTE_NAME } from '../router/router';
import { openBaseDialog } from '../common/utils-quasar';
import { debounce } from 'lodash-es';
import { returnObjectUnlessEmpty } from '../common/utils';

import ItemSelect from './group-buy-shopping-cart/item-select.vue';
import DeliveryForm from './group-buy-shopping-cart/delivery-form.vue';
import ShippingInfoForm from './group-buy-shopping-cart/shipping-info-form.vue';
import OrderComplete from './group-buy-shopping-cart/order-complete.vue';

import { useGroupBuyShoppingCartStore } from '../stores/group-buy-shopping-cart.store';
import { CreateGroupBuyOrderParam, useGroupBuyApi, useGroupBuyOrderApi } from '@jgo-idea/api';
import { useQuasar } from 'quasar';
import { useRouter } from 'vue-router';
import { whenever } from '@vueuse/core';

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const $q = useQuasar();
const groupBuyOrderApi = useGroupBuyOrderApi(instance);
const groupBuyApi = useGroupBuyApi(instance);
const cartStore = useGroupBuyShoppingCartStore();
const router = useRouter();

/** 使用者輸入的表單資料 */
const form = ref<{
  /** 已選擇團購 */
  selectedGroupBuys: ShoppingCartGroupBuy[];
  deliveryMethod?: DeliveryMethod;
  buyerInfo: NonNullable<GroupBuyOrder['buyerInfo']>;
  receiverInfo: NonNullable<GroupBuyOrder['receiverInfo']>;
  myShip711Info: NonNullable<GroupBuyOrder['myShip711Info']>;
}>({
  selectedGroupBuys: [],
  deliveryMethod: undefined,
  buyerInfo: {
    name: '',
    email: '',
    phone: '',
  },
  receiverInfo: {
    name: '',
    email: '',
    phone: '',
    county: '',
    township: '',
    address: '',
  },
  myShip711Info: {
    receiverName: '',
    receiverPhone: '',
    storeNumber: '',
    storeName: '',
    storeAddress: '',
  },
});
// watch(form, (newForm) => {
//   console.log(`🚀 ~ newForm:`, newForm);
// }, {
//   deep: true,
// });

const hasInvalidGroup = computed(() => pipe(
  form.value.selectedGroupBuys,
  map(prop('groupBuy')),
  map(getGroupBuyStateInfo),
  (infos) => infos.some((info) => info.value !== 'in-progress'),
));

/** 建立成功的訂單 */
const order = ref<GroupBuyOrder>();

const step = ref(1);
const stepLabelMap: Record<string, {
  title: string,
  caption: string,
}> = {
  '1': {
    title: '團購購物車',
    caption: '1. 選擇要結帳的商品',
  },
  '2': {
    title: '選擇運送及付款方式',
    caption: '2. 選擇付款方式',
  },
  '3': {
    title: '填寫收件人資料',
    caption: '3. 填寫收件人資料',
  },
  '4': {
    title: '訂購完成',
    caption: '訂單完成',
  },
}
const stepInfo = computed(() => stepLabelMap[`${step.value}`] ?? {
  title: '',
  caption: '',
});
/** 每次購物車顯示時，將步驟跳回第一步 */
whenever(() => cartStore.visible, () => {
  step.value = 1;
});

async function checkStockQuantity() {
  // 取得所有團購資料
  const groupBuys = await pipe(undefined,
    () => pipe(form.value.selectedGroupBuys,
      // 去除沒有數量限制的商品
      reject((item) => item.commodities.every((groupBuyCommodity) =>
        isNil(groupBuyCommodity.combination.stockQuantity)
      )),
      flatMap((item) => item.groupBuy._id),
      uniq(),
    ),
    async (ids) => {
      const results: GroupBuy[] = [];

      for (const id of ids) {
        const [error, result] = await to(groupBuyApi.findOne(id));
        if (error) {
          console.error(`取得團購資料失敗：${id}`);
          console.error(error);
          continue;
        }

        results.push(result);
      }

      return results;
    },
  );

  const texts: string[] = [];
  /** 數量變更的團購商品 */
  const updatedList = pipe(form.value.selectedGroupBuys,
    clone,
    reduce((list: ShoppingCartGroupBuy[], selectedItem) => {
      const groupBuy = groupBuys.find((groupBuy) => groupBuy._id === selectedItem.groupBuy._id);
      if (!groupBuy) return list;

      const partText: string[] = [];
      selectedItem.commodities.forEach((selectedCommodity) => {
        const commodity = groupBuy.commodities.find(
          (groupBuyCommodity) => groupBuyCommodity.commodity._id === selectedCommodity.commodity._id
        );
        if (!commodity) return;

        const combination = commodity.combinations.find(
          (combination) => isCommodityCombinationEq(combination.list, selectedCommodity.combination.list)
        );
        // remainingStockQuantity 不存在表示沒有庫存限制
        if (!combination || isNil(combination.remainingStockQuantity)) return;
        if (selectedCommodity.quantity <= combination.remainingStockQuantity) return;

        const name = pipe(combination.list, map(prop('attribute')), join('-'));

        if (combination.remainingStockQuantity === 0) {
          partText.push(`${name} 商品數量不足，請重新結帳`);
        } else {
          partText.push(`${name} 商品數量調整 ${selectedCommodity.quantity} → ${combination.remainingStockQuantity}`);
        }

        selectedCommodity.quantity = combination.remainingStockQuantity;
      });
      if (partText.length !== 0) {
        partText.unshift(`${groupBuy.name} 團購`);
        partText.push('');

        list.push(selectedItem);
      }

      texts.push(...partText);

      return list;
    }, []),
  );

  return {
    /** 提示文字，一行一個 */
    texts,
    /** 數量變更的團購商品 */
    updatedList,
  };
}
async function processCheckStockQuantity() {
  const { texts, updatedList } = await checkStockQuantity();
  if (texts.length === 0) {
    return;
  }

  emit('close');

  const message = texts.join('\n');
  updatedList.forEach((item) => {
    item.commodities.forEach((commodityItem) => {
      cartStore.put(item.groupBuy, commodityItem);
    });
  });

  cartStore.groupBuysRefreshHook.trigger(
    pipe(updatedList, map(prop('groupBuy'))),
  );

  openBaseDialog({
    title: '團購庫存不足',
    message,
    actions: [
      {
        label: '確認',
        action: 'y',
      },
    ],
    persistent: true,
  }).onOk(() => {
    cartStore.setVisible(true);
  });

  return Promise.reject();
}

const nextStep = debounce(async () => {
  if (hasInvalidGroup.value) {
    openBaseDialog({
      title: '團購已結束',
      message: '結帳內容包含已結束團購，無法進行成立訂單流程，請返回團購列表',
      actions: [
        {
          label: '返回團購列表',
          action: 'y',
        },
      ],
      persistent: true,
    }).onOk(() => {
      cartStore.clean();
      router.push({ name: ROUTE_NAME.GROUP_BUY });
      emit('close');
    });
    return;
  }

  // 第一步時，檢查庫存數量
  if (step.value === 1) {
    const [stockError] = await to(processCheckStockQuantity());
    if (stockError) return;
  }

  step.value += 1;
}, 1000, {
  leading: true,
  trailing: false,
});
const prevStep = debounce(() => {
  step.value -= 1;
}, 1000, {
  leading: true,
  trailing: false,
});

const totalPrice = computed(() =>
  pipe(0,
    // 商品數量價格
    (value) => {
      const result = pipe(
        form.value.selectedGroupBuys,
        flatMap((item) => item.commodities),
        reduce(
          (acc, item) => acc += item.quantity * item.combination.groupBuyPrice,
          value,
        ),
      );

      return result;
    },
    // 計算包材費用
    (value) => {
      const result = form.value.selectedGroupBuys.reduce((acc, groupBuyItem) => {
        const packingFeeSetting = groupBuyItem.groupBuy.packingFeeSetting;

        if (packingFeeSetting.method === 'per-item') {
          groupBuyItem.commodities.forEach((item) => {
            acc += item.quantity * packingFeeSetting.value;
          });
        }

        if (packingFeeSetting.method === 'per-order') {
          acc += packingFeeSetting.value;
        }

        return acc;
      }, value);

      return result;
    },
    // 加上運費
    (value) => {
      const setting = form.value.selectedGroupBuys[0]?.groupBuy.deliverySettings;
      if (setting) {
        const target = pipe(
          setting,
          find(({ method }) => method === form.value.deliveryMethod),
        );

        value += target?.cost ?? 0;
      }

      return value;
    },
  )
);

async function handleSubmitOrder() {
  const [stockError] = await to(processCheckStockQuantity());
  if (stockError) return;

  if (hasInvalidGroup.value) {
    openBaseDialog({
      title: '團購已結束',
      message: '結帳內容包含已結束團購，無法進行成立訂單流程，請返回團購列表',
      actions: [
        {
          label: '返回團購列表',
          action: 'y',
        },
      ],
      persistent: true,
    }).onOk(() => {
      cartStore.clean();
      router.push({ name: ROUTE_NAME.GROUP_BUY });
      emit('close');
    });
    return;
  }

  if (!form.value.deliveryMethod) {
    return;
  }

  const param: CreateGroupBuyOrderParam = {
    deliveryMethod: form.value.deliveryMethod,
    items: pipe(
      form.value.selectedGroupBuys,
      clone,
      map((item) => {
        return item.commodities.map(({ commodity, quantity, combination }) => ({
          groupBuy: item.groupBuy,
          commodity,
          combinationList: combination.list,
          quantity,
        }));
      }),
      flatten(),
      simplifyToDocIdDeep,
    ),
    buyerInfo: returnObjectUnlessEmpty(form.value.buyerInfo),
    receiverInfo: returnObjectUnlessEmpty(form.value.receiverInfo),
    myShip711Info: returnObjectUnlessEmpty(form.value.myShip711Info),
  }

  $q.loading.show();
  const [error, result] = await to(groupBuyOrderApi.create(param));
  $q.loading.hide();

  // 刪除購物車對應項目
  form.value.selectedGroupBuys.forEach((selectedItem) => {
    selectedItem.commodities.forEach((item) => {
      cartStore.put(
        selectedItem.groupBuy,
        {
          ...clone(item),
          quantity: 0,
        },
      );
    });
  });


  if (error) {
    if (error.message.includes('庫存不足')) {
      processCheckStockQuantity()
      return;
    }

    console.error(error);
    $q.notify({
      type: 'negative',
      message: `建立訂單失敗：${error.message}`
    });
    return;
  }

  order.value = result;
  step.value = 4;
}
</script>

<style scoped lang="sass">
:deep(.q-stepper)
  .q-stepper__tab
    padding: 0.75rem 0
    min-height: 3rem
  .q-stepper__header
    height: 2.4rem
    min-height: 2.4rem
  .q-stepper__dot
    width: 0.75rem
    min-width: 0.75rem
    height: 0.75rem
    transition-duration: 0.4s
  .q-stepper__step-inner
    padding: 0rem

// 2 個 height 是為了相容舊瀏覽器，不支援 dvh 屬性會取 calc(80vh - 3rem)
:deep(.q-stepper__step-inner) > *
  height: calc(80vh - 3rem)
  height: 75dvh

</style>
