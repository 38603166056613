import { createApp } from 'vue';
import router from './router/router';
import { createPinia } from 'pinia';
import i18n from './locales/i18n';
import { VueQueryPlugin } from '@tanstack/vue-query';

import * as Sentry from '@sentry/capacitor';
import * as SentryVue from '@sentry/vue';

import dayjs from 'dayjs';
import 'dayjs/locale/zh-tw';
dayjs.locale('zh-tw');

import { Quasar, Dialog, Notify, Loading, QuasarPluginOptions } from 'quasar';
import quasarLang from 'quasar/lang/zh-TW';

// Import Quasar
import { Platform } from 'quasar';
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-symbols-rounded/material-symbols-rounded.css';
import iconSet from 'quasar/icon-set/material-symbols-rounded';
import 'quasar/src/css/index.sass';


import App from './App.vue';

// Tailwind CSS
// import './index.css';

// Uno CSS
import 'uno.css';

// 自訂樣式
import './style/animate.sass';
import './style/global.sass';

// import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

// piniaPluginPersistedstate 不知道為甚麼沒有任何作用
const pinia = createPinia();
// pinia.use(piniaPluginPersistedstate);

const app = createApp(App)
  .use(Quasar, {
    plugins: {
      Dialog, Notify, Loading,
    },
    lang: quasarLang,
    iconSet,
    config: {
      loading: {
        timeout: 2000,
      }
    },
  } as Partial<QuasarPluginOptions>)
  .use(pinia)
  .use(i18n)
  .use(VueQueryPlugin)

// FIX: ios 上切換頁面時會導致不明錯誤，暫時停用
if (!Platform.is.ios) {
  Sentry.init({
    app,
    dsn: "https://cc7a04e04af370e8219e4cc469a46a99@o4507587449978880.ingest.us.sentry.io/4507824142942208",
    integrations: [
      SentryVue.browserTracingIntegration({ router }),
      SentryVue.replayIntegration(),
    ],

    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/muconail\.com\/api/
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    enabled: import.meta.env.PROD,
  }, SentryVue.init);
}

app.use(router);
app.mount("#app");