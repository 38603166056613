import { useRequest } from './utils';
export function useCourseApi(instance) {
    const request = useRequest(instance);
    async function create(param) {
        return request({
            method: 'post',
            url: `/api/v1/course`,
            data: param
        });
    }
    async function findOne(id) {
        return request({
            method: 'get',
            url: `/api/v1/course/${id}`,
        });
    }
    async function find(param) {
        return request({
            method: 'get',
            url: `/api/v1/courses`,
            data: param
        });
    }
    async function findVisitable(param) {
        return request({
            method: 'get',
            url: `/api/v1/courses/visitable`,
            data: param
        });
    }
    async function update(id, param) {
        return request({
            method: 'patch',
            url: `/api/v1/course/${id}`,
            data: param
        });
    }
    async function remove(id) {
        return request({
            method: 'delete',
            url: `/api/v1/course/${id}`,
        });
    }
    async function findLogs(id, param) {
        return request({
            method: 'get',
            url: `/api/v1/course/${id}/logs`,
            data: param,
        });
    }
    async function addAnnouncement(courseId, param) {
        return request({
            method: 'post',
            url: `/api/v1/course/${courseId}/announcement`,
            data: param
        });
    }
    async function updateAnnouncement(courseId, announcementId, param) {
        return request({
            method: 'patch',
            url: `/api/v1/course/${courseId}/announcement/${announcementId}`,
            data: param
        });
    }
    async function removeAnnouncement(courseId, announcementId) {
        return request({
            method: 'delete',
            url: `/api/v1/course/${courseId}/announcement/${announcementId}`,
        });
    }
    return {
        create,
        findOne,
        find,
        findVisitable,
        update,
        remove,
        findLogs,
        addAnnouncement,
        updateAnnouncement,
        removeAnnouncement,
    };
}
