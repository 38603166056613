import { computed, readonly, ref, shallowRef } from 'vue';
import { useGroupBuySettingApi } from '@jgo-idea/api';
import { clone } from 'remeda';
import { defineStore, acceptHMRUpdate } from 'pinia';
import { instance } from '../common/api';
import { useAsyncState, watchDebounced } from '@vueuse/core';

export const useGroupBuySettingStore = defineStore('group-buy-setting', () => {
  const groupBuySettingApi = useGroupBuySettingApi(instance);

  const { state: data, isLoading, execute } = useAsyncState(async () => {
    return groupBuySettingApi.get();
  }, undefined);

  return {
    isLoading,
    data: computed(() => clone(data.value)),
    refresh: execute
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGroupBuySettingStore, import.meta.hot))
}
