import { LineLoginParam, useAuthApi, useUserApi } from '@jgo-idea/api';
import { FeatureName, UserIdentityMap } from '@jgo-idea/types';
import to from 'await-to-js';
import { defineStore, acceptHMRUpdate } from 'pinia';
import { clone, pipe } from 'remeda';
import { computed } from 'vue';
import { instance } from '../common/api';
import { ROUTE_NAME } from '../router/router';
import { omit } from 'lodash-es';
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { User as GoogleUser } from '@codetrix-studio/capacitor-google-auth';

import { useRouter } from 'vue-router';
import { useAsyncState } from '@vueuse/core';
import { useNotificationStore } from './notification.store';
import { useQuasar } from 'quasar';

const identityMapFeature: Record<keyof UserIdentityMap, FeatureName> = {
  groupBuyCreator: 'group-buy',
  courseCreator: 'course',
  fleaMarketCreator: 'flea-market',
  artPieceCreator: 'art-piece',
}

export const useUserStore = defineStore('user', () => {
  const $q = useQuasar();
  const notifyStore = useNotificationStore();
  const authApi = useAuthApi(instance);
  const userApi = useUserApi(instance);
  const router = useRouter();

  // 登入登出
  /** 更新權杖 */
  async function refresh() {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      $q.notify({
        message: '更新權限發生異常，請重新登入',
        color: 'negative',
        position: 'top',
        timeout: 2000,
      });

      await authApi.logout();
      throw new Error('更新權限發生異常，請重新登入');
    }

    const [error, data] = await to(
      authApi.refresh(refreshToken)
    );
    if (error) {
      userInfo.value = undefined;
      await authApi.logout();
      $q.notify({
        message: '登入逾期，請重新登入',
        color: 'negative',
        position: 'top',
        timeout: 2000,
      });

      throw new Error('登入逾期，請重新登入');
    }

    localStorage.setItem('refreshToken', data.refreshToken);

    await refreshInfo();
  }

  async function lineLogin(params: LineLoginParam) {
    const [err, result] = await to(authApi.lineLogin(params));
    if (err) {
      return Promise.reject(err);
    }

    if (result.refreshToken) {
      localStorage.setItem('refreshToken', result.refreshToken);
    }

    return result;
  }
  async function appleLogin(params: SignInWithAppleResponse) {
    const [err, result] = await to(authApi.appleLogin({
      userId: params.response.user ?? '',
      name: `${params.response.familyName ?? ''}${params.response.givenName ?? ''}`,
      identityToken: params.response.identityToken ?? '',
    }));
    if (err) {
      return Promise.reject(err);
    }

    if (result.refreshToken) {
      localStorage.setItem('refreshToken', result.refreshToken);
    }

    return result;
  }
  async function googleLogin(params: GoogleUser) {
    const [err, result] = await to(authApi.googleLogin({
      accessToken: params.authentication.accessToken,
      id: params.id,
      name: params.name,
    }));
    if (err) {
      return Promise.reject(err);
    }

    if (result.refreshToken) {
      localStorage.setItem('refreshToken', result.refreshToken);
    }

    return result;
  }

  async function logout() {
    userInfo.value = undefined;
    await authApi.logout();
    await notifyStore.stop();
  }

  const {
    isLoading: isUserInfoLoading,
    state: userInfo,
    execute: refreshInfo,
  } = useAsyncState(() => userApi.getInfo(), undefined, {
    resetOnExecute: false,
    // router 已經檢查過了，不需要再檢查
    // onSuccess(data) {
    //   // console.log('🚀 ~ [refreshInfo] data:', JSON.stringify(data, null, 2));
    //   /** 檢查必填資料是否為空，為空強制跳轉 */
    //   if (!data?.nickName) {
    //     router.replace({
    //       name: ROUTE_NAME.ACCOUNT_REQUIRED_SETTING
    //     })
    //   }
    // },
    // onError(error) {
    //   router.replace({
    //     name: ROUTE_NAME.ACCOUNT_LOGIN,
    //   })
    // }
  });

  // 可用功能
  const availableFeatures = computed(() => {
    const result = pipe([],
      /** 檢查是否有團購資格 */
      (data: FeatureName[]) => {
        Object.entries(identityMapFeature).forEach(([key, value]) => {
          const identity = key as keyof UserIdentityMap;

          const creator = userInfo.value?.identityMap?.[identity];
          if (!creator || creator.timestamp.deletedAt) return;

          data.push(value);
        });

        return data;
      },
    );

    return result;
  });

  return {
    isUserInfoLoading,
    userInfo: computed(() => clone(userInfo.value)),
    /** 更新 userInfo */
    refreshInfo,

    /** 此使用者可用的功能。團購、課程等等 */
    availableFeatures,
    /** 更新登入權杖 */
    async refresh() {
      try {
        await refresh();
      } catch (error) {
        $q.notify({
          type: 'warning',
          message: `權杖過期，請重新登入`,
        });

        router.replace({
          name: ROUTE_NAME.ACCOUNT_LOGIN,
        })
      }
    },
    lineLogin,
    appleLogin,
    googleLogin,
    logout,

    ...omit(userApi, ['getInfo', 'getGroupBuyCreator'])
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
