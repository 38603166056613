import { RouteRecordRaw } from 'vue-router'
import { defineConstants } from '@jgo-idea/types';


export const {
  GROUP_BUY_LAUNCHER_ROUTE_NAME_KV: GROUP_BUY_LAUNCHER_ROUTE_NAME,
  GROUP_BUY_LAUNCHER_ROUTE_NAME_LIST,
} = defineConstants(
  [
    {
      key: 'GROUP_BUY_LAUNCHER',
      value: 'group-buy-launcher'
    },
    {
      key: 'GROUP_BUY_LAUNCHER_BASIC_FORM',
      value: 'group-buy-launcher-basic-form'
    },
    {
      key: 'GROUP_BUY_LAUNCHER_REMITTANCE_FORM',
      value: 'group-buy-launcher-remittance-form'
    },
    {
      key: 'GROUP_BUY_LAUNCHER_FINISH',
      value: 'group-buy-launcher-finish'
    },
  ] as const,
  'GROUP_BUY_LAUNCHER_ROUTE_NAME'
);

/**
 * TODO: 不良設計，launcher 內子元件狀態相當內聚，不需要將其狀態註冊至 router，
 * 其頁面切換應該由 launcher 內部控制，不應該透過 router 控制。
 * 
 * 請使用 q-tab 配合 useRouteQuery，參考 the-course-launcher 設計
 * ，不用再獨立設計一個 router
 */
export const groupBuyLauncherRoute: RouteRecordRaw = {
  path: `/group-buy-launcher`,
  name: GROUP_BUY_LAUNCHER_ROUTE_NAME.GROUP_BUY_LAUNCHER,
  redirect: {
    name: GROUP_BUY_LAUNCHER_ROUTE_NAME.GROUP_BUY_LAUNCHER_BASIC_FORM,
  },
  component: () => import('../views/the-group-buy-launcher.vue'),
  meta: {
    mainColorName: 'group-buy',
  },
  children: [
    {
      path: `basic-form`,
      name: GROUP_BUY_LAUNCHER_ROUTE_NAME.GROUP_BUY_LAUNCHER_BASIC_FORM,
      component: () => import('../views/the-group-buy-launcher-basic-form.vue'),
      meta: {
        header: {
          title: '發起團購',
          hideBackBtn: true,
        },
        hideNavTabs: true,
      }
    },
    {
      path: `remittance-form`,
      name: GROUP_BUY_LAUNCHER_ROUTE_NAME.GROUP_BUY_LAUNCHER_REMITTANCE_FORM,
      component: () => import('../views/the-group-buy-launcher-remittance-form.vue'),
      meta: {
        header: {
          title: '收款及物流設定',
          hideBackBtn: true,
        },
        hideNavTabs: true,
      }
    },
    {
      path: `finish`,
      name: GROUP_BUY_LAUNCHER_ROUTE_NAME.GROUP_BUY_LAUNCHER_FINISH,
      component: () => import('../views/the-group-buy-launcher-finish.vue'),
      meta: {
        header: {
          title: '完成團購發起申請',
          hideBackBtn: true,
        },
        hideNavTabs: true,
      }
    },
  ]
};
