import { defineConstants } from "./define";
export const { FLEA_MARKET_ORDER_STATUS_KV: FLEA_MARKET_ORDER_STATUS, FLEA_MARKET_ORDER_STATUS_MAP_BY_KEY, FLEA_MARKET_ORDER_STATUS_MAP_BY_VALUE, FLEA_MARKET_ORDER_STATUS_VALUES } = defineConstants([
    {
        key: 'PENDING_PAYMENT',
        value: 'pending-payment',
        label: '待付款',
        color: '#F45252',
    },
    {
        key: 'PENDING_SHIPMENT',
        value: 'pending-shipment',
        label: '待出貨',
        color: '#E4973B',
    },
    {
        key: 'SHIPPED',
        value: 'shipped',
        label: '已出貨',
        color: '#146DAE',
    },
    {
        key: 'DELIVERED',
        value: 'delivered',
        label: '已到貨',
        color: '#146DAE',
    },
    {
        key: 'COMPLETED',
        value: 'completed',
        label: '已完成',
        color: '#14AE5C',
    },
    {
        key: 'CANCELLED',
        value: 'cancelled',
        label: '已取消',
        color: '#636363',
    },
], 'FLEA_MARKET_ORDER_STATUS');
