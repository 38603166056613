import dayjs from "dayjs";
import { isEmpty, isNot, pipe } from "remeda";

export { simplifyToDocIdDeep } from '@jgo-idea/common';


export function toBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result?.toString() ?? '');
    reader.onerror = reject;
  });
}

/** 輸入 dataUrl，可以自動取得 mimeType，如果直接輸入 base64 則必須提供 mimeType */
export function toFile(dataUrl: string, filename: string, mimeType?: string) {
  const dataArray = dataUrl.split(',');
  const mime = dataArray[0]?.match(/:(.*?);/)?.[1] ?? mimeType;
  if (!mime) {
    throw new Error('無法取得檔案類型');
  }

  const data = pipe(dataArray,
    ([part01, part02]) => part02 ?? part01,
  );
  if (!data) {
    throw new Error('無法取得檔案內容');
  }

  const base64 = atob(data);
  const arrayBuffer = new ArrayBuffer(base64.length);
  const uintArray = new Uint8Array(arrayBuffer);
  for (let i = 0; i < base64.length; i++) {
    uintArray[i] = base64.charCodeAt(i);
  }

  return new File([uintArray], filename, { type: mime });
}

export function toDateTime(timestamp?: number, format = 'YYYY/MM/DD (dd) HH:mm') {
  if (!timestamp) return '';
  return dayjs(timestamp * 1000).format(format);
}

/** 檢查第一層 field 之 value 是否為 empty，
 * 如果為空則回傳 undefined，否則回傳原物件
 * 
 * @example
 * ```typescript
 * // return undefined
 * const data = {
 *   name: '',
 *   value: {},
 * }
 * 
 * // return data
 * const data = {
 *   name: '123',
 *   value: {},
 * }
 * ```
 */
export function returnObjectUnlessEmpty<
  T extends Record<string, any>
>(data?: T) {
  if (!data) return;
  return Object.values(data).some(isNot(isEmpty)) ? data : undefined;
}

/** 取得分享用的 URL */
export function getDomain() {
  if (import.meta.env.VITE_DOMAIN_URL) {
    return import.meta.env.VITE_DOMAIN_URL;
  }
  

  return window.location.origin;
}