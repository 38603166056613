<template>
  <transition-group
    :name="props.name"
    :tag="props.tag"
    @before-leave="handleBeforeLeave"
  >
    <slot />
  </transition-group>
</template>

<script setup lang="ts">
/** 修正 transition-group 在 flex 布局下，刪除動畫異常問題
 * 
 * ![範例](https://codesandbox.io/s/xiu-zheng-transition-zai-flex-pai-ban-shan-chu-dong-hua-yi-chang-wen-ti-wclndf)
 */
import { ref, TransitionGroupProps } from 'vue';

const props = withDefaults(defineProps<TransitionGroupProps>(), {});

function handleBeforeLeave(el: Element) {
  const { marginLeft, marginTop, width, height } = window.getComputedStyle(el);
  if (!(el instanceof HTMLElement)) return;

  el.style.left = `${el.offsetLeft - parseFloat(marginLeft)}px`
  el.style.top = `${el.offsetTop - parseFloat(marginTop)}px`
  el.style.width = width
  el.style.height = height
}
</script>

<style scoped lang="sass">
</style>
