<template>
  <q-menu
    ref="menuRef"
    anchor="top middle"
    self="bottom middle"
    :offset="[0, 0]"
    class="w-[100vw] !max-w-[100vw] shadow-none !bg-transparent"
  >
    <q-card class="shadow-3 bg-white m-4  rounded-lg overflow-hidden">
      <q-card-section class="bg-[#F5F3EC] p-4 text-base text-art-piece text-center">
        作品功能選單
      </q-card-section>

      <div class=" p-4 flex-col gap-2 relative">
        <div class="relative flex-col gap-2">
          <q-item
            v-for="btn, i in creatorBtnList"
            :key="i"
            v-ripple
            clickable
            class="btn-shadow px-6 py-4 !h-auto"
            :class="btn.class"
            @click="btn.onClick"
          >
            <q-item-section>
              <q-item-label
                class="text-base font-medium"
                :class="`text-${btn.color}`"
              >
                {{ btn.label }}
              </q-item-label>
              <q-item-label
                v-if="btn.caption"
                caption
                :class="btn.captionClass"
              >
                {{ btn.caption }}
              </q-item-label>
            </q-item-section>
            <q-item-section avatar>
              <q-icon
                :color="btn.color"
                :name="btn.icon"
              />
            </q-item-section>
          </q-item>

          <div
            v-if="creatorInfo?.status !== 'normal'"
            class="flex-col flex-center gap-3 absolute inset-0 px-9 py-6
                 text-[#198EE2] border-[#198EE2] border rounded bg-white"
          >
            <div class=" text-base font-medium">
              作品分享功能尚未申請完成
            </div>

            <q-btn
              unelevated
              class=" btn-shadow py-6"
              @click="toSeller()"
            >
              <div class="flex flex-nowrap px-6">
                <div class=" px-10 whitespace-nowrap text-base">
                  作品功能
                </div>
                <q-icon name="other_admission" />
              </div>
            </q-btn>

            <div class=" text-sm text-center">
              功能路徑：會員功能 → 作品功能 → 作品分功能啟用
            </div>
          </div>
        </div>

        <q-item
          v-for="btn, i in baseBtnList"
          :key="i"
          v-ripple
          clickable
          class="btn-shadow px-6 py-4 !h-auto"
          :class="btn.class"
          @click="btn.onClick"
        >
          <q-item-section>
            <q-item-label
              class="text-base font-medium"
              :class="`text-${btn.color}`"
            >
              {{ btn.label }}
            </q-item-label>
            <q-item-label
              v-if="btn.caption"
              caption
              :class="btn.captionClass"
            >
              {{ btn.caption }}
            </q-item-label>
          </q-item-section>
          <q-item-section avatar>
            <q-icon
              :color="btn.color"
              :name="btn.icon"
            />
          </q-item-section>
        </q-item>
      </div>
    </q-card>
  </q-menu>
</template>

<script
  setup
  lang="ts"
>
import { QMenu, useQuasar } from 'quasar';
import { map, pipe } from 'remeda';
import { computed, nextTick, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { ROUTE_NAME } from '../../router/router';
import { useArtPieceStore } from '../../stores/art-piece.store';
import { useUserStore } from '../../stores/user.store';

enum TabName {
  BUYER = '買家功能',
  SELLER = '賣家功能',
}

interface Btn {
  class?: string;
  label: string;
  caption?: string;
  captionClass?: string;
  icon?: string;
  color?: string;
  onClick?: () => void;
}

const emit = defineEmits<{
  'update': [value: `${TabName}`]
}>()

const router = useRouter();
const artPieceStore = useArtPieceStore();
const userStore = useUserStore();
const $q = useQuasar();

const menuRef = ref<InstanceType<typeof QMenu>>();

const creatorInfo = computed(() => userStore.userInfo?.identityMap.artPieceCreator);

const tab = ref(TabName.BUYER);
watch(tab, async () => {
  emit('update', tab.value)
  await nextTick();
  menuRef.value?.updatePosition();
});

const baseBtnList: Btn[] = [
  {
    icon: 'cached',
    color: 'white',
    class: 'bg-art-piece',
    label: '重新整理 - 作品列表頁面',
    caption: '重新讀取最新內容',
    captionClass: 'text-white',
    onClick() {
      artPieceStore.refresh();
    }
  },
]

const creatorBtnList: Btn[] = [
  {
    icon: 'add_photo_alternate',
    color: 'art-piece',
    label: '建立作品',
    caption: '建立作品內容並進行發佈',
    onClick() {
      router.push({
        name: ROUTE_NAME.ART_PIECE_LAUNCHER,
      });
    }
  },
  {
    icon: 'clinical_notes',
    color: 'art-piece',
    label: '個人主頁與作品管理',
    caption: '可以編輯個人主頁內容及管理作品',
    onClick() {
      router.push({
        name: ROUTE_NAME.ART_PIECE_CREATOR,
        params: {
          id: creatorInfo.value?._id,
        }
      });
    }
  },
]

function toSeller() {
  if (!userStore.userInfo) {
    $q.notify({
      message: '請先登入會員',
      color: 'negative',
    });

    router.push({ name: ROUTE_NAME.ACCOUNT_LOGIN });
    return;
  }

  router.push({
    name: ROUTE_NAME.ACCOUNT_PROFILE_ART_PIECE,
  });
}
</script>

<style
  scoped
  lang="sass"
>
</style>
