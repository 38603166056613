<template>
  <q-menu
    ref="menuRef"
    anchor="top middle"
    self="bottom middle"
    :offset="[0, 0]"
    class="w-[100vw] !max-w-[100vw] shadow-none !bg-transparent"
  >
    <q-card class="shadow-3 bg-white m-4  rounded-lg overflow-hidden">
      <q-card-section class="bg-[#F5F3EC] p-4 text-base text-flea-market text-center">
        二手拍賣功能選單
      </q-card-section>

      <q-card-section class="p-0 px-5">
        <q-tabs
          v-model="tab"
          active-color="primary"
        >
          <q-tab
            label="買家功能"
            :name="TabName.BUYER"
          />
          <q-tab
            label="賣家功能"
            :name="TabName.SELLER"
          />
        </q-tabs>
      </q-card-section>

      <q-tab-panels
        v-model="tab"
        animated
      >
        <q-tab-panel
          :name="TabName.BUYER"
          class=" p-4 flex-col gap-2"
        >
          <q-item
            v-for="btn, i in buyerBtnList"
            :key="i"
            v-ripple
            clickable
            class="btn-shadow px-6 py-4 !h-auto"
            :class="btn.class"
            @click="btn.onClick"
          >
            <q-item-section>
              <q-item-label
                class="text-base font-medium"
                :class="`text-${btn.color}`"
              >
                {{ btn.label }}
              </q-item-label>
              <q-item-label
                v-if="btn.caption"
                caption
                :class="btn.captionClass"
              >
                {{ btn.caption }}
              </q-item-label>
            </q-item-section>
            <q-item-section avatar>
              <q-icon
                :color="btn.color"
                :name="btn.icon"
              />
            </q-item-section>
          </q-item>
        </q-tab-panel>

        <q-tab-panel
          :name="TabName.SELLER"
          class=" p-4 flex-col gap-2 relative"
        >
          <q-item
            v-for="btn, i in sellerBtnList"
            :key="i"
            v-ripple
            clickable
            class="btn-shadow px-6 py-4 !h-auto"
            :class="btn.class"
            @click="btn.onClick"
          >
            <q-item-section>
              <q-item-label
                class="text-base font-medium"
                :class="`text-${btn.color}`"
              >
                {{ btn.label }}
              </q-item-label>
              <q-item-label
                v-if="btn.caption"
                caption
                :class="btn.captionClass"
              >
                {{ btn.caption }}
              </q-item-label>
            </q-item-section>
            <q-item-section avatar>
              <q-icon
                :color="btn.color"
                :name="btn.icon"
              />
            </q-item-section>
          </q-item>

          <div
            v-if="creatorInfo?.status !== 'normal'"
            class="flex-col flex-center gap-3 absolute inset-0 m-4 px-9
                 text-[#198EE2] border-[#198EE2] border rounded bg-white"
          >
            <div class=" text-base font-medium">
              賣家功能尚未申請完成
            </div>

            <q-btn
              unelevated
              class=" btn-shadow "
              @click="toSeller()"
            >
              <div class="flex flex-nowrap px-6">
                <div class=" px-10 whitespace-nowrap text-base">
                  賣家功能
                </div>
                <q-icon name="other_admission" />
              </div>
            </q-btn>

            <div class=" text-sm text-center">
              請使用會員功能 → 買賣相關 → 賣家功能 → 二手拍賣資格申請
            </div>
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
  </q-menu>
</template>

<script setup lang="ts">
import { QMenu, useQuasar } from 'quasar';
import { map, pipe } from 'remeda';
import { computed, nextTick, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { ROUTE_NAME } from '../../router/router';
import { useFleaMarketStore } from '../../stores/flea-market.store';
import { useUserStore } from '../../stores/user.store';

enum TabName {
  BUYER = '買家功能',
  SELLER = '賣家功能',
}

interface Btn {
  class?: string;
  label: string;
  caption?: string;
  captionClass?: string;
  icon?: string;
  color?: string;
  onClick?: () => void;
}

const emit = defineEmits<{
  'update': [value: `${TabName}`]
}>()

const router = useRouter();
const fleaMarketStore = useFleaMarketStore();
const userStore = useUserStore();
const $q = useQuasar();

const menuRef = ref<InstanceType<typeof QMenu>>();

const creatorInfo = computed(() => userStore.userInfo?.identityMap.fleaMarketCreator);

const tab = ref(TabName.BUYER);
watch(tab, async () => {
  emit('update', tab.value)
  await nextTick();
  menuRef.value?.updatePosition();
});

const buyerBtnList: Btn[] = [
  {
    icon: 'receipt',
    color: 'flea-market',
    label: '買家訂單管理',
    caption: '與購買二手拍賣有關的訂單',
    onClick() {
      router.push({
        name: ROUTE_NAME.ACCOUNT_ORDER_MANAGER_FLEA_MARKET,
      });
    }
  },
  {
    icon: 'cached',
    color: 'white',
    class: 'bg-flea-market',
    label: '重新整理 - 二手拍賣列表頁面',
    caption: '重新讀取最新內容',
    captionClass: 'text-white',
    onClick() {
      fleaMarketStore.refresh();
    }
  },
]

const sellerBtnList: Btn[] = [
  {
    icon: 'add_ad',
    color: 'flea-market',
    label: '發起二手拍賣',
    caption: '建立新的二手拍賣項目',
    onClick() {
      router.push({
        name: ROUTE_NAME.FLEA_MARKET_LAUNCHER,
      });
    }
  },
  {
    icon: 'developer_guide',
    color: 'flea-market',
    label: '二手拍賣管理',
    caption: '查看已建立的二手拍賣項目',
    onClick() {
      router.push({
        name: ROUTE_NAME.ACCOUNT_FLEA_MARKET_MANAGER,
      });
    }
  },
  {
    icon: 'order_approve',
    color: 'flea-market',
    label: '二手拍賣訂單管理',
    caption: '可以查看已成立的訂單',
    onClick() {
      router.push({
        name: ROUTE_NAME.ACCOUNT_FLEA_MARKET_CREATOR_ORDER_MANAGER,
      });
    }
  },
]

function toSeller() {
  if (!userStore.userInfo) {
    $q.notify({
      message: '請先登入會員',
      color: 'negative',
    });

    router.push({ name: ROUTE_NAME.ACCOUNT_LOGIN });
    return;
  }

  router.push({
    name: ROUTE_NAME.ACCOUNT_PROFILE_TRADE_SELLER_OTHER,
  });
}
</script>

<style scoped lang="sass">
</style>
