<template>
  <q-dialog
    v-model="visible"
    persistent
  >
    <div class="border rounded bg-white w-full">
      <div class="w-full text-center px-5 py-3 bg-[#F5F3EC]">
        APP 版本更新提示
      </div>

      <div class=" flex-col p-6 gap-4">
        <div class=" text-center">
          已推出新版本，建議更新後再使用 APP
        </div>

        <list-btn
          label="前往商店更新"
          caption="到商店將 APP 版本更新到最新版"
          icon="app_promo"
          icon-color="primary"
          @click="openStore()"
        />
      </div>

      <div class=" px-5 py-4 border-t">
        <q-btn
          class=" w-full text-white bg-negative p-4"
          unelevated
          @click="checkClose"
        >
          繼續使用舊版
        </q-btn>
      </div>
    </div>
  </q-dialog>
</template>

<script setup lang="ts">
/** 如果 store 有新版本，會顯示更新對話框 */

import { ref } from 'vue';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';

import ListBtn from './list-btn.vue';

import { useToggle } from '@vueuse/core';
import { useQuasar } from 'quasar';
import { openBaseDialog } from '../common/utils-quasar';
import to from 'await-to-js';

const [visible, toggleVisible] = useToggle(true);
const $q = useQuasar();

function openStore() {
  AppUpdate.openAppStore();
}

function checkClose() {
  openBaseDialog({
    title: '繼續使用舊版',
    message: '使用舊版本 APP 可能無法體驗最新功能，確定要繼續使用舊版 APP 嗎?',
  }).onOk((answer) => {
    if (answer !== 'y') return;
    toggleVisible(false);
  });
}

async function init() {
  if (!$q.platform.is.nativeMobile) {
    toggleVisible(false);
    return;
  }

  const [error, info] = await to(AppUpdate.getAppUpdateInfo());
  if (error) {
    toggleVisible(false);
    return;
  }

  if (info.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE) {
    toggleVisible(true);
    return;
  }

  toggleVisible(false);
}
init();
</script>

<style scoped lang="sass">
</style>