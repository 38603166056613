import { RouteRecordRaw } from 'vue-router'
import { defineConstants } from '@jgo-idea/types';

export const {
  ART_PIECE_ROUTE_NAME_KV: ART_PIECE_ROUTE_NAME,
  ART_PIECE_ROUTE_NAME_LIST,
  ART_PIECE_ROUTE_NAME_VALUES,
} = defineConstants(
  [
    {
      key: 'ART_PIECE',
      value: 'art-piece'
    },
    {
      key: 'ART_PIECE_DETAIL',
      value: 'art-piece-detail'
    },
    {
      key: 'ART_PIECE_CREATOR',
      value: 'art-piece-creator'
    },
    {
      key: 'ART_PIECE_CREATOR_APPLICATION_PROCESS',
      value: 'art-piece-creator-application-process'
    },
    {
      key: 'ART_PIECE_LAUNCHER',
      value: 'art-piece-launcher',
    },
  ] as const,
  'ART_PIECE_ROUTE_NAME'
);

export const artPieceRoutes: RouteRecordRaw[] = [
  {
    path: `/art-piece`,
    name: ART_PIECE_ROUTE_NAME.ART_PIECE,
    component: () => import('../views/the-art-piece.vue'),
    meta: {
      mainColorName: 'art-piece'
    }
  },
  {
    path: `/art-piece/detail/:id`,
    name: ART_PIECE_ROUTE_NAME.ART_PIECE_DETAIL,
    component: () => import('../views/the-art-piece-detail.vue'),
    meta: {
      mainColorName: 'art-piece'
    }
  },
  {
    path: `/art-piece-creator/:id`,
    name: ART_PIECE_ROUTE_NAME.ART_PIECE_CREATOR,
    component: () => import('../views/the-art-piece-creator.vue'),
    meta: {
      mainColorName: 'art-piece',
      header: {
        title: '作品分享者主頁面',
      }
    }
  },
  {
    path: `/art-piece-creator-application-process`,
    name: ART_PIECE_ROUTE_NAME.ART_PIECE_CREATOR_APPLICATION_PROCESS,
    component: () => import('../views/the-art-piece-creator-application-process.vue'),
    meta: {
      mainColorName: 'art-piece',
      hideNavTabs: true,
    }
  },
  {
    path: `/art-piece-launcher`,
    name: ART_PIECE_ROUTE_NAME.ART_PIECE_LAUNCHER,
    component: () => import('../views/the-art-piece-launcher.vue'),
    meta: {
      mainColorName: 'art-piece',
      hideNavTabs: true,
    }
  },
]

