<template>
  <div class="p-3 gap-2 flex flex-nowrap justify-between items-center border-t border-[#F1F1F1] rounded relative">
    <q-img
      :src="image"
      class=" w-16 h-16 rounded"
    />

    <div class=" text-[#636363]">
      <div class=" text-sm font-medium ">
        {{ variantLabel }}
      </div>
      <span class=" text-xs">
        ${{ specification.price }}
      </span>

      <number-picker
        v-model="quantity"
        dense
        size="0.7rem"
        :max="specification.remainingStockQuantity"
        :min="1"
        input-class="text-xs"
      />
    </div>

    <q-icon
      name="do_not_disturb_on"
      color="negative"
      size="0.75rem"
      class=" px-4 py-3 bg-white rounded shadow-sm"
      @click="remove"
    />
  </div>
</template>

<script setup lang="ts">
import { FleaMarketSale } from '@jgo-idea/types';
import { find, isDefined } from 'remeda';
import { clone, pipe } from 'remeda';
import { computed, ref, watch } from 'vue';
import { openBaseDialog } from '../../common/utils-quasar';
import {
  ShoppingCartFleaMarket, useFleaMarketShoppingCartStore
} from '../../stores/flea-market-shopping-cart.store';

import placeholder from '../../assets/placeholder.jpg';

import NumberPicker from '../number-picker.vue';

interface Props {
  fleaMarket: FleaMarketSale;
  commodityItem: ShoppingCartFleaMarket['commodities'][0];
}
const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  (e: 'update', data: Props['commodityItem']): void;
  (e: 'remove', data: Props['commodityItem']): void;
}>();

const shoppingCartStore = useFleaMarketShoppingCartStore();

const specification = computed(() => props.commodityItem.specification);
const image = computed(() => pipe(
  [
    props.fleaMarket.cover?.publicUrl,
    props.commodityItem.specification.image?.publicUrl,
    placeholder,
  ],
  find(isDefined),
));

const variantLabel = computed(() => specification.value.name);

function remove() {
  const specName = variantLabel.value || '無規格';
  const name = `${props.fleaMarket.name}-${specName}`;

  openBaseDialog({
    title: `確認刪除？`,
    message: `確定要刪除「${name}」嗎？`,
  }).onOk((result) => {
    if (result !== 'y') return;

    shoppingCartStore.put(props.fleaMarket, clone({
      ...props.commodityItem,
      quantity: 0,
    }));

    emit('remove', props.commodityItem);
  });


}

const quantity = ref(props.commodityItem.quantity);
watch(quantity, (value) => {
  const newData = clone({
    ...props.commodityItem,
    quantity: value,
  });

  shoppingCartStore.put(props.fleaMarket, newData);

  emit('update', newData);
});
</script>

<style scoped lang="sass">
</style>
