import { pipe } from "remeda";
import { isObject } from "lodash-es";
/** 當 value 為 falsely 時，回傳 defaultValue */
export function defaultValue(value, defaultValue) {
    return value ? value : defaultValue;
}
export function toPriceFormat(value, locales) {
    return pipe(value, (data) => {
        if (typeof data === 'string') {
            return parseFloat(data);
        }
        return data;
    }, (data) => {
        return data.toLocaleString(locales);
    });
}
/** 只保留 mongo 物件 _id 部分
 *
 * @example
 * ```typescript
 * const data = {
 *   a: 1,
 *   b: {
 *     _id: '2'
 *   },
 *   c: [
 *     {
 *       data: 3,
 *       d: {
 *         _id: '4',
 *       }
 *     }
 *   ]
 * }
 *
 * // 變為
 *
 * const data = {
 *   a: 1,
 *   b: '2',
 *   c: [
 *     {
 *       data: 3,
 *       d: '4'
 *     }
 *   ]
 * }
 * ```
 */
export function simplifyToDocIdDeep(object) {
    // 一般型別
    if (!isObject(object)) {
        const value = object;
        return value;
    }
    // 矩陣
    if (Array.isArray(object)) {
        const result = object.map(simplifyToDocIdDeep);
        return result;
    }
    // 單層
    if (object && '_id' in object && object['_id']) {
        return object._id;
    }
    // 深層物件
    const result = {};
    Object.entries(object).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            const newValue = value.map(simplifyToDocIdDeep);
            result[key] = newValue;
            return;
        }
        if (typeof value === 'object') {
            if ('_id' in value && value['_id']) {
                result[key] = value._id;
                return;
            }
            const newValue = simplifyToDocIdDeep(value);
            result[key] = newValue;
            return;
        }
        result[key] = value;
    });
    return result;
}
