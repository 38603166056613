import { defineConstants } from "./define";
export const { ART_PIECE_CREATOR_STATUS_KV: ART_PIECE_CREATOR_STATUS, ART_PIECE_CREATOR_STATUS_MAP_BY_KEY, ART_PIECE_CREATOR_STATUS_MAP_BY_VALUE, ART_PIECE_CREATOR_STATUS_VALUES } = defineConstants([
    {
        key: 'NORMAL',
        value: 'normal',
        label: '正常',
        colorName: 'positive',
    },
    {
        key: 'PENDING_REVIEW',
        value: 'pending-review',
        label: '待審核',
        description: '需管理方批准',
        colorName: 'red',
    },
    {
        key: 'SUSPENDED',
        value: 'suspended',
        label: '已停權',
        colorName: 'red',
    },
], 'ART_PIECE_CREATOR_STATUS');
