<template>
  <div class="bg-white">
    <transition
      name="fade-up"
      mode="out-in"
    >
      <div
        :key="stepInfo.title"
        class=" sticky top-0 text-base font-medium text-flea-market p-[0.875rem] text-center z-10"
      >
        {{ stepInfo.title }}
      </div>
    </transition>

    <q-stepper
      ref="stepper"
      v-model="step"
      contracted
      alternative-labels
      inactive-color="grey-4"
      done-color="flea-market"
      active-color="flea-market"
      done-icon=" "
      active-icon=" "
      animated
      flat
      header-class="bg-[#F5F3EC]"
      keep-alive
    >
      <template #message>
        <div class="message-bar text-flea-market bg-[#F5F3EC] text-center pb-3">
          <transition
            name="fade-down"
            mode="out-in"
          >
            <div
              :key="stepInfo.caption"
              class="text-xs "
            >
              {{ stepInfo.caption }}
            </div>
          </transition>
        </div>
      </template>

      <!-- 二手拍賣購物車 -->
      <q-step
        :name="1"
        title=""
        :done="step > 1"
      >
        <item-select
          v-model="form.selectedFleaMarkets"
          :list="cartStore.list"
          @back="emit('close')"
          @next="nextStep()"
        />
      </q-step>

      <!-- 選擇運送及付款方式 -->
      <q-step
        :name="2"
        title=""
        :done="step > 2"
      >
        <delivery-form
          v-if="form.selectedFleaMarkets.length > 0"
          v-model:delivery-method="form.deliveryMethod"
          v-model:payment-method="form.paymentMethod"
          :flea-markets="form.selectedFleaMarkets"
          :total-price="totalPrice"
          @back="prevStep"
          @next="nextStep"
        />
        <div
          v-else
          class="step-content text-2xl flex flex-center opacity-20"
        >
          請選擇要結帳的二手拍賣項目
        </div>
      </q-step>

      <!-- 填寫寄件資料 -->
      <q-step
        :name="3"
        title=""
        :done="step > 3"
      >
        <shipping-info-form
          v-if="form.selectedFleaMarkets.length > 0"
          v-model:buyer="form.buyerInfo"
          v-model:receiver="form.receiverInfo"
          v-model:my-ship711="form.myShip711Info"
          :flea-markets="form.selectedFleaMarkets"
          :delivery-method="form.deliveryMethod"
          :payment-method="form.paymentMethod"
          :total-price="totalPrice"
          @back="prevStep"
          @next="handleSubmitOrder"
        />

        <div
          v-else
          class="step-content text-2xl flex flex-center opacity-20"
        >
          請選擇要結帳的二手拍賣項目
        </div>
      </q-step>

      <!-- 預覽完成訂單 -->
      <q-step
        :name="4"
        title=""
      >
        <order-complete
          :order="order"
          @close="emit('close')"
        />
      </q-step>
    </q-stepper>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { ShoppingCartFleaMarket } from '../stores/flea-market-shopping-cart.store';
import {
  FleaMarketSaleDeliveryMethod,
  FleaMarketSale, FleaMarketOrder,
  FleaMarketSalePaymentMethod
} from '@jgo-idea/types';
import to from 'await-to-js';
import { instance } from '../common/api';
import { clone, find, prop, flatMap, map, pipe, reduce, uniq } from 'remeda';
import { getFleaMarketSaleStateInfo } from '@jgo-idea/common';
import { ROUTE_NAME } from '../router/router';
import { openBaseDialog } from '../common/utils-quasar';
import { debounce } from 'lodash-es';
import { returnObjectUnlessEmpty } from '../common/utils';
import { whenever } from '@vueuse/core';

import ItemSelect from './flea-market-shopping-cart/item-select.vue';
import DeliveryForm from './flea-market-shopping-cart/delivery-form.vue';
import ShippingInfoForm from './flea-market-shopping-cart/shipping-info-form.vue';
import OrderComplete from './flea-market-shopping-cart/order-complete.vue';

import { useFleaMarketShoppingCartStore } from '../stores/flea-market-shopping-cart.store';
import { CreateFleaMarketOrderParam, useFleaMarketSaleApi, useFleaMarketOrderApi } from '@jgo-idea/api';
import { useQuasar } from 'quasar';
import { useRouter } from 'vue-router';

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const $q = useQuasar();
const fleaMarketOrderApi = useFleaMarketOrderApi(instance);
const fleaMarketApi = useFleaMarketSaleApi(instance);
const cartStore = useFleaMarketShoppingCartStore();
const router = useRouter();

/** 使用者輸入的表單資料 */
const form = ref<{
  /** 已選擇二手拍賣 */
  selectedFleaMarkets: ShoppingCartFleaMarket[];
  deliveryMethod?: FleaMarketSaleDeliveryMethod;
  paymentMethod?: FleaMarketSalePaymentMethod;
  buyerInfo: NonNullable<FleaMarketOrder['buyerInfo']>;
  receiverInfo: NonNullable<FleaMarketOrder['receiverInfo']>;
  myShip711Info: NonNullable<FleaMarketOrder['myShip711Info']>;
}>({
  selectedFleaMarkets: [],
  deliveryMethod: undefined,
  paymentMethod: undefined,
  buyerInfo: {
    name: '',
    email: '',
    phone: '',
  },
  receiverInfo: {
    name: '',
    email: '',
    phone: '',
    county: '',
    township: '',
    address: '',
  },
  myShip711Info: {
    receiverName: '',
    receiverPhone: '',
    storeNumber: '',
    storeName: '',
    storeAddress: '',
  },
});
// watch(form, (newForm) => {
//   console.log(`🚀 ~ newForm:`, newForm);
// }, {
//   deep: true,
// });

onMounted(async () => {
  // 檢查庫存與內容變更
  $q.loading.show();
  const [error] = await to(processCheckStockQuantity());
  $q.loading.hide();
});

const hasInvalidGroup = computed(() => pipe(
  form.value.selectedFleaMarkets,
  map(prop('fleaMarket')),
  map(getFleaMarketSaleStateInfo),
  (infos) => infos.some((info) => info.value !== 'in-progress'),
));

/** 建立成功的訂單 */
const order = ref<FleaMarketOrder>();

const step = ref(1);
const stepLabelMap: Record<string, {
  title: string,
  caption: string,
}> = {
  '1': {
    title: '二手購物車',
    caption: '1. 選擇要結帳的商品',
  },
  '2': {
    title: '選擇運送及付款方式',
    caption: '2. 選擇付款方式',
  },
  '3': {
    title: '填寫收件人資料',
    caption: '3. 填寫收件人資料',
  },
  '4': {
    title: '訂購完成',
    caption: '訂單完成',
  },
}
const stepInfo = computed(() => stepLabelMap[`${step.value}`] ?? {
  title: '',
  caption: '',
});
/** 每次購物車顯示時，將步驟跳回第一步 */
whenever(() => cartStore.visible, async () => {
  step.value = 1;
});

async function checkStockQuantity() {
  // 取得所有二手拍賣資料
  const fleaMarkets = await pipe(undefined,
    () => pipe(cartStore.list,
      flatMap((item) => item.fleaMarket._id),
      uniq(),
    ),
    async (ids) => {
      const results: FleaMarketSale[] = [];

      for (const id of ids) {
        const [error, result] = await to(fleaMarketApi.findOne(id));
        if (error) {
          console.error(`取得二手拍賣資料失敗：${id}`);
          console.error(error);
          continue;
        }

        results.push(result);
      }

      return results;
    },
  );

  const texts: string[] = [];
  /** 數量變更的二手拍賣商品 */
  const updatedList = pipe(cartStore.list,
    clone,
    reduce((list: ShoppingCartFleaMarket[], item) => {
      const fleaMarket = fleaMarkets.find((fleaMarket) => fleaMarket._id === item.fleaMarket._id);
      if (!fleaMarket) return list;

      const partText: string[] = [];
      item.commodities.forEach((selectedCommodity) => {
        const commodity = fleaMarket.specifications.find(
          (spec) => spec.name === selectedCommodity.specification.name,
        );
        const name = selectedCommodity.specification.name || '無規格';

        if (!commodity) {
          partText.push(`「${name}」已被移除，請重新結帳`);
          // 設為 0 會自動清除
          selectedCommodity.quantity = 0;
          return;
        }

        if (selectedCommodity.quantity <= commodity.remainingStockQuantity) return;

        if (commodity.remainingStockQuantity === 0) {
          partText.push(`「${name}」數量不足，請重新結帳`);
        } else {
          partText.push(`「${name}」數量調整 ${selectedCommodity.quantity} → ${commodity.remainingStockQuantity}`);
        }

        selectedCommodity.quantity = commodity.remainingStockQuantity;
      });
      if (partText.length !== 0) {
        partText.unshift(`<b>「${fleaMarket.name}」二手拍賣</b>`);
        partText.push('');

        list.push(item);
      }

      texts.push(...partText);

      return list;
    }, []),
  );

  return {
    /** 提示文字，一行一個 */
    texts,
    /** 數量變更的二手拍賣商品 */
    updatedList,
  };
}
async function processCheckStockQuantity() {
  const { texts, updatedList } = await checkStockQuantity();
  if (texts.length === 0) {
    return;
  }

  emit('close');

  const message = texts.join('\n');
  updatedList.forEach((item) => {
    item.commodities.forEach((commodityItem) => {
      cartStore.put(item.fleaMarket, commodityItem);
    });
  });

  cartStore.fleaMarketsRefreshHook.trigger(
    pipe(updatedList, map(prop('fleaMarket'))),
  );

  openBaseDialog({
    title: '二手拍賣內容發生變更',
    message,
    actions: [
      {
        label: '確認',
        action: 'y',
      },
    ],
    persistent: true,
  }).onOk(() => {
    cartStore.setVisible(true);
    router.push({ name: ROUTE_NAME.FLEA_MARKET });
  });

  return Promise.reject();
}

const nextStep = debounce(async () => {
  if (hasInvalidGroup.value) {
    openBaseDialog({
      title: '二手拍賣已結束',
      message: '結帳內容包含已結束二手拍賣，無法進行成立訂單流程，請返回二手拍賣列表',
      actions: [
        {
          label: '返回二手拍賣列表',
          action: 'y',
        },
      ],
      persistent: true,
    }).onOk(() => {
      cartStore.clean();
      router.push({ name: ROUTE_NAME.FLEA_MARKET });
      emit('close');
    });
    return;
  }

  step.value += 1;
}, 1000, {
  leading: true,
  trailing: false,
});
const prevStep = debounce(() => {
  step.value -= 1;
}, 1000, {
  leading: true,
  trailing: false,
});

const totalPrice = computed(() =>
  pipe(0,
    // 商品數量價格
    (value) => {
      const result = pipe(
        form.value.selectedFleaMarkets,
        flatMap((item) => item.commodities),
        reduce(
          (acc, item) => acc += item.quantity * item.specification.price,
          value,
        ),
      );

      return result;
    },
    // 加上運費
    (value) => {
      const setting = form.value.selectedFleaMarkets[0]?.fleaMarket.deliverySettings;
      if (setting) {
        const target = pipe(
          setting,
          find(({ method }) => method === form.value.deliveryMethod),
        );

        value += target?.cost ?? 0;
      }

      return value;
    },
  )
);

async function handleSubmitOrder() {
  const [stockError] = await to(processCheckStockQuantity());
  if (stockError) return;

  if (hasInvalidGroup.value) {
    openBaseDialog({
      title: '二手拍賣已結束',
      message: '結帳內容包含已結束二手拍賣，無法進行成立訂單流程，請返回二手拍賣列表',
      actions: [
        {
          label: '返回二手拍賣列表',
          action: 'y',
        },
      ],
      persistent: true,
    }).onOk(() => {
      cartStore.clean();
      router.push({ name: ROUTE_NAME.FLEA_MARKET });
      emit('close');
    });
    return;
  }

  if (!form.value.deliveryMethod || !form.value.paymentMethod) {
    return;
  }

  const param: CreateFleaMarketOrderParam = {
    deliveryMethod: form.value.deliveryMethod,
    paymentMethod: form.value.paymentMethod,
    commodities: pipe(
      form.value.selectedFleaMarkets,
      clone,
      map((item) => ({
        sale: item.fleaMarket._id,
        list: item.commodities.map((commodity) => ({
          name: commodity.specification.name,
          quantity: commodity.quantity,
          price: commodity.specification.price
        })),
      })),
    ),
    buyerInfo: returnObjectUnlessEmpty(form.value.buyerInfo),
    receiverInfo: returnObjectUnlessEmpty(form.value.receiverInfo),
    myShip711Info: returnObjectUnlessEmpty(form.value.myShip711Info),

  }

  $q.loading.show();
  const [error, result] = await to(fleaMarketOrderApi.create(param));
  $q.loading.hide();

  // 刪除購物車對應項目
  form.value.selectedFleaMarkets.forEach((selectedItem) => {
    selectedItem.commodities.forEach((item) => {
      cartStore.put(
        selectedItem.fleaMarket,
        {
          ...clone(item),
          quantity: 0,
        },
      );
    });
  });


  if (error) {
    if (error.message.includes('庫存不足')) {
      processCheckStockQuantity()
      return;
    }

    console.error(error);
    $q.notify({
      type: 'negative',
      message: `建立訂單失敗：${error.message}`
    });
    return;
  }

  order.value = result;
  step.value = 4;
}
</script>

<style scoped lang="sass">
:deep(.q-stepper)
  .q-stepper__tab
    padding: 0.75rem 0
    min-height: 3rem
  .q-stepper__header
    height: 2.4rem
    min-height: 2.4rem
  .q-stepper__dot
    width: 0.75rem
    min-width: 0.75rem
    height: 0.75rem
    transition-duration: 0.4s
  .q-stepper__step-inner
    padding: 0rem

// 2 個 height 是為了相容舊瀏覽器，不支援 dvh 屬性會取 calc(80vh - 3rem)
:deep(.q-stepper__step-inner) > *
  height: calc(80vh - 3rem)
  height: 75dvh

</style>
