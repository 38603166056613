import { useCourseSettingApi } from '@jgo-idea/api';
import { CourseSetting } from '@jgo-idea/types';
import { useAsyncState } from '@vueuse/core';
import to from 'await-to-js';
import { defineStore, acceptHMRUpdate } from 'pinia';
import { clone } from 'remeda';
import { computed, ref } from 'vue';
import { instance } from '../common/api';

export const useCourseSettingStore = defineStore('course-setting', () => {
  const courseSettingApi = useCourseSettingApi(instance);

  const {
    state: data,
    isLoading,
    execute
  } = useAsyncState(async () => {
    return courseSettingApi.get();
  }, undefined);

  return {
    isLoading,
    data: computed(() => clone(data.value)),
    refresh: execute
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCourseSettingStore, import.meta.hot))
}