import { useRequest } from './utils';
;
;
export function useGroupBuyApi(instance) {
    const request = useRequest(instance);
    async function create(param) {
        return request({
            method: 'post',
            url: `/api/v1/group-buy`,
            data: param
        });
    }
    async function find(param) {
        return request({
            method: 'get',
            url: `/api/v1/group-buys`,
            data: param
        });
    }
    async function findOrderable(param) {
        return request({
            method: 'get',
            url: `/api/v1/group-buys/orderable`,
            data: param
        });
    }
    async function findVisitable(param) {
        return request({
            method: 'get',
            url: `/api/v1/group-buys/visitable`,
            data: param
        });
    }
    async function findOne(id) {
        return request({
            method: 'get',
            url: `/api/v1/group-buy/${id}`,
        });
    }
    async function update(id, param) {
        return request({
            method: 'patch',
            url: `/api/v1/group-buy/${id}`,
            data: param
        });
    }
    async function remove(id) {
        return request({
            method: 'delete',
            url: `/api/v1/group-buy/${id}`,
        });
    }
    async function findLogs(id, params) {
        return request({
            method: 'get',
            url: `/api/v1/group-buy/${id}/logs`,
            data: params,
        });
    }
    async function createCommodity(id, param) {
        return request({
            method: 'post',
            url: `/api/v1/group-buy/${id}/commodity`,
            data: param
        });
    }
    async function findOneCommodity(id, commodityId) {
        return request({
            method: 'get',
            url: `/api/v1/group-buy/${id}/commodity/${commodityId}`,
        });
    }
    async function updateCommodity(id, commodityId, param) {
        return request({
            method: 'patch',
            url: `/api/v1/group-buy/${id}/commodity/${commodityId}`,
            data: param
        });
    }
    async function removeCommodity(id, commodityId) {
        return request({
            method: 'delete',
            url: `/api/v1/group-buy/${id}/commodity/${commodityId}`,
        });
    }
    return {
        create,
        find,
        findOrderable,
        findVisitable,
        findOne,
        update,
        remove,
        findLogs,
        createCommodity,
        findOneCommodity,
        updateCommodity,
        removeCommodity,
    };
}
