import { RouteRecordRaw } from 'vue-router'
import { defineConstants } from '@jgo-idea/types';

export const {
  COURSE_ROUTE_NAME_KV: COURSE_ROUTE_NAME,
  COURSE_ROUTE_NAME_LIST,
  COURSE_ROUTE_NAME_VALUES,
} = defineConstants(
  [
    {
      key: 'COURSE',
      value: 'course'
    },
    {
      key: 'COURSE_DETAIL',
      value: 'course-detail'
    },
    {
      key: 'COURSE_CREATOR',
      value: 'course-creator'
    },
    {
      key: 'COURSE_CREATOR_APPLICATION_PROCESS',
      value: 'course-creator-application-process'
    },
    {
      key: 'COURSE_LAUNCHER',
      value: 'course-launcher',
    },
  ] as const,
  'COURSE_ROUTE_NAME'
);

export const courseRoutes: RouteRecordRaw[] = [
  {
    path: `/course`,
    name: COURSE_ROUTE_NAME.COURSE,
    component: () => import('../views/the-course.vue'),
    meta: {
      mainColorName: 'course'
    }
  },
  {
    path: `/course/detail/:id`,
    name: COURSE_ROUTE_NAME.COURSE_DETAIL,
    component: () => import('../views/the-course-detail.vue'),
    meta: {
      mainColorName: 'course'
    }
  },
  {
    path: `/course-creator/:id`,
    name: COURSE_ROUTE_NAME.COURSE_CREATOR,
    component: () => import('../views/the-course-creator.vue'),
    meta: {
      mainColorName: 'course',
      header: {
        title: '課程發起人主頁面',
      }
    }
  },
  {
    path: `/course-creator-application-process`,
    name: COURSE_ROUTE_NAME.COURSE_CREATOR_APPLICATION_PROCESS,
    component: () => import('../views/the-course-creator-application-process.vue'),
    meta: {
      mainColorName: 'course',
      hideNavTabs: true,
    }
  },
  {
    path: `/course-launcher`,
    name: COURSE_ROUTE_NAME.COURSE_LAUNCHER,
    component: () => import('../views/the-course-launcher.vue'),
    meta: {
      mainColorName: 'course',
      hideNavTabs: true,
    }
  },
]

