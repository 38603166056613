<template>
  <div class=" flex-col bg-white">
    <div
      class=" text-base p-3 text-center border-b"
      :class="colorClassMap.text"
    >
      超商取貨資料
    </div>

    <div class="flex-col gap-6 p-6">
      <form-item
        title="交貨便收件資料"
        caption="資料設定完成後僅能刪除或再次新增"
        class=" relative"
      >
        <template #title="scope">
          <div class="flex">
            {{ scope.title }}
            <q-space />
            <div
              class="text-link cursor-pointer"
              @click="openCreateForm"
            >
              新增交貨便收件資料
            </div>
          </div>
        </template>

        <template #default>
          <div
            v-if="infoOptions.length !== 0"
            class="flex-col gap-1"
          >
            <radio-group
              v-model="selectedItem"
              color="white"
              :options="infoOptions"
            >
              <template #option="{ option }">
                <div class="flex-cl text-xs text-[#999898] flex-1 gap-3 py-3">
                  <div class="flex-col">
                    <div
                      class=" text-xs text-[#999898]"
                      :class="{ 'text-white': option.selected }"
                    >
                      門市名稱
                    </div>
                    <div
                      class="text-sm font-medium text-[#636363]"
                      :class="{ 'text-white': option.selected }"
                    >
                      {{ option.value.storeName }}
                    </div>
                  </div>
                  <div class="flex-col">
                    <div
                      class=" text-xs text-[#999898]"
                      :class="{ 'text-white': option.selected }"
                    >
                      收件人名稱
                    </div>
                    <div
                      class="text-sm font-medium text-[#636363]"
                      :class="{ 'text-white': option.selected }"
                    >
                      {{ option.value.receiverName }}
                    </div>
                  </div>
                  <div class="flex-col">
                    <div
                      class=" text-xs text-[#999898]"
                      :class="{ 'text-white': option.selected }"
                    >
                      連絡電話
                    </div>
                    <div
                      class="text-sm font-medium text-[#636363]"
                      :class="{ 'text-white': option.selected }"
                    >
                      {{ option.value.receiverPhone }}
                    </div>
                  </div>
                </div>

                <q-icon
                  name="do_not_disturb_on"
                  color="negative"
                  size="0.75rem"
                  class=" px-4 py-3 bg-white rounded shadow-sm"
                  @click="removeInfo(option.value)"
                />
              </template>
            </radio-group>
          </div>

          <div
            v-else
            class="border text-negative border-negative py-6 rounded text-center"
          >
            請新增交貨便收件資料
          </div>

          <q-inner-loading :showing="isLoading" />
        </template>
      </form-item>
    </div>

    <q-space />

    <div class=" p-4 flex gap-4 bg-white">
      <q-btn
        label="取消"
        class=" shadow-base p-3 flex-1 "
        :class="colorClassMap.text"
        unelevated
        @click="emit('cancel')"
      />
      <q-btn
        label="帶入資料"
        :color="props.color"
        class=" shadow-base p-3 flex-1 "
        unelevated
        @click="handleSubmit"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { instance } from '../common/api';
import { openBaseDialog, openUsingDialog } from '../common/utils-quasar';
import { map, omit, pick, pipe } from 'remeda';
import to from 'await-to-js';
import { GroupBuy, GroupBuyOrder, UserMyShip711Info, UserReceiverInfo } from '@jgo-idea/types';

import FormItem from '../components/form-item.vue';
import UserMyShip711InfoCreateForm from './user-my-ship-711-info-create-form.vue';
import RadioGroup, { Option } from './radio-group.vue';

import { useAsyncState, useVModels } from '@vueuse/core';
import { useUserMyShip711InfoApi } from '@jgo-idea/api';
import { useQuasar } from 'quasar';

interface Props {
  modelValue?: GroupBuyOrder['myShip711Info'];
  color?: string;
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  color: 'group-buy',
});

const emit = defineEmits<{
  'update:modelValue': [value: NonNullable<Props['modelValue']>]
  'cancel': []
}>();

const colorClassMap = computed(() => ({
  text: `text-${props.color}`,
  bg: `bg-${props.color}`,
}));

const { modelValue } = useVModels(props, emit);
const selectedItem = ref<UserMyShip711Info>();

const userMyShip711InfoApi = useUserMyShip711InfoApi(instance);
const $q = useQuasar();

const {
  state: infoOptions,
  isLoading,
  execute: refresh
} = useAsyncState(
  async () => {
    const result = await userMyShip711InfoApi.find({
      limit: 99,
      skip: 0,
    });

    const options: Option<UserMyShip711Info>[] = pipe(
      result.data,
      map((value) => ({
        value,
        class: 'items-center border rounded p-4',
        activeClass: colorClassMap.value.bg
      })),
    );

    return options;
  },
  [],
  {
    resetOnExecute: false,
  }
);

function removeInfo(info: UserMyShip711Info) {
  openBaseDialog({
    title: `確定刪除`,
    message: `確定要刪除「${info.storeName}」資料嗎？`
  }).onOk(async (result) => {
    if (result !== 'y') return;

    const [error] = await to(
      userMyShip711InfoApi.remove(info._id)
    );
    if (error) {
      $q.notify({
        type: 'negative',
        message: `刪除「${info.storeName}」資料失敗`
      });
      return;
    }
    refresh();
  });
}

function openCreateForm() {
  const dialog = openUsingDialog(
    UserMyShip711InfoCreateForm,
    {
      color: props.color,
      'onUpdate:modelValue'() {
        refresh();
        dialog.hide();
      },
      onCancel() {
        dialog.hide();
      },
    },
    undefined,
    {
      // position: 'bottom',
      maximized: true,
      transitionShow: 'slide-up',
      transitionHide: 'slide-down',
    }
  );
}

function handleSubmit() {
  if (!selectedItem.value) return;

  modelValue.value = omit(
    selectedItem.value, ['_id', 'owner', 'timestamp']
  )
}

</script>

<style scoped lang="sass">
</style>
