<template>
  <q-form
    class="flex flex-col flex-nowrap"
    @validation-error="handleValidationError"
    @submit="handleSubmit"
  >
    <!-- 二手拍賣項目清單 -->
    <div class=" p-4">
      <flea-market-price-totalizer
        v-model:commodity-total-price="totalResult.commodity"
        :flea-markets="props.fleaMarkets"
        class=" rounded"
      />
    </div>

    <!-- 選項 -->
    <div class=" p-5 flex flex-col flex-nowrap gap-6">
      <!-- 運送方式 -->
      <form-item
        title="運送方式*"
        caption="材料會寄到同一個地方，如需個別寄送請分次報名"
      >
        <q-field
          v-model="deliveryMethod"
          :rules="notEmptyRule"
          borderless
          color="grey-8"
          no-error-icon
          hide-bottom-space
        >
          <delivery-radio-group
            v-model="deliveryMethod"
            :options="deliveryOptions"
            active-class="text-white bg-flea-market"
            class=" w-full"
          />
        </q-field>
      </form-item>

      <!-- 付款方式 -->
      <form-item title="付款方式*">
        <q-field
          v-model="paymentMethodValue"
          :rules="notEmptyRule"
          borderless
          color="grey-8"
          no-error-icon
          hide-bottom-space
        >
          <radio-group
            v-model="paymentMethodValue"
            :options="paymentOptions"
            class="w-full"
            dense
            color="white"
          />
        </q-field>
      </form-item>
    </div>

    <q-space />

    <div class=" bg-white">
      <div
        class="flex flex-nowrap items-center py-4 px-8
              bg-[#F5F3EC] text-flea-market text-xs font-medium"
        placeholder=""
      >
        <div class="flex-center gap-2">
          <q-icon
            name="payments"
            size="1rem"
          />
          總金額
        </div>
        <q-space />
        <div class="text-xs">
          NT${{ toPriceFormat(props.totalPrice) }}
        </div>
      </div>

      <!-- 按鈕 -->
      <div class="flex p-5 gap-4">
        <q-btn
          label="上一步"
          padding="1rem"
          unelevated
          class="flex-1 text-flea-market shadow-base"
          @click="emit('back')"
        />
        <q-btn
          label="下一步"
          padding="1rem"
          color="flea-market"
          class="flex-[2] "
          type="submit"
        />
      </div>
    </div>
  </q-form>
</template>

<script setup lang="ts">
import {
  FleaMarketSale,
  FleaMarketSaleDeliveryMethod,
  FleaMarketSalePaymentMethod,
  FLEA_MARKET_SALE_DELIVERY_METHOD_MAP_BY_VALUE,
  FLEA_MARKET_SALE_PAYMENT_METHOD_MAP_BY_VALUE,
} from '@jgo-idea/types';
import {
  pipe, map, filter
} from 'remeda';
import { computed, ref, watch, watchEffect } from 'vue';
import { ShoppingCartFleaMarket } from '../../stores/flea-market-shopping-cart.store';
import { toPriceFormat } from '@jgo-idea/common';

import FormItem from '../form-item.vue';
import FleaMarketPriceTotalizer from './flea-market-price-totalizer.vue';
import RadioGroup, { Option as RadioOption } from '../radio-group.vue';
import DeliveryRadioGroup from '../delivery-radio-group.vue';


import seven from '../../assets/7-eleven.svg';
import delivery from '../../assets/delivery.svg';
import shipping from '../../assets/shipping-product.svg';

import { useQuasar } from 'quasar';
import { createRule, notEmpty } from '../../common/validator';

interface Props {
  /** 要結帳的二手拍賣 */
  fleaMarkets: ShoppingCartFleaMarket[];
  deliveryMethod?: FleaMarketSaleDeliveryMethod;
  paymentMethod?: FleaMarketSalePaymentMethod;
  totalPrice: number;
}
const props = withDefaults(defineProps<Props>(), {
  deliveryMethod: undefined,
  paymentMethod: undefined,
});

const emit = defineEmits<{
  (e: 'update:deliveryMethod', value: Props['deliveryMethod']): void;
  (e: 'update:paymentMethod', value: Props['paymentMethod']): void;
  (e: 'back'): void;
  (e: 'next'): void;
}>();

const $q = useQuasar();

const notEmptyRule = createRule([notEmpty], '不可為空');

/** 運費、付款方式皆相同才可以合併結帳，所以取第一個就行 */
const fleaMarket = computed(() => props.fleaMarkets[0]?.fleaMarket as FleaMarketSale);

const deliveryIconMap: Record<FleaMarketSaleDeliveryMethod, string> = {
  'home': delivery,
  'my-ship-711-cash-on-delivery': seven,
  'my-ship-711-pickup': seven,
  'self-pickup': shipping,
}
const deliveryOptions = computed(() => {
  const result = pipe(
    fleaMarket.value.deliverySettings,
    map(({ method, cost }) => {
      const info = FLEA_MARKET_SALE_DELIVERY_METHOD_MAP_BY_VALUE[method];

      return {
        icon: deliveryIconMap[method],
        label: info.label,
        method,
        cost
      }
    }),
  );

  return result;
});

const deliveryToPaymentMap: Record<
  FleaMarketSaleDeliveryMethod, FleaMarketSalePaymentMethod[]
> = {
  'home': ['remittance'],
  'my-ship-711-cash-on-delivery': ['cash-on-delivery-in-store'],
  'my-ship-711-pickup': ['remittance'],
  'self-pickup': ['remittance'],
}
const paymentOptions = computed<RadioOption<FleaMarketSalePaymentMethod>[]>(() =>
  pipe(
    fleaMarket.value.paymentSettings,
    filter((setting) => {
      const deliveryMethodValue = deliveryMethod.value;

      if (!deliveryMethodValue) {
        return false;
      }

      const allowList = deliveryToPaymentMap[deliveryMethodValue];
      return allowList?.includes(setting.method);
    }),
    map((setting) => ({
      label: FLEA_MARKET_SALE_PAYMENT_METHOD_MAP_BY_VALUE[setting.method].label,
      value: setting.method,
      activeClass: 'text-white bg-flea-market',
      class: 'border rounded'
    }))
  )
)

const deliveryMethod = ref(
  /** 檢查可用選項中是否有初始值 */
  pipe(
    props.deliveryMethod,
    (method) => {
      const hasValue = deliveryOptions.value.some((option) =>
        option.method === method
      );
      return hasValue ? method : undefined;
    }
  ),
);
const paymentMethodValue = ref<FleaMarketSalePaymentMethod>();

/** deliveryMethod 變化時需要清空 paymentMethod */
watch(deliveryMethod, () => {
  paymentMethodValue.value = undefined;
});
watchEffect(() => {
  emit('update:deliveryMethod', deliveryMethod.value);
  emit('update:paymentMethod', paymentMethodValue.value);
});

const totalResult = ref({
  commodity: 0,
});

function handleValidationError() {
  $q.notify({
    type: 'negative', message: `請確認所有欄位不為空`
  });
}

function handleSubmit() {
  emit('next');
}
</script>

<style scoped lang="sass">
:deep(.option-item)
  &.q-item--active
    .q-badge
      color: white !important
  .option-item-text
    color: white
</style>
