import { useRequest } from './utils';
;
export function useCareerCompanyApi(instance) {
    const request = useRequest(instance);
    async function create(param) {
        return request({
            method: 'post',
            url: `/api/v1/career-company`,
            data: param
        });
    }
    async function findOne(id) {
        return request({
            method: 'get',
            url: `/api/v1/career-company/${id}`,
        });
    }
    async function find(param) {
        return request({
            method: 'get',
            url: `/api/v1/career-companies`,
            data: param
        });
    }
    async function update(id, param) {
        return request({
            method: 'patch',
            url: `/api/v1/career-company/${id}`,
            data: param
        });
    }
    async function remove(id) {
        return request({
            method: 'delete',
            url: `/api/v1/career-company/${id}`,
        });
    }
    async function findLogs(id, param) {
        return request({
            method: 'get',
            url: `/api/v1/career-company/${id}/logs`,
            data: param,
        });
    }
    return {
        create,
        findOne,
        find,
        update,
        remove,
        findLogs,
    };
}
