/** 用於建構列舉內容
 *
 * KV 是 key value 的意思。
 *
 * 定義矩陣資料記得加上 as const
 *
 * @example
 * ```typescript
 * // 運送方式常數定義
 * export const {
 *   DELIVERY_METHOD,
 *   DELIVERY_METHOD_MAP_BY_KEY,
 *   DELIVERY_METHOD_MAP_BY_VALUE,
 *   DELIVERY_METHOD_VALUES
 * } = defineConstants(
 *   [
 *     {
 *       key: 'HOME',
 *       value: 'home',
 *       label: '宅配',
 *       icon: delivery,
 *     },
 *     {
 *       key: 'SELF_PICKUP',
 *       value: 'self-pickup',
 *       label: '自取',
 *       icon: shipping,
 *     },
 *   ] as const,
 *   'DELIVERY_METHOD'
 * )
 * // 運送方式
 * export type DeliveryMethod = typeof DELIVERY_METHOD_VALUES[number];
 * ```
 */
export function defineConstants(defs, namespace) {
    const prefix = namespace ? `${namespace}_` : '';
    return {
        [`${prefix}KEYS`]: defs.map((item) => item.key),
        [`${prefix}VALUES`]: defs.map((item) => item.value),
        [`${prefix}KV`]: defs.reduce((map, item) => ({
            ...map,
            [item.key]: item.value,
        }), {}),
        [`${prefix}VK`]: defs.reduce((map, item) => ({
            ...map,
            [item.value]: item.key,
        }), {}),
        [`${prefix}MAP_BY_KEY`]: defs.reduce((map, item) => ({
            ...map,
            [item.key]: item,
        }), {}),
        [`${prefix}MAP_BY_VALUE`]: defs.reduce((map, item) => ({
            ...map,
            [item.value]: item,
        }), {}),
        [`${prefix}KEY_MAP`]: defs.reduce((map, item) => ({
            ...map,
            [item.key]: item,
        }), {}),
        [`${prefix}MAP`]: defs.reduce((map, item) => ({
            ...map,
            [item.key]: item.value,
        }), {}),
        [`${prefix}LIST`]: defs,
    };
}
