import { RouteRecordRaw } from 'vue-router'
import { defineConstants } from '@jgo-idea/types';

export const {
  CAREER_ROUTE_NAME_KV: CAREER_ROUTE_NAME,
  CAREER_ROUTE_NAME_LIST,
  CAREER_ROUTE_NAME_VALUES,
} = defineConstants(
  [
    // 公司
    {
      key: 'CAREER_COMPANY_LAUNCHER',
      value: 'career-company-launcher',
    },
    {
      key: 'CAREER_COMPANY_DETAIL',
      value: 'career-company-detail',
    },

    {
      key: 'CAREER_APPLICATION',
      value: 'career-application',
    },
    {
      key: 'CAREER_APPLICATION_MANAGER',
      value: 'career-application-manager',
    },
  ] as const,
  'CAREER_ROUTE_NAME'
);

export const careerRoutes: RouteRecordRaw[] = [
  {
    path: `/career-company-launcher`,
    name: CAREER_ROUTE_NAME.CAREER_COMPANY_LAUNCHER,
    component: () => import('../views/the-career-company-launcher.vue'),
  },
  {
    path: `/career-company-detail/:id`,
    name: CAREER_ROUTE_NAME.CAREER_COMPANY_DETAIL,
    component: () => import('../views/the-career-company-detail.vue'),
  },

  {
    path: `/career-application`,
    name: CAREER_ROUTE_NAME.CAREER_APPLICATION,
    component: () => import('../views/the-career-application.vue'),
  },

  {
    path: `/career-application-manager`,
    name: CAREER_ROUTE_NAME.CAREER_APPLICATION_MANAGER,
    component: () => import('../views/the-career-application-manager.vue'),
  },
]

