import { RouteRecordRaw } from 'vue-router'
import { defineConstants } from '@jgo-idea/types';
import { groupBuyLauncherRoute, GROUP_BUY_LAUNCHER_ROUTE_NAME_LIST } from './group-buy-launcher.router';

export const {
  GROUP_BUY_ROUTE_NAME_KV: GROUP_BUY_ROUTE_NAME,
  GROUP_BUY_ROUTE_NAME_LIST,
  GROUP_BUY_ROUTE_NAME_VALUES,
} = defineConstants(
  [
    {
      key: 'GROUP_BUY',
      value: 'group-buy'
    },
    {
      key: 'GROUP_BUY_DETAIL',
      value: 'group-buy-detail'
    },

    {
      key: 'GROUP_BUY_CREATOR',
      value: 'group-buy-creator'
    },

    ...GROUP_BUY_LAUNCHER_ROUTE_NAME_LIST,

    {
      key: 'GROUP_BUY_CREATOR_APPLICATION_PROCESS',
      value: 'group-buy-creator-application-process'
    },
  ] as const,
  'GROUP_BUY_ROUTE_NAME'
);

export const groupBuyRoutes: RouteRecordRaw[] = [
  // 團購
  {
    path: `/group-buy`,
    name: GROUP_BUY_ROUTE_NAME.GROUP_BUY,
    component: () => import('../views/the-group-buy.vue'),
    meta: {
      mainColorName: 'group-buy'
    }
  },
  {
    path: `/group-buy/detail/:id`,
    name: GROUP_BUY_ROUTE_NAME.GROUP_BUY_DETAIL,
    component: () => import('../views/the-group-buy-detail.vue'),
    meta: {
      mainColorName: 'group-buy'
    }
  },
  {
    path: `/group-buy-creator/:id`,
    name: GROUP_BUY_ROUTE_NAME.GROUP_BUY_CREATOR,
    component: () => import('../views/the-group-buy-creator.vue'),
    meta: {
      mainColorName: 'group-buy',
      header: {
        title: '團購主主頁面',
      }
    }
  },

  groupBuyLauncherRoute,

  {
    path: `/group-buy-creator-application-process`,
    name: GROUP_BUY_ROUTE_NAME.GROUP_BUY_CREATOR_APPLICATION_PROCESS,
    component: () => import('../views/the-group-buy-creator-application-process.vue'),
    meta: {
      mainColorName: 'group-buy',
      hideNavTabs: true,
    }
  },
]

