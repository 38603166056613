import { FLEA_MARKET_ACTUAL_STATE_MAP_BY_KEY, } from '@jgo-idea/types';
import { piped, pipe, reduce, uniq } from 'remeda';
import dayjs from 'dayjs';
export function isFleaMarketSale(data) {
    if (!data)
        return false;
    if (typeof data !== 'object')
        return false;
    if (!data._id ||
        !data.status ||
        !data.name ||
        !data.remittanceInfo ||
        !data.timestamp) {
        return false;
    }
    return true;
}
/** 判斷二手拍賣真實狀態。例如：未開始、團購中、已結束 */
export const getFleaMarketSaleStateInfo = piped(({ status, endAt }) => {
    if (status === 'pending-review') {
        return FLEA_MARKET_ACTUAL_STATE_MAP_BY_KEY.PENDING_REVIEW;
    }
    if (status === 'terminated') {
        return FLEA_MARKET_ACTUAL_STATE_MAP_BY_KEY.OVER;
    }
    const currentTimestamp = dayjs().unix();
    if (status === 'reject') {
        return FLEA_MARKET_ACTUAL_STATE_MAP_BY_KEY.REJECTED;
    }
    if (currentTimestamp > endAt) {
        return FLEA_MARKET_ACTUAL_STATE_MAP_BY_KEY.OVER;
    }
    return FLEA_MARKET_ACTUAL_STATE_MAP_BY_KEY.IN_PROGRESS;
});
/** 取得價格上下限 */
export function getFleaMarketSalePriceRange({ specifications }) {
    return pipe(specifications, reduce((acc, { price }) => {
        acc[0] = Math.min(acc[0], price);
        acc[1] = Math.max(acc[1], price);
        return acc;
    }, [Infinity, 0]), uniq());
}
