<template>
  <div class=" flex flex-col flex-nowrap">
    <div class="flex flex-col flex-nowrap ">
      <item-select-creator
        v-for="group, i in groups"
        :key="i"
        v-model="modelValue"
        :list="group"
        class=" mb-3 m-3 "
      />

      <div
        v-if="groups.length === 0"
        class=" text-2xl p-5 flex-center opacity-20 h-[30vh]"
      >
        無任何商品
      </div>
    </div>

    <q-space />

    <div class=" p-3 px-6 bg-white">
      <div class="flex text-[#636363] text-base font-medium">
        <span>
          結帳金額總計
        </span>
        <q-space />
        <span>
          NT${{ toPriceFormat(totalPrice) }}
        </span>
      </div>

      <!-- 按鈕 -->
      <div class="flex mt-4 gap-4">
        <q-btn
          label="返回"
          padding="1rem"
          unelevated
          class="flex-1 text-flea-market shadow-base"
          @click="emit('back')"
        />
        <q-btn
          label="我要結帳"
          padding="1rem"
          color="flea-market"
          unelevated
          class="flex-[2]"
          @click="next"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
/** 用於選擇結帳商品 */
import {
  pipe, groupBy, values,
  prop, map, flatten, reduce,
} from 'remeda';
import { toPriceFormat } from '@jgo-idea/common';
import { computed } from 'vue';
import { computedWatch } from '../../composables/computed-watch';
import { ShoppingCartFleaMarket } from '../../stores/flea-market-shopping-cart.store';

import ItemSelectCreator from './item-select-creator.vue';

import { useVModel } from '@vueuse/core';
import { useQuasar } from 'quasar';

interface Props {
  /** 已選擇的二手拍賣 */
  modelValue: ShoppingCartFleaMarket[];
  /** 目前所有二手拍賣清單 */
  list: ShoppingCartFleaMarket[];
}
const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  (e: 'update:modelValue', value: Props['modelValue']): void;
  (e: 'next'): void;
  (e: 'back'): void;
}>();

const modelValue = useVModel(props, 'modelValue', emit, { deep: true });
const $q = useQuasar();

/** 根據二手賣家分類 */
const groups = computed<ShoppingCartFleaMarket[][]>(() => {
  const result = pipe(
    props.list,
    groupBy((data) => data.fleaMarket.creator?._id),
    values,
  );

  return result;
});

const totalPrice = computedWatch(modelValue, 0, (value) => {
  return pipe(
    value,
    map(prop('commodities')),
    flatten(),
    reduce((acc, item) => acc + item.quantity * item.specification.price, 0),
  )
});

const disableNext = computed(() => props.modelValue.length === 0);
function next() {
  if (disableNext.value) {
    $q.notify({
      type: 'negative',
      message: '請選擇要結帳的項目'
    });
    return;
  }

  emit('next');
}
</script>

<style scoped lang="sass">
</style>
