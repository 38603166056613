import { RouteRecordRaw } from 'vue-router'
import { defineConstants } from '@jgo-idea/types';
import { useUserStore } from '../stores/user.store';
import { Notify } from 'quasar';


export const {
  ACCOUNT_ROUTE_NAME_KV: ACCOUNT_ROUTE_NAME,
  ACCOUNT_ROUTE_NAME_MAP,
  ACCOUNT_ROUTE_NAME_LIST,
} = defineConstants(
  [
    {
      key: 'ACCOUNT',
      value: 'account'
    },
    {
      key: 'ACCOUNT_LOGIN',
      value: 'account-login'
    },
    {
      key: 'ACCOUNT_REQUIRED_SETTING',
      value: 'account-required-setting'
    },


    // #region 團購管理
    {
      key: 'ACCOUNT_GROUP_BUY_MANAGER',
      value: 'account-group-buy-manager'
    },
    {
      key: 'ACCOUNT_GROUP_BUY_ITEM_DETAIL',
      value: 'account-group-buy-item-detail'
    },
    // - 團購主管理訂單
    {
      key: 'ACCOUNT_GROUP_BUY_CREATOR_ORDER_MANAGER',
      value: 'account-group-buy-creator-order-manager'
    },
    // - 項目細節頁面，可以編輯部份內容
    {
      key: 'ACCOUNT_GROUP_BUY_CREATOR_ORDER_DETAIL',
      value: 'account-group-buy-creator-order-detail'
    },
    // #endregion 團購管理

    // #region 課程管理
    {
      key: 'ACCOUNT_COURSE_MANAGER',
      value: 'account-course-manager'
    },
    // - 課程發起人管理訂單
    {
      key: 'ACCOUNT_COURSE_CREATOR_ORDER_MANAGER',
      value: 'account-course-creator-order-manager'
    },
    // - 訂單項目細節頁面，可以編輯部份內容
    {
      key: 'ACCOUNT_COURSE_CREATOR_ORDER_DETAIL',
      value: 'account-course-creator-order-detail'
    },
    // - 項目細節頁面，可以編輯部份內容
    {
      key: 'ACCOUNT_COURSE_ITEM_DETAIL',
      value: 'account-course-item-detail'
    },
    // #endregion 課程管理

    // #region 二手管理
    {
      key: 'ACCOUNT_FLEA_MARKET_MANAGER',
      value: 'account-flea-market-manager'
    },
    // - 二手賣家管理訂單
    {
      key: 'ACCOUNT_FLEA_MARKET_CREATOR_ORDER_MANAGER',
      value: 'account-flea-market-creator-order-manager'
    },
    // - 訂單項目細節頁面，可以編輯部份內容
    {
      key: 'ACCOUNT_FLEA_MARKET_CREATOR_ORDER_DETAIL',
      value: 'account-flea-market-creator-order-detail'
    },
    // - 項目細節頁面，可以編輯部份內容
    {
      key: 'ACCOUNT_FLEA_MARKET_ITEM_DETAIL',
      value: 'account-flea-market-item-detail'
    },
    // #endregion 二手管理

    // #region 應徵
    // - 公司管理頁面
    {
      key: 'ACCOUNT_CAREER_COMPANY_MANAGER',
      value: 'account-career-company-manager'
    },
    // - 公司細節頁面，可以管理內容
    {
      key: 'ACCOUNT_CAREER_COMPANY_DETAIL',
      value: 'account-career-company-detail'
    },
    // #endregion 應徵

    // #region 一般應徵
    // - 招募細節頁面，可以管理內容
    {
      key: 'ACCOUNT_CAREER_STANDARD_RECRUIT_DETAIL',
      value: 'account-career-standard-recruit-detail'
    },
    // #endregion 一般應徵

    // #region 模特應徵
    // - 招募細節頁面，可以管理內容
    {
      key: 'ACCOUNT_CAREER_MODEL_RECRUIT_DETAIL',
      value: 'account-career-model-recruit-detail',
    },
    // #endregion 一般應徵

    // #region 個人設定資料管理
    {
      key: 'ACCOUNT_BUYER_RECEIVER_INFO_MANAGER',
      value: 'account-buyer-receiver-info-manager'
    },
    {
      key: 'ACCOUNT_MY_SHIP_711_INFO_MANAGER',
      value: 'account-my-ship-711-info-manager'
    },
    {
      key: 'ACCOUNT_REMITTANCE_INFO_MANAGER',
      value: 'account-remittance-info-manager'
    },
    {
      key: 'ACCOUNT_STUDENT_INFO_MANAGER',
      value: 'account-student-info-manager'
    },
    // #endregion 個人設定資料管理

    // #region 買家訂單管理
    {
      key: 'ACCOUNT_ORDER_MANAGER',
      value: 'account-order-manager'
    },
    // - 團購
    {
      key: 'ACCOUNT_ORDER_MANAGER_GROUP_BUY',
      value: 'account-order-manager-group-buy'
    },
    {
      key: 'ACCOUNT_GROUP_BUY_ORDER_DETAIL',
      value: 'account-group-buy-order-detail'
    },
    // - 課程
    {
      key: 'ACCOUNT_ORDER_MANAGER_COURSE',
      value: 'account-order-manager-course'
    },
    {
      key: 'ACCOUNT_COURSE_ORDER_DETAIL',
      value: 'account-course-order-detail'
    },
    // - 二手
    {
      key: 'ACCOUNT_ORDER_MANAGER_FLEA_MARKET',
      value: 'account-order-manager-flea-market'
    },
    {
      key: 'ACCOUNT_FLEA_MARKET_ORDER_DETAIL',
      value: 'account-flea-market-order-detail'
    },
    // #endregion 買家訂單管理

    // #region 使用者功能總攬
    {
      key: 'ACCOUNT_PROFILE',
      value: 'account-profile'
    },
    {
      key: 'ACCOUNT_PROFILE_MEMBER',
      value: 'account-profile-member'
    },
    {
      key: 'ACCOUNT_PROFILE_MEMBER_SETTING',
      value: 'account-profile-member-setting'
    },
    {
      key: 'ACCOUNT_PROFILE_MEMBER_ADMIN_SETTING',
      value: 'account-profile-member-admin-setting'
    },
    {
      key: 'ACCOUNT_PROFILE_TRADE',
      value: 'account-profile-trade'
    },
    {
      key: 'ACCOUNT_PROFILE_ART_PIECE',
      value: 'account-profile-art-piece'
    },
    {
      key: 'ACCOUNT_PROFILE_CAREER',
      value: 'account-profile-career',
    },

    // - 買家功能
    {
      key: 'ACCOUNT_PROFILE_TRADE_BUYER',
      value: 'account-profile-trade-buyer'
    },
    {
      key: 'ACCOUNT_PROFILE_TRADE_BUYER_OTHER',
      value: 'account-profile-trade-buyer-other'
    },

    // - 賣家功能
    {
      key: 'ACCOUNT_PROFILE_TRADE_SELLER',
      value: 'account-profile-trade-seller'
    },
    {
      key: 'ACCOUNT_PROFILE_TRADE_SELLER_OTHER',
      value: 'account-profile-trade-seller-other'
    },
    {
      key: 'ACCOUNT_PROFILE_TRADE_SELLER_GROUP_BUY',
      value: 'account-profile-trade-seller-group-buy'
    },
    {
      key: 'ACCOUNT_PROFILE_TRADE_SELLER_COURSE',
      value: 'account-profile-trade-seller-course'
    },
    {
      key: 'ACCOUNT_PROFILE_TRADE_SELLER_FLEA_MARKET',
      value: 'account-profile-trade-seller-flea-market'
    },
    // #endregion 使用者功能總攬

    // 收藏 
    {
      key: 'ACCOUNT_COLLECTION',
      value: 'account-collection'
    },
  ] as const,
  'ACCOUNT_ROUTE_NAME'
);


export const accountRoute: RouteRecordRaw = {
  path: `/account`,
  name: ACCOUNT_ROUTE_NAME.ACCOUNT,
  redirect: {
    name: ACCOUNT_ROUTE_NAME.ACCOUNT_LOGIN,
  },
  component: () => import('../views/the-account.vue'),
  children: [
    {
      path: `login`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_LOGIN,
      component: () => import('../views/the-account-login.vue'),
      meta: {
        header: {
          title: '會員功能',
          hideBackBtn: true,
        },
      },
    },
    {
      path: `required-setting`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_REQUIRED_SETTING,
      component: () => import('../views/the-account-required-setting.vue'),
      meta: {
        hideNavTabs: true,
      },
    },

    // 團購
    {
      path: `group-buy-manager`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_GROUP_BUY_MANAGER,
      component: () => import('../views/the-account-group-buy-manager.vue'),
      meta: {
        header: {
          title: '團購管理',
        },
      },
    },
    {
      path: `group-buy-item-detail/:id`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_GROUP_BUY_ITEM_DETAIL,
      component: () => import('../views/the-account-group-buy-item-detail.vue'),
      meta: {
        header: {
          title: '檢視團購項目細節',
        },
      },
    },
    {
      path: `group-buy-creator-order-manager`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_GROUP_BUY_CREATOR_ORDER_MANAGER,
      component: () => import('../views/the-account-group-buy-creator-order-manager.vue'),
      meta: {
        header: {
          title: '團購主訂單管理',
        },
      },
    },
    {
      path: `group-buy-creator-order-manager/order/:id`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_GROUP_BUY_CREATOR_ORDER_DETAIL,
      component: () => import('../views/the-account-group-buy-creator-order-detail.vue'),
      meta: {
        header: {
          title: '訂單詳情',
        },
      },
    },

    {
      path: `group-buy-order/:id`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_GROUP_BUY_ORDER_DETAIL,
      component: () => import('../views/the-account-order-manager-group-buy-order-detail.vue'),
      meta: {
        header: {
          title: '訂單詳情',
        },
      },
    },

    // 課程
    {
      path: `course-manager`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_COURSE_MANAGER,
      component: () => import('../views/the-account-course-manager.vue'),
      meta: {
        header: {
          title: '課程管理',
        },
      },
    },
    {
      path: `course-item-detail/:id`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_COURSE_ITEM_DETAIL,
      component: () => import('../views/the-account-course-item-detail.vue'),
      meta: {
        header: {
          title: '檢視課程細節',
        },
      },
    },
    {
      path: `course-order/:id`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_COURSE_ORDER_DETAIL,
      component: () => import('../views/the-account-order-manager-course-order-detail.vue'),
      meta: {
        header: {
          title: '訂單詳情',
        },
      },
    },
    {
      path: `course-creator-order-manager`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_COURSE_CREATOR_ORDER_MANAGER,
      component: () => import('../views/the-account-course-creator-order-manager.vue'),
      meta: {
        header: {
          title: '課程訂單管理',
        },
      },
    },
    {
      path: `course-creator-order-manager/order/:id`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_COURSE_CREATOR_ORDER_DETAIL,
      component: () => import('../views/the-account-course-creator-order-detail.vue'),
      meta: {
        header: {
          title: '訂單詳情',
        },
      },
    },

    // 二手拍賣
    {
      path: `flea-market-manager`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_FLEA_MARKET_MANAGER,
      component: () => import('../views/the-account-flea-market-manager.vue'),
      meta: {
        header: {
          title: '二手拍賣管理',
        },
      },
    },
    {
      path: `flea-market-item-detail/:id`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_FLEA_MARKET_ITEM_DETAIL,
      component: () => import('../views/the-account-flea-market-item-detail.vue'),
      meta: {
        header: {
          title: '檢視二手拍賣細節',
        },
      },
    },
    {
      path: `flea-market-creator-order-manager`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_FLEA_MARKET_CREATOR_ORDER_MANAGER,
      component: () => import('../views/the-account-flea-market-creator-order-manager.vue'),
      meta: {
        header: {
          title: '二手拍賣訂單管理',
        },
      },
    },
    {
      path: `flea-market-creator-order-manager/order/:id`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_FLEA_MARKET_CREATOR_ORDER_DETAIL,
      component: () => import('../views/the-account-flea-market-creator-order-detail.vue'),
      meta: {
        header: {
          title: '訂單詳情',
        },
      },
    },
    {
      path: `flea-market-order/:id`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_FLEA_MARKET_ORDER_DETAIL,
      component: () => import('../views/the-account-order-manager-flea-market-order-detail.vue'),
      meta: {
        header: {
          title: '訂單詳情',
        },
      },
    },

    // 應徵
    {
      path: `career-company-manager`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_CAREER_COMPANY_MANAGER,
      component: () => import('../views/the-account-career-company-manager.vue'),
      meta: {
        header: {
          title: '公司及招募職缺管理',
        },
      },
    },
    {
      path: `career-company-detail/:id`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_CAREER_COMPANY_DETAIL,
      component: () => import('../views/the-account-career-company-detail.vue'),
      meta: {
        header: {
          title: '檢視公司細節',
        },
      },
    },

    // 一般應徵
    {
      path: `career-standard-recruit-detail/:id`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_CAREER_STANDARD_RECRUIT_DETAIL,
      component: () => import('../views/the-account-career-standard-recruit-detail.vue'),
      meta: {
        header: {
          title: '檢視招募內容細節',
        },
      },
    },
    // 模特應徵
    {
      path: `career-model-recruit-detail/:id`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_CAREER_MODEL_RECRUIT_DETAIL,
      component: () => import('../views/the-account-career-model-recruit-detail.vue'),
      meta: {
        header: {
          title: '檢視模特招募內容細節',
        },
      },
    },


    // 個人資料管理
    {
      path: `buyer-receiver-info-manager`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_BUYER_RECEIVER_INFO_MANAGER,
      component: () => import('../views/the-account-buyer-receiver-info-manager.vue'),
      meta: {
        header: {
          title: '訂購人/收件人資料',
        },
      },
    },
    {
      path: `my-ship-711-info-manager`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_MY_SHIP_711_INFO_MANAGER,
      component: () => import('../views/the-account-my-ship-711-info-manager.vue'),
      meta: {
        header: {
          title: '超商取貨資料',
        },
      },
    },
    {
      path: `remittance-info-manager`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_REMITTANCE_INFO_MANAGER,
      component: () => import('../views/the-account-remittance-info-manager.vue'),
      meta: {
        header: {
          title: '收款帳號設定',
        },
      },
    },
    {
      path: `student-info-manager`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_STUDENT_INFO_MANAGER,
      component: () => import('../views/the-account-student-info-manager.vue'),
      meta: {
        header: {
          title: '學生上課資料',
        },
      },
    },

    // 訂單管理
    {
      path: `order-manager`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_ORDER_MANAGER,
      component: () => import('../views/the-account-order-manager.vue'),
      redirect: {
        name: ACCOUNT_ROUTE_NAME.ACCOUNT_ORDER_MANAGER_GROUP_BUY,
      },
      meta: {
        header: {
          title: '買家訂單管理',
        },
      },
      children: [
        {
          path: `group-buy`,
          name: ACCOUNT_ROUTE_NAME.ACCOUNT_ORDER_MANAGER_GROUP_BUY,
          component: () => import('../views/the-account-order-manager-group-buy.vue'),
        },
        {
          path: `course`,
          name: ACCOUNT_ROUTE_NAME.ACCOUNT_ORDER_MANAGER_COURSE,
          component: () => import('../views/the-account-order-manager-course.vue'),
        },
        {
          path: 'flea-market',
          name: ACCOUNT_ROUTE_NAME.ACCOUNT_ORDER_MANAGER_FLEA_MARKET,
          component: () => import('../views/the-account-order-manager-flea-market.vue'),
        },
      ]
    },

    // 個人資料
    {
      path: `profile`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_PROFILE,
      component: () => import('../views/the-account-profile.vue'),
      meta: {
        header: {
          title: '會員功能',
          hideBackBtn: true,
        },
      },
      children: [
        {
          path: `member`,
          name: ACCOUNT_ROUTE_NAME.ACCOUNT_PROFILE_MEMBER,
          component: () => import('../views/the-account-profile-member.vue'),
          meta: {
            header: {
              title: '會員功能管理',
            },
          },
          children: [
            {
              path: `setting`,
              name: ACCOUNT_ROUTE_NAME.ACCOUNT_PROFILE_MEMBER_SETTING,
              component: () => import('../views/the-account-profile-member-setting.vue'),
              meta: {
                header: {
                  title: '個人資料設定',
                },
              },
            },
            {
              path: `admin-setting`,
              name: ACCOUNT_ROUTE_NAME.ACCOUNT_PROFILE_MEMBER_ADMIN_SETTING,
              component: () => import('../views/the-account-profile-member-admin-setting.vue'),
              meta: {
                header: {
                  title: '後台帳號密碼設定',
                },
              },
            },
          ],
        },
        {
          path: `trade`,
          name: ACCOUNT_ROUTE_NAME.ACCOUNT_PROFILE_TRADE,
          component: () => import('../views/the-account-profile-trade.vue'),
          meta: {
            header: {
              title: '買賣功能管理',
            },
          },
          redirect: {
            name: ACCOUNT_ROUTE_NAME.ACCOUNT_PROFILE_TRADE_BUYER,
          },
          children: [
            {
              path: `buyer`,
              name: ACCOUNT_ROUTE_NAME.ACCOUNT_PROFILE_TRADE_BUYER,
              component: () => import('../views/the-account-profile-trade-buyer.vue'),
              redirect: {
                name: ACCOUNT_ROUTE_NAME.ACCOUNT_PROFILE_TRADE_BUYER_OTHER,
              },
              children: [
                {
                  path: `other`,
                  name: ACCOUNT_ROUTE_NAME.ACCOUNT_PROFILE_TRADE_BUYER_OTHER,
                  component: () => import('../views/the-account-profile-trade-buyer-other.vue'),
                },
              ]
            },
            {
              path: `seller`,
              name: ACCOUNT_ROUTE_NAME.ACCOUNT_PROFILE_TRADE_SELLER,
              component: () => import('../views/the-account-profile-trade-seller.vue'),
              redirect: {
                name: ACCOUNT_ROUTE_NAME.ACCOUNT_PROFILE_TRADE_SELLER_OTHER,
              },
              children: [
                {
                  path: `other`,
                  name: ACCOUNT_ROUTE_NAME.ACCOUNT_PROFILE_TRADE_SELLER_OTHER,
                  component: () => import('../views/the-account-profile-trade-seller-other.vue'),
                },
                {
                  path: `group-buy`,
                  name: ACCOUNT_ROUTE_NAME.ACCOUNT_PROFILE_TRADE_SELLER_GROUP_BUY,
                  component: () => import('../views/the-account-profile-trade-seller-group-buy.vue'),
                },
                {
                  path: `course`,
                  name: ACCOUNT_ROUTE_NAME.ACCOUNT_PROFILE_TRADE_SELLER_COURSE,
                  component: () => import('../views/the-account-profile-trade-seller-course.vue'),
                },
                {
                  path: `flea-market`,
                  name: ACCOUNT_ROUTE_NAME.ACCOUNT_PROFILE_TRADE_SELLER_FLEA_MARKET,
                  component: () => import('../views/the-account-profile-trade-seller-flea-market.vue'),
                },

              ]
            },
          ]
        },
        {
          path: `art-piece`,
          name: ACCOUNT_ROUTE_NAME.ACCOUNT_PROFILE_ART_PIECE,
          component: () => import('../views/the-account-profile-art-piece.vue'),
          meta: {
            header: {
              title: '作品功能管理',
            },
          },
        },
        {
          path: `career`,
          name: ACCOUNT_ROUTE_NAME.ACCOUNT_PROFILE_CAREER,
          component: () => import('../views/the-account-profile-career.vue'),
          meta: {
            header: {
              title: '應徵相關功能管理',
            },
          },
        },
      ]
    },

    // 收藏
    {
      path: `collection`,
      name: ACCOUNT_ROUTE_NAME.ACCOUNT_COLLECTION,
      component: () => import('../views/the-account-collection.vue'),
      meta: {
        header: {
          title: '收藏的內容',
        },
      },
      beforeEnter: () => {
        const userStore = useUserStore();
        if (!userStore.userInfo) {
          Notify.create({
            type: 'negative',
            message: '下載 APP 註冊帳號即可使用收藏功能',
          })
          return false;
        }

        return true;
      },
    },
  ]
};
