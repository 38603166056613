<template>
  <div class="relative">
    <q-table
      v-model:selected="selectedCommodities"
      :rows="props.groupBuyItem.commodities"
      :columns="columns"
      selection="multiple"
      :rows-per-page-options="[0]"
      hide-bottom
      dense
      flat
      separator="none"
      :row-key="getRowKey"
    >
      <template #header-selection="scope">
        <q-checkbox
          v-model="scope.selected"
          :disable="!!selectionDisableReason"
          color="group-buy"
          @click="showDisableReason"
        />
      </template>

      <template #body-selection="scope">
        <q-checkbox
          v-model="scope.selected"
          :disable="!!selectionDisableReason"
          color="group-buy"
        />
      </template>

      <template #header-cell-item>
        <q-th class="text-left !py-6 !border-t-1 !border-[#F1F1F1]">
          <div class="flex flex-nowrap items-center w-full">
            <span class=" text-base text-[#636363] font-medium">
              <div class=" whitespace-pre-line">
                {{ groupBuy.name }}
              </div>
            </span>
            <span
              class="text-xs text-[#198EE2] ml-3"
              @click="handleContinueShopping"
            >
              繼續選購
            </span>
          </div>

          <div
            v-if="groupBuy.mergeableBilling"
            class=" text-[0.625rem] text-[#999898]"
          >
            (可合併結帳)
          </div>
        </q-th>
      </template>

      <template #body-cell-item="tableProps">
        <table-item
          :commodity-item="tableProps.row"
          :group-buy="groupBuy"
          @update="handleItemUpdate"
          @remove="handleItemRemove"
        />
      </template>
    </q-table>

    <transition name="opacity">
      <div
        v-if="selectionDisableReason"
        class=" absolute inset-0 bg-[#F45252E5]/20"
        @click="showDisableReason"
      >
        <div class="bg-[#F45252E5]/90 p-2 text-center text-white">
          {{ selectionDisableReason }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { QTableColumn, useQuasar } from 'quasar';
import { clone, pipe, join, reject, createPipe, sortBy, prop, equals, map, isEmpty } from 'remeda';
import { computed, ref, watch, watchEffect } from 'vue';
import { isCommodityCombinationEq, isIdEq } from '@jgo-idea/common';
import { ShoppingCartGroupBuy } from '../../stores/group-buy-shopping-cart.store';
import { DeliverySetting } from '@jgo-idea/types';
import { random } from 'lodash-es';
import { ROUTE_NAME } from '../../router/router';

import TableItem from './item-select-creator-table-item.vue';

import { useVModels } from '@vueuse/core';
import { useRouter } from 'vue-router';

interface Props {
  /** 已選擇的團購 */
  modelValue: ShoppingCartGroupBuy[];
  /** 此 table 的 GroupBuy */
  groupBuyItem: ShoppingCartGroupBuy;
}
const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  (e: 'update:modelValue', value: Props['modelValue']): void;
}>();

const $q = useQuasar();
const router = useRouter();
const { modelValue } = useVModels(props, emit, { deep: true });

/** 設情況停用選擇功能 */
const selectionDisableReason = computed<string | undefined>(() => {
  // 排除自己
  const selectedGroupBuys = pipe(
    props.modelValue,
    clone,
    reject((item) => isIdEq(item.groupBuy, props.groupBuyItem.groupBuy)),
  );

  const result = pipe(
    undefined,
    // 自己不可合併結帳時，只要已選擇團購不為空則停用
    (reason?: string) => {
      if (!props.groupBuyItem.groupBuy.mergeableBilling &&
        selectedGroupBuys.length !== 0) {
        return '此團購不可合併結帳';
      }

      return reason;
    },

    // 檢查所有已選擇團購
    (reason) => {
      if (reason) return reason;

      for (const selectedItem of selectedGroupBuys) {
        // 已選擇團購包含與目前團購不同團購主
        if (selectedItem.groupBuy.creator?._id !== props.groupBuyItem.groupBuy.creator?._id) {
          return '不同團購主不可合併結帳';
        }

        // 已選擇團購有不可合併結帳者
        if (!selectedItem.groupBuy.mergeableBilling) {
          return '已選擇團購不可合併結帳';
        }

        // 運送方式取交集
        const haveIntersection = pipe(
          selectedItem.groupBuy.deliverySettings,
          map(prop('method')),
          (data) => props.groupBuyItem.groupBuy.deliverySettings.some(
            (item) => data.includes(item.method)
          ),
        );
        if (!haveIntersection) {
          return '此團購運費設定不相同';
        }
      }

      return undefined;
    },
  );

  return result;
});
function showDisableReason() {
  if (!selectionDisableReason.value) return;

  $q.notify({
    type: 'negative',
    message: selectionDisableReason.value,
    timeout: 1000,
  });
}

const selectedCommodities = ref<ShoppingCartGroupBuy['commodities']>([]);
/** 更新時檢查 modelValue 是否含自己，進行新增或更新 */
watch(selectedCommodities, (newCommodities: ShoppingCartGroupBuy['commodities']) => {
  const existed = modelValue.value.find((item) => isIdEq(item.groupBuy, props.groupBuyItem.groupBuy));

  /** 沒有商品，嘗試刪除自己 */
  if (newCommodities.length === 0) {
    /** 不存在，沒事 */
    if (!existed) return;

    /** 刪除 */
    const index = modelValue.value.indexOf(existed);
    modelValue.value.splice(index, 1);
    return;
  }

  /** 有商品，新增或更新 */

  /** 不存在，新增 */
  if (!existed) {
    modelValue.value.push({
      ...props.groupBuyItem,
      commodities: newCommodities,
    });
    return;
  }

  /** 更新 */
  existed.commodities = newCommodities;
}, {
  deep: true
});

/** 因為 item 資料變更（數量變更）時，selectedCommodities 的資料內容不會變
 * ，所以在 item 資料變更時，替換新資料並手動觸發一次 update:modelValue
 */
function handleItemUpdate(commodityItem: ShoppingCartGroupBuy['commodities'][number]) {
  const newValue = pipe(
    modelValue.value,
    clone,
    map((data) => {
      if (!isIdEq(data.groupBuy, props.groupBuyItem.groupBuy)) {
        return data;
      }

      // 替換對應 commodityItem
      const commodities = pipe(
        data.commodities,
        map((item) => isCommodityCombinationEq(item.combination.list, commodityItem.combination.list)
          ? commodityItem
          : item
        ),
        clone,
      );

      return {
        ...data,
        commodities
      };
    })
  );

  emit('update:modelValue', newValue);
}

function handleItemRemove(commodityItem: ShoppingCartGroupBuy['commodities'][number]) {
  const newValue = pipe(
    modelValue.value,
    clone,
    map((data) => {
      if (!isIdEq(data.groupBuy, props.groupBuyItem.groupBuy)) {
        return data;
      }

      // 刪除對應 commodityItem
      const commodities = pipe(
        data.commodities,
        reject(
          (commodity) => isCommodityCombinationEq(commodity.combination.list, commodityItem.combination.list)
        ),
        clone,
      );

      return {
        ...data,
        commodities
      };
    }),
    /** 去除 commodities 為空的項目 */
    reject(({ commodities }) => isEmpty(commodities)),
  );

  emit('update:modelValue', newValue);
}

const groupBuy = computed(() => props.groupBuyItem.groupBuy);

const columns: QTableColumn[] = [
  {
    name: 'item',
    label: 'item',
    field: 'item',
  },
];

function getRowKey(item: ShoppingCartGroupBuy['commodities'][0]) {
  const result = pipe(
    [] as string[],
    (data) => {
      data.push(item.commodity._id);
      return data;
    },
    (data) => {
      const keys = item.combination.list.map((listItem) =>
        listItem.variant._id + listItem.attribute
      );
      data.push(...keys);
      return data;
    },
    join(','),
  );

  return result;
}

function handleContinueShopping() {
  router.replace({
    name: ROUTE_NAME.GROUP_BUY_DETAIL,
    params: {
      id: props.groupBuyItem.groupBuy._id,
    },
    // 強制更新 route，可以直接關閉 dialog
    query: {
      reload: random(0, 100),
    }
  });
}
</script>

<style scoped lang="sass">
:deep(.q-table)
  .q-table--col-auto-width
    padding: 0 0.2rem !important
    border-top: 1px solid #f1f1f1
</style>
