import { defineConstants } from "./define";
/** 此分類棄用，請在各 domain 下定義 DeliveryMethod。
 *
 * 目前內容為 group-buy 的 DeliveryMethod。
 */
/** 團購運送方式常數定義 */
export const { DELIVERY_METHOD_KV: DELIVERY_METHOD, DELIVERY_METHOD_MAP_BY_KEY, DELIVERY_METHOD_MAP_BY_VALUE, DELIVERY_METHOD_VALUES } = defineConstants([
    {
        key: 'HOME',
        value: 'home',
        label: '宅配',
    },
    {
        key: 'SELF_PICKUP',
        value: 'self-pickup',
        label: '自取',
    },
    {
        key: 'MY_SHIP_711_CASH_ON_DELIVERY',
        value: 'my-ship-711-cash-on-delivery',
        label: '交貨便 - 貨到付款',
    },
    {
        key: 'MY_SHIP_711_PICKUP',
        value: 'my-ship-711-pickup',
        label: '交貨便 - 取貨',
    },
], 'DELIVERY_METHOD');
