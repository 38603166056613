<template>
  <div class="flex-col gap-3 border border-[#F5F3EC]">
    <!-- header -->
    <div class=" text-xs bg-[#F5F3EC] text-[#636363] py-[0.375rem] px-4">
      {{ creator?.groupBuyName }}
    </div>

    <!-- 團購 -->
    <div class="flex-col gap-2 px-3">
      <div
        v-for="item in groupBuyList"
        :key="item.groupBuy._id"
        class=" border flex-col rounded flex-nowrap"
      >
        <div class="flex flex-nowrap">
          <q-img
            :src="item.imageSrc"
            class=" w-16 h-16 m-4 rounded"
          />

          <div class=" py-3 flex-1 text-sm text-[#636363]">
            <div class=" font-medium whitespace-pre-line">
              {{ item.groupBuy.name }}
            </div>

            <div class=" text-xs text-[#999898]">
              共 {{ item.itemQuantity }} 個項目
            </div>

            <div class=" mt-2">
              小計：NT${{ toPriceFormat(item.totalPrice) }}
            </div>
          </div>
        </div>

        <div class="flex flex-nowrap items-center bg-group-buy text-white px-4 py-2 rounded-b">
          <div class="text-sm font-medium">
            包材費用
          </div>
          <q-space />
          <div class="text-xs px-3">
            費用
          </div>

          <q-chip
            color="white"
            outline
            square
            class="m-0"
            :label="`$${item.groupBuy.packingFeeSetting.value}`"
          />
        </div>
      </div>
    </div>

    <div class=" flex-col flex-center pb-3">
      <div class="flex text-sm font-medium gap-4">
        <div class=" w-14 ">
          商品小計
        </div>
        <div class="w-14">
          NT${{ toPriceFormat(commodityTotalPrice) }}
        </div>
      </div>
      <div class="flex text-sm font-medium gap-4">
        <div class=" w-14 ">
          包材費用
        </div>
        <div class="w-14">
          NT${{ toPriceFormat(packingFeeTotalPrice) }}
        </div>
      </div>

      <div class=" text-[#999898] mt-2">
        依照訂購商品內容收取包材費用
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  StorageFile
} from '@jgo-idea/types';
import { toPriceFormat } from '@jgo-idea/common';
import { useVModels } from '@vueuse/core';
import { pipe, map, clone, reduce, first, prop, equals, createPipe } from 'remeda';
import { computed, ref, watch } from 'vue';
import { ShoppingCartGroupBuy } from '../../stores/group-buy-shopping-cart.store';

import FormItem from '../form-item.vue';

import placeholder from '../../assets/placeholder.jpg';
import { cond } from '@jgo-idea/common';

interface Props {
  /** 已選擇團購 */
  groupBuys: ShoppingCartGroupBuy[];
  commodityTotalPrice?: number;
  packingFeeTotalPrice?: number;
}
const props = withDefaults(defineProps<Props>(), {
  commodityTotalPrice: 0,
  packingFeeTotalPrice: 0,
});

const emit = defineEmits<{
  (e: 'update:deliverySetting', value: string): void;
  (e: 'update:commodityTotalPrice', value: string): void;
  (e: 'update:packingFeeTotalPrice', value: string): void;
}>();

const {
  commodityTotalPrice, packingFeeTotalPrice
} = useVModels(props, emit, { deep: true });

const groupBuyList = computed(() => pipe(
  props.groupBuys,
  clone,
  map((item) => {
    const totalPrice = pipe(
      item.commodities,
      reduce((acc, commodity) => {
        const price = commodity.combination.groupBuyPrice * commodity.quantity;
        acc += price;
        return acc;
      }, 0),
    );

    const packingFeePrice = cond(
      item.groupBuy.packingFeeSetting, [
      [
        createPipe(prop('method'), equals('per-order')),
        prop('value'),
      ],
      [
        createPipe(prop('method'), equals('per-item')),
        createPipe(
          prop('value'),
          (price) => pipe(
            item.commodities,
            reduce((acc, commodity) => {
              acc += price * commodity.quantity;
              return acc;
            }, 0),
          ),
        ),
      ],
      [
        createPipe(prop('method'), equals('none')),
        () => 0,
      ],
    ]) ?? 0;

    const imageSrc = pipe([],
      (list: StorageFile[]) => {
        if (item.groupBuy.cover) {
          list.unshift(item.groupBuy.cover);
        }

        if (item.groupBuy.images?.[0]) {
          list.unshift(item.groupBuy.images[0]);
        }

        if (item.groupBuy.commodities[0]?.combinations[0]?.image) {
          list.unshift(item.groupBuy.commodities[0]?.combinations[0]?.image);
        }

        return list;
      },
      map(prop('publicUrl')),
      (data) => {
        data.push(placeholder);
        return data;
      },
      first(),
    );

    const itemQuantity = pipe(
      item.commodities,
      reduce((acc, commodity) => {
        acc += commodity.quantity;
        return acc;
      }, 0),
    );

    return {
      ...item,
      totalPrice,
      packingFeePrice,
      imageSrc,
      itemQuantity,
    }
  }),
));

watch(groupBuyList, (list) => {
  commodityTotalPrice.value = pipe(
    list,
    reduce((acc, item) => acc + item.totalPrice, 0),
  );

  packingFeeTotalPrice.value = pipe(
    list,
    reduce((acc, item) => acc + item.packingFeePrice, 0),
  );
}, {
  immediate: true,
  deep: true,
});

const creator = computed(() => props.groupBuys[0]?.groupBuy.creator);

</script>

<style scoped lang="sass">
</style>
