<template>
  <q-card class="flex-col">
    <q-card-section class="flex-1 flex-center">
      請在新視窗中選擇門市資料
    </q-card-section>

    <q-card-section class="hidden">
      <form
        ref="formRef"
        method="post"
        action="https://seven.bgmotion.com.tw/mobilemap/default.aspx"
        target="frame"
      >
        <input
          type="hidden"
          name="eshopparid"
          value="935"
        >
        <input
          type="hidden"
          name="eshopid"
          value="001"
        >
        <input
          type="hidden"
          name="eshoppwd"
          value="presco123"
        >
        <input
          type="hidden"
          name="url"
          value="https://localhost:3000/cvs_callback"
        >
        <input
          type="hidden"
          name="tempvar"
          value=""
        >
        <input
          type="hidden"
          name="sid"
          value="1"
        >
        <input
          type="hidden"
          name="storecategory"
          value="3"
        >
        <input
          type="hidden"
          name="showtype"
          value="1"
        >
        <input
          type="hidden"
          name="storeid"
          value=""
        >
      </form>

      請在新視窗中選擇門市資料
    </q-card-section>

    <!-- <q-card-actions class=" flex-1 p-0">
      <iframe
        ref="iframeRef"
        :src="url"
        class=" fit border-none outline-none"
      />
    </q-card-actions> -->

    <q-card-actions>
      <q-btn
        label="取消"
        unelevated
        color="red"
        class="w-full"
        @click="emit('cancel')"
      />
    </q-card-actions>
  </q-card>
</template>

<script setup lang="ts">
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser"
import {
  onBeforeUnmount,
  onMounted, ref
} from 'vue';
import { Platform } from 'quasar'
import { useIntervalFn } from "@vueuse/core";

interface ShipData {
  ship: string;
  storeaddress: string;
  storeid: string;
  storename: string;
}

// interface Props {
//   label?: string;
// }
// const props = withDefaults(defineProps<Props>(), {
//   label: '',
// });

const emit = defineEmits<{
  'submit': [data: ShipData]
  'cancel': []
}>()

const formRef = ref<HTMLFormElement>();
const iframeRef = ref<HTMLIFrameElement>();

onMounted(() => {
  if (Platform.is.nativeMobile) {
    startWithApp();
    return;
  }

  startWithWeb();

  // iframeRef.value?.addEventListener('message', (event) => {
  //   console.log('🚀 ~ event:', event);
  // });
});

const url = 'https://seven.bgmotion.com.tw/mobilemap/';

function startWithApp() {
  const winRef = InAppBrowser.create(url, '_blank', {
    zoom: 'no',
  });

  const messageListener = winRef.on('message');
  messageListener.subscribe((event) => {
    if (!event.data.storeid) return;

    winRef?.close();
    emit('submit', event.data);
  });

  const exitListener = winRef.on('exit');
  exitListener.subscribe(() => {
    emit('cancel');
  });

  onBeforeUnmount(() => {
    winRef?.close();
  });
}

function startWithWeb() {
  const winRef = window.open('about:blank', 'frame');
  formRef.value?.submit();

  function handleMessage(data: MessageEvent<any>) {
    if (!data.data.storeid) return;

    winRef?.close();
    emit('submit', data.data);
  }

  window.addEventListener('message', handleMessage);
  onBeforeUnmount(() => {
    winRef?.close();
    window.removeEventListener('message', handleMessage);
  });

  // 偵測網頁是否被關閉
  const { pause } = useIntervalFn(() => {
    if (!winRef?.window) {
      emit('cancel');
      pause();
    }
  }, 500);
}

</script>

<style scoped lang="sass">
</style>
