export var NotifyType;
(function (NotifyType) {
    /** 通知用 */
    NotifyType["INFO"] = "info";
})(NotifyType || (NotifyType = {}));
export var NotifyBaseEvent;
(function (NotifyBaseEvent) {
    NotifyBaseEvent["STATUS_UPDATE"] = "status-update";
    NotifyBaseEvent["DELETE"] = "delete";
})(NotifyBaseEvent || (NotifyBaseEvent = {}));
export var NotifyOrderEvent;
(function (NotifyOrderEvent) {
    /** 倒數一天，尚未付款 */
    NotifyOrderEvent["NOT_PAID_YET"] = "not-paid-yet";
    NotifyOrderEvent["PAYMENT_COMPLETED"] = "payment-completed";
})(NotifyOrderEvent || (NotifyOrderEvent = {}));
export var FeatureOrder;
(function (FeatureOrder) {
    FeatureOrder["GROUP_BUY_ORDER"] = "group-buy-order";
    FeatureOrder["COURSE_ORDER"] = "course-order";
    FeatureOrder["FLEA_MARKET_ORDER"] = "flea-market-order";
})(FeatureOrder || (FeatureOrder = {}));
export var FeatureApplication;
(function (FeatureApplication) {
    FeatureApplication["CAREER_STANDARD_APPLICATION"] = "career-standard-application";
    FeatureApplication["CAREER_MODEL_APPLICATION"] = "career-model-application";
})(FeatureApplication || (FeatureApplication = {}));
