import { purry } from "remeda";
function _cond(value, pairs) {
    for (const [predicate, func] of pairs) {
        if (predicate(value)) {
            return func(value);
        }
    }
    return undefined;
}
export function cond() {
    // eslint-disable-next-line prefer-rest-params
    return purry(_cond, arguments);
}
/** 協助處理 remeda pipe 中的 promise
 *
 *
 * @example
 * // 原本每一個 async 後都要先 await 才能拿到 data
 * ```typescript
 * pipe(
 *   'data',
 *   async (data) => data,
 *   async (promise) => {
 *     const data = await promise;
 *   },
 * );
 *
 * // 用 then 包裝一下就不用了
 * pipe(
 *   'data',
 *   async (data) => data,
 *   then(async (data) => { }),
 * );
 * ```
 */
export function then(fn) {
    return async (a) => {
        return fn(await a);
    };
}
