import { defineConstants } from "./define";
export const { GROUP_BUY_STATISTICS_COMMODITY_STATUS_KV: GROUP_BUY_STATISTICS_COMMODITY_STATUS, GROUP_BUY_STATISTICS_COMMODITY_STATUS_MAP_BY_KEY, GROUP_BUY_STATISTICS_COMMODITY_STATUS_MAP_BY_VALUE, GROUP_BUY_STATISTICS_COMMODITY_STATUS_VALUES } = defineConstants([
    {
        key: 'PENDING',
        value: 'pending',
        label: '建立中',
    },
    {
        key: 'AVAILABLE',
        value: 'available',
        label: '完成',
    },
    {
        key: 'FAIL',
        value: 'fail',
        label: '失敗',
    },
], 'GROUP_BUY_STATISTICS_COMMODITY_STATUS');
