<template>
  <div class=" flex-col bg-white">
    <div
      class=" text-base p-3 text-center border-b"
      :class="colorClassMap.text"
    >
      {{ title }}
    </div>

    <div class="flex-col gap-6 p-6 flex-1">
      <form-item
        v-if="!props.hideBuyer"
        title="訂購人資料"
        caption="資料設定完成後僅能刪除或再次新增"
        class=" relative"
      >
        <template #title="scope">
          <div class="flex">
            {{ scope.title }}
            <q-space />
            <div
              class="text-[#198EE2]"
              @click="openBuyerCreateForm"
            >
              新增訂購人資料
            </div>
          </div>
        </template>

        <template #default>
          <div
            v-if="buyerInfoOptions.length !== 0"
            class="flex-col gap-1"
          >
            <radio-group
              v-model="form.buyer"
              color="white"
              :options="buyerInfoOptions"
            >
              <template #option="{ option }">
                <div
                  class="flex-col text-xs text-[#999898] flex-1 gap-1 py-4"
                  :class="{ 'text-white': option.selected }"
                >
                  <div
                    class="text-sm font-medium text-[#636363]"
                    :class="{ 'text-white': option.selected }"
                  >
                    {{ option.value.name }}
                  </div>
                  <div class="">
                    {{ option.value.phone }}
                  </div>
                  <div class="">
                    {{ option.value.email }}
                  </div>
                </div>

                <q-icon
                  name="do_not_disturb_on"
                  color="negative"
                  size="0.75rem"
                  class=" px-4 py-3 bg-white rounded shadow-sm"
                  @click="removeBuyerInfo(option.value)"
                />
              </template>
            </radio-group>
          </div>

          <div
            v-else
            class="border text-negative border-negative py-6 rounded text-center"
          >
            請新增訂購人資料
          </div>

          <q-inner-loading :showing="isBuyerInfosLoading" />
        </template>
      </form-item>

      <form-item
        v-if="!props.hideReceiver"
        title="收件人資料"
        caption="資料設定完成後僅能刪除或再次新增"
        class=" relative"
      >
        <template #title="scope">
          <div class="flex">
            {{ scope.title }}
            <q-space />
            <div
              class="text-[#198EE2]"
              @click="openReceiverCreateForm"
            >
              新增收件人資料
            </div>
          </div>
        </template>

        <template #default>
          <div
            v-if="receiverInfoOptions.length !== 0"
            class="flex-col gap-1"
          >
            <radio-group
              v-model="form.receiver"
              color="white"
              :options="receiverInfoOptions"
            >
              <template #option="{ option }">
                <div
                  class="flex-col text-xs text-[#999898] flex-1 gap-1 py-4"
                  :class="{ 'text-white': option.selected }"
                >
                  <div
                    class="text-sm font-medium text-[#636363]"
                    :class="{ 'text-white': option.selected }"
                  >
                    {{ option.value.name }}
                  </div>
                  <div class="">
                    {{ option.value.phone }}
                  </div>
                  <div class="">
                    {{ option.value.email }}
                  </div>
                  <div class="">
                    {{ option.value.address }}
                  </div>
                </div>

                <q-icon
                  name="do_not_disturb_on"
                  color="negative"
                  size="0.75rem"
                  class=" px-4 py-3 bg-white rounded shadow-sm"
                  @click="removeReceiverInfo(option.value)"
                />
              </template>
            </radio-group>
          </div>

          <div
            v-else
            class="border text-negative border-negative py-6 rounded text-center"
          >
            請新增收件人資料
          </div>

          <q-inner-loading :showing="isReceiverInfosLoading" />
        </template>
      </form-item>
    </div>

    <div class=" p-4 flex gap-4 bg-white">
      <q-btn
        label="取消"
        class=" shadow-base p-3 flex-1"
        :class="colorClassMap.text"
        unelevated
        @click="emit('cancel')"
      />
      <q-btn
        label="帶入資料"
        :color="props.color"
        class=" shadow-base p-3 flex-1 "
        unelevated
        @click="handleSubmit"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { instance } from '../common/api';
import { openBaseDialog, openUsingDialog } from '../common/utils-quasar';
import { clone, map, pipe } from 'remeda';
import to from 'await-to-js';
import { UserBuyerInfo, UserReceiverInfo } from '@jgo-idea/types';

import FormItem from '../components/form-item.vue';
import UserBuyerInfoCreateForm from '../components/user-buyer-info-create-form.vue';
import UserReceiverInfoCreateForm from '../components/user-receiver-info-create-form.vue';
import RadioGroup, { Option } from './radio-group.vue';

import { useAsyncState, useVModels } from '@vueuse/core';
import {
  useUserBuyerInfoApi, useUserReceiverInfoApi
} from '@jgo-idea/api';
import { useQuasar } from 'quasar';

type OmitCollectionMeta<Data> = Omit<Data, '_id' | 'owner' | 'timestamp'>

interface Props {
  buyer?: OmitCollectionMeta<UserBuyerInfo>;
  hideBuyer?: boolean;
  receiver?: OmitCollectionMeta<UserReceiverInfo>;
  hideReceiver?: boolean;
  color?: string;
}
const props = withDefaults(defineProps<Props>(), {
  buyer: () => ({
    name: '',
    email: '',
    phone: '',
  }),
  hideBuyer: false,
  receiver: () => ({
    name: '',
    email: '',
    phone: '',
    township: '',
    county: '',
    address: '',
  }),
  hideReceiver: false,
  color: 'group-buy',
});

const emit = defineEmits<{
  'update:buyer': [value: NonNullable<Props['buyer']>];
  'update:receiver': [value: NonNullable<Props['receiver']>];
  'cancel': [];
}>();

const colorClassMap = computed(() => ({
  text: `text-${props.color}`,
  bg: `bg-${props.color}`,
}));

const { buyer, receiver } = useVModels(props, emit);

const form = ref<{
  buyer?: UserBuyerInfo;
  receiver?: UserReceiverInfo;
}>({
  buyer: undefined,
  receiver: undefined,
});

const userBuyerInfoApi = useUserBuyerInfoApi(instance);
const userReceiverInfoApi = useUserReceiverInfoApi(instance);
const $q = useQuasar();

const title = computed(() => {
  const labels: string[] = [];

  if (!props.hideBuyer) {
    labels.push('訂購人');
  }
  if (!props.hideReceiver) {
    labels.push('收件人');
  }

  return `${labels.join('、')}資料`
});

const {
  state: buyerInfoOptions,
  isLoading: isBuyerInfosLoading,
  execute: refreshBuyerInfos
} = useAsyncState(
  async () => {
    const result = await userBuyerInfoApi.find({
      limit: 99,
      skip: 0,
    });

    const options: Option<UserBuyerInfo>[] = pipe(
      result.data,
      map((value) => ({
        value,
        class: 'items-center border rounded p-4',
        activeClass: colorClassMap.value.bg,
      })),
    );

    return options;
  },
  [],
  {
    resetOnExecute: false,
  }
);

function removeBuyerInfo(info: UserBuyerInfo) {
  openBaseDialog({
    title: `確定刪除`,
    message: `確定要刪除「${info.name}」訂購人資料嗎？`
  }).onOk(async (result) => {
    if (result !== 'y') return;

    const [error] = await to(
      userBuyerInfoApi.remove(info._id)
    );
    if (error) {
      $q.notify({
        type: 'negative',
        message: `刪除「${info.name}」訂購人資料失敗`
      });
      return;
    }
    refreshBuyerInfos();
  });
}

function openBuyerCreateForm() {
  const dialog = openUsingDialog(
    UserBuyerInfoCreateForm,
    {
      color: props.color,
      'onUpdate:modelValue'() {
        refreshBuyerInfos();
        dialog.hide();
      },
      onCancel() {
        dialog.hide();
      },
    },
    undefined,
    {
      // position: 'bottom',
      maximized: true,
      transitionShow: 'slide-up',
      transitionHide: 'slide-down',
    }
  );
}

const {
  state: receiverInfoOptions,
  isLoading: isReceiverInfosLoading,
  execute: refreshReceiverInfos
} = useAsyncState(
  async () => {
    const result = await userReceiverInfoApi.find({
      limit: 99,
      skip: 0,
    });

    const options: Option<UserReceiverInfo>[] = pipe(
      result.data,
      map((value) => ({
        value,
        class: 'items-center border rounded p-4',
        activeClass: colorClassMap.value.bg
      })),
    );

    return options;
  },
  [],
  {
    resetOnExecute: false,
  }
);

function removeReceiverInfo(info: UserReceiverInfo) {
  openBaseDialog({
    title: `確定刪除`,
    message: `確定要刪除「${info.name}」收件人資料嗎？`
  }).onOk(async (result) => {
    if (result !== 'y') return;

    const [error] = await to(
      userReceiverInfoApi.remove(info._id)
    );
    if (error) {
      $q.notify({
        type: 'negative',
        message: `刪除「${info.name}」收件人資料失敗`
      });
      return;
    }
    refreshReceiverInfos();
  });
}

function openReceiverCreateForm() {
  const dialog = openUsingDialog(
    UserReceiverInfoCreateForm,
    {
      color: props.color,
      'onUpdate:modelValue'() {
        refreshReceiverInfos();
        dialog.hide();
      },
      onCancel() {
        dialog.hide();
      },
    },
    undefined,
    {
      // position: 'bottom',
      maximized: true,
      transitionShow: 'slide-up',
      transitionHide: 'slide-down',
    }
  );
}

function handleSubmit() {
  const data = clone(form.value);

  if (data.buyer) {
    buyer.value = data.buyer;
  }
  if (data.receiver) {
    receiver.value = data.receiver;
  }
}
</script>

<style scoped lang="sass">
</style>
