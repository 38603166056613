import { Dialog, DialogChainObject, QDialog, QDialogOptions, QDialogProps } from "quasar";
import { ExtractComponentProps, ExtractComponentSlots } from "../types";
import BaseDialog from '../components/base-dialog.vue';
import { Component, h } from 'vue';
import { omit } from "remeda";

/** 可以將 Quasar Dialog Plugin 包裝為 Promise */
export function dialogPromisify<T = 'y' | 'n'>(dialog: DialogChainObject) {
  return new Promise<T>((resolve, reject) => {
    dialog
      .onOk(resolve)
      .onCancel(() => reject('cancel'))
      .onDismiss(() => reject('dismiss'));
  });
}

/** 將 Vue SFC 元件包装為 QDialog
 * 
 * @param component Vue SFC 元件
 * @param props SFC 內所有參數，包含 class、style、event 等等
 * @param slots SFC 插槽
 * @param dialogProps QDialog 原本參數
 * 
 * @example
 * ```typescript
 * const component = wrapWithDialog(BrandEditStatusForm, {
 *   data,
 *   onSuccess() {
 *     dialog.hide();
 *     handleEditSuccess();
 *   },
 * });
 *
 * const dialog = $q.dialog({ component });
 * ```
 * 
 * @example
 * ```typescript
 * $q.dialog({
 *   component: wrapWithDialog(
 *     BrandLog,
 *     {
 *       data,
 *       class: 'w-full'
 *     },
 *     {
 *       fullHeight: true,
 *     }
 *   ),
 * });
 * ```
 */
export function wrapWithDialog<T extends Component>(
  component: T,
  props?: ExtractComponentProps<T>,
  slots?: ExtractComponentSlots<T>,
  dialogProps?: QDialogProps,
) {
  return h(QDialog, {
    noRefocus: true,
    ...dialogProps,
  }, {
    default: () => h(component, props as any, slots as any)
  })
}

/** 使用 Quasar Dialog 開啟元件
 * 
 * @param component Vue SFC 元件
 * @param props SFC 內所有參數，包含 class、style、event 等等
 * @param slots SFC 插槽
 * @param dialogProps QDialog 原本參數
 * @returns 
 * 
 * @example
 * ```typescript
 * const dialog = openUsingDialog(BrandEditStatusForm, {
 *   data,
 *   onSuccess() {
 *     dialog.hide();
 *     handleEditSuccess();
 *   },
 * });
 * ```
 */
export function openUsingDialog<T extends Component>(
  component: T,
  props?: ExtractComponentProps<T>,
  slots?: ExtractComponentSlots<T>,
  dialogProps?: QDialogProps,
) {
  return Dialog.create({
    component: wrapWithDialog(
      component, props, slots, dialogProps
    ),
  });
}

/** 
 * 更簡單配合 Quasar $q.dialog 使用 BaseDialog
 * 
 * @example
 * ```typescript
 * // 原本要這樣寫
 * const componentProps: DialogProps = {
 *   message: `確定刪除「${brand.name}」項目嗎？`,
 * }
 * 
 * $q.dialog({
 *   component: BaseDialog,
 *   componentProps,
 * }))
 * 
 * // 不用引入 BaseDialog 與 $q，可以簡化成
 * openBaseDialog({
 *   message: '確定刪除「${brand.name}」項目嗎？',
 * })
 * ```
 */
export function openBaseDialog(
  props: ExtractComponentProps<typeof BaseDialog>,
  dialogProps: Omit<QDialogOptions, 'component'> = {
    focus: 'none',
  },
) {
  return Dialog.create({
    ...dialogProps,
    component: h(BaseDialog, props),
  });
}
