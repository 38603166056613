import { defineConstants } from "./define";
export const { ACCOUNT_ROLE_KV, ACCOUNT_ROLE_MAP_BY_KEY, ACCOUNT_ROLE_MAP_BY_VALUE, ACCOUNT_ROLE_VALUES } = defineConstants([
    {
        key: 'ADMIN',
        value: 'admin',
        label: '管理者',
        description: '晶采員工',
    },
    {
        key: 'BASIC',
        value: 'basic',
        label: '使用者',
        description: '帳號預設值，基礎使用者，一般買家',
    },
], 'ACCOUNT_ROLE');
