import { useRequest } from './utils';
export function useUserApi(instance) {
    const request = useRequest(instance);
    function getInfo() {
        return request({
            method: 'get',
            url: `/api/v1/user/self`,
        });
    }
    function updateInfo(param) {
        return request({
            method: 'patch',
            url: `/api/v1/user/info`,
            data: param,
        });
    }
    /** 停用，遷移至自身 domain api 下 */
    async function getGroupBuys() {
        return request({
            method: 'get',
            url: `/api/v1/user/group-buys`,
        });
    }
    /** 停用，遷移至自身 domain api 下 */
    async function getGroupBuyCreator() {
        return request({
            method: 'get',
            url: `/api/v1/user/group-buy-creator`,
        });
    }
    /** 停用，遷移至自身 domain api 下 */
    async function updateGroupBuyCreator(parma) {
        return request({
            method: 'patch',
            url: `/api/v1/user/group-buy-creator`,
            data: parma,
        });
    }
    /** 停用，遷移至自身 domain api 下 */
    async function putGroupBuyCreatorApplicationForm(data) {
        return request({
            method: 'put',
            url: `/api/v1/user/group-buy-creator/application-form`,
            data,
        });
    }
    /** 停用，遷移至自身 domain api 下 */
    async function getGroupBuyCreatorApplicationForm() {
        return request({
            method: 'get',
            url: `/api/v1/user/group-buy-creator/application-form`,
        });
    }
    return {
        getInfo,
        /** 更新個人資訊 */
        updateInfo,
        /** 取得自己的所有團購 */
        getGroupBuys,
        /** 取得團購主資料 */
        getGroupBuyCreator,
        /** 更新團購主資料 */
        updateGroupBuyCreator,
        /** 新增或變更團購主申請單 */
        putGroupBuyCreatorApplicationForm,
        /** 取得團購主申請單 */
        getGroupBuyCreatorApplicationForm,
    };
}
