import { piped, pipe, reduce, add } from 'remeda';
/** 取得二手訂單價格統計資料 */
export const getFleaMarketOrderPriceTotalData = piped((order) => {
    const totalPrice = pipe(order.commodities, reduce((acc, commodity) => {
        const price = commodity.list.reduce((acc, item) => acc + item.price, 0);
        return acc + price;
    }, 0), add(order.deliverySetting.cost));
    return {
        totalPrice,
    };
});
