<template>
  <div class="flex-col bg-white rounded-t-lg">
    <q-form
      class="flex-col flex-1"
      @submit="handleSubmit"
    >
      <div
        class=" text-base p-3 text-center border-b"
        :class="colorClassMap.text"
      >
        新增訂購人資料
      </div>

      <form-item
        title="訂購人資料"
        class=" px-5 py-4 flex-1"
      >
        <div class="border !border-[#DDD] rounded">
          <q-input
            v-model="form.name"
            v-bind="groupFieldDefaultProp"
            label="姓名"
            :rules="notEmptyRule"
          />

          <q-separator />
          <q-input
            v-model="form.phone"
            v-bind="groupFieldDefaultProp"
            label="行動電話"
            :rules="notEmptyRule"
          />

          <q-separator />
          <q-input
            v-model="form.email"
            v-bind="groupFieldDefaultProp"
            label="電子信箱"
            :rules="emailRule"
          />
        </div>
      </form-item>

      <div class="flex px-5 py-4 gap-3 border-t">
        <q-btn
          class=" btn-shadow"
          :class="colorClassMap.text"
          unelevated
          label="取消"
          @click="emit('cancel')"
        />
        <q-btn
          class=" btn-shadow flex-[2]"
          unelevated
          :color="props.color"
          label="保存資料"
          type="submit"
        />
      </div>
    </q-form>
  </div>
</template>

<script setup lang="ts">
import to from 'await-to-js';
import { computed, ref } from 'vue';
import { instance } from '../common/api';
import {
  createRule, notEmpty, isEmail
} from '../common/validator';

import FormItem from './form-item.vue';

import { useUserBuyerInfoApi } from '@jgo-idea/api';
import { UserBuyerInfo } from '@jgo-idea/types';
import { useVModel } from '@vueuse/core';
import { useQuasar } from 'quasar';


interface Props {
  modelValue?: UserBuyerInfo;
  color?: string;
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  color: 'group-buy',
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: NonNullable<Props['modelValue']>): void;
  (e: 'cancel'): void;
}>();

const modelValue = useVModel(props, 'modelValue');
const userBuyerInfoApi = useUserBuyerInfoApi(instance);
const $q = useQuasar();

const colorClassMap = computed(() => ({
  text: `text-${props.color}`,
  bg: `bg-${props.color}`,
}));

const groupFieldDefaultProp = {
  color: props.color,
  hideBottomSpace: true,
  borderless: true,
  class: 'mx-3',
}

const form = ref({
  name: '',
  phone: '',
  email: '',
})

const notEmptyRule = createRule([notEmpty], '不可為空');
const emailRule = createRule([isEmail], '必須為 E-mail');

async function handleSubmit() {
  $q.loading.show();
  const [error, result] = await to(
    userBuyerInfoApi.create(form.value),
  );
  $q.loading.hide();

  if (error) {
    $q.notify({
      type: 'negative',
      message: `新增訂購人資料失敗 : ${error.message}`,
    });
    return;
  }

  modelValue.value = result;
  return result;
}
</script>

<style scoped lang="sass">
:deep(.q-field__bottom)
  padding-bottom: 0.75rem
  border-top: 1px solid rgba(#444, 0.1)
</style>
