<template>
  <div class="flex flex-col flex-nowrap">
    <!-- 團購項目清單 -->
    <div class=" p-4">
      <group-buy-price-totalizer
        v-model:commodity-total-price="totalResult.commodity"
        v-model:packing-fee-total-price="totalResult.packingFee"
        :group-buys="props.groupBuys"
        class=" rounded"
      />
    </div>

    <!-- 選項 -->
    <div class=" p-5 flex flex-col flex-nowrap gap-6">
      <!-- 運送方式 -->
      <form-item title="運送方式">
        <q-item
          v-for="option, i in deliveryOptions"
          :key="i"
          v-ripple
          tag="label"
          class=" rounded border mb-[0.375rem] py-4"
          :active="deliveryMethod === option.method"
          active-class="option-item bg-group-buy text-white"
        >
          <q-item-section side>
            <q-radio
              v-model="deliveryMethod"
              :val="option.method"
              color="white"
            />
          </q-item-section>

          <q-item-section class=" flex flex-row !justify-start items-center flex-nowrap gap-4">
            <q-img
              :src="option.icon"
              class=" w-10"
            />
            <q-item-label>{{ option.label }}</q-item-label>
          </q-item-section>

          <q-item-section
            side
            class=" flex flex-row !items-center gap-2"
          >
            <div class="option-item-text text-xs">
              費用
            </div>
            <q-chip
              :label="`$${option.cost}`"
              outline
              square
              color="group-buy"
              class=" justify-center"
              :class="{ 'text-white': deliveryMethod === option.method }"
            />
          </q-item-section>
        </q-item>
      </form-item>

      <!-- 付款方式 -->
      <form-item title="付款方式">
        <q-item
          v-if="is711Ship"
          v-ripple
          tag="label"
          class=" rounded border mb-[0.375rem] py-0"
          active
          dense
          active-class="option-item bg-group-buy text-white"
        >
          <q-item-section side>
            <q-radio
              :model-value="'true'"
              val="true"
              color="white"
            />
          </q-item-section>

          <q-item-section>
            <q-item-label>
              貨到付款
            </q-item-label>
          </q-item-section>
        </q-item>

        <q-item
          v-else
          v-ripple
          tag="label"
          class=" rounded border mb-[0.375rem] py-0"
          active
          dense
          active-class="option-item bg-group-buy text-white"
        >
          <q-item-section side>
            <q-radio
              :model-value="'true'"
              val="true"
              color="white"
            />
          </q-item-section>

          <q-item-section>
            <q-item-label>
              轉帳匯款
            </q-item-label>
          </q-item-section>
        </q-item>
      </form-item>
    </div>

    <q-space />

    <div class=" bg-white">
      <div
        class="flex flex-nowrap items-center py-4 px-8
              bg-[#F5F3EC] text-group-buy text-xs font-medium"
        placeholder=""
      >
        <div class="flex-center gap-2">
          <q-icon
            name="payments"
            size="1rem"
          />
          總金額
        </div>
        <q-space />
        <div class="text-xs">
          NT${{ toPriceFormat(props.totalPrice) }}
        </div>
      </div>

      <!-- 按鈕 -->
      <div class="flex p-5 gap-4">
        <q-btn
          label="上一步"
          padding="1rem"
          unelevated
          class="flex-1 text-group-buy shadow-base"
          @click="emit('back')"
        />
        <q-btn
          label="下一步"
          padding="1rem"
          color="group-buy"
          class="flex-[2] "
          @click="next()"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  DeliveryMethod,
  GroupBuyPackingFeeCalculationMethod,
  DELIVERY_METHOD_MAP_BY_VALUE
} from '@jgo-idea/types';
import { useVModels } from '@vueuse/core';
import {
  pipe, map, flatMap, uniqBy,
  reduce, intersectionWith,
  hasAtLeast
} from 'remeda';
import { computed, ref, watch } from 'vue';
import { ShoppingCartGroupBuy } from '../../stores/group-buy-shopping-cart.store';
import { toPriceFormat } from '@jgo-idea/common';

import FormItem from '../form-item.vue';
import GroupBuyPriceTotalizer from './group-buy-price-totalizer.vue';

import placeholder from '../../assets/placeholder.jpg';

import seven from '../../assets/7-eleven.svg';
import delivery from '../../assets/delivery.svg';
import shipping from '../../assets/shipping-product.svg';

import { useQuasar } from 'quasar';


interface Props {
  /** 要結帳的團購 */
  groupBuys: ShoppingCartGroupBuy[];
  deliveryMethod?: DeliveryMethod,
  totalPrice: number;
}
const props = withDefaults(defineProps<Props>(), {
  deliveryMethod: undefined,
});

const emit = defineEmits<{
  (e: 'update:deliveryMethod', value: Props['deliveryMethod']): void;
  (e: 'back'): void;
  (e: 'next'): void;
}>();

const $q = useQuasar();

const deliveryIconMap: Record<DeliveryMethod, string> = {
  'home': delivery,
  'my-ship-711-cash-on-delivery': seven,
  'my-ship-711-pickup': seven,
  'self-pickup': shipping,
}
const deliveryOptions = computed(() => {
  const allDeliverySettings = pipe(
    props.groupBuys,
    flatMap((item) => item.groupBuy.deliverySettings),
  );

  /** 對 method 取交集後，取 cost 最大者 */
  const result = pipe(
    props.groupBuys,
    map((item) => item.groupBuy.deliverySettings),
    /** 取 method 交集 */
    (settingsList) => {
      if (!hasAtLeast(settingsList, 1)) {
        return [];
      }

      const result = reduce(settingsList, (acc, settings) => {
        return intersectionWith(
          acc, settings, (a, b) => a.method === b.method,
        );
      }, settingsList[0]);

      return result;
    },
    map((item) => item.method),
    uniqBy((item) => item),
    map((method) => {
      const items = allDeliverySettings.filter((item) => item.method === method);
      const cost = Math.max(...items.map((item) => item.cost));

      const info = DELIVERY_METHOD_MAP_BY_VALUE[method];

      return {
        icon: deliveryIconMap[method],
        label: info.label,
        method,
        cost
      }
    }),
  );

  return result;
});

const deliveryMethod = ref(
  /** 檢查可用選項中是否有初始值 */
  pipe(
    props.deliveryMethod,
    (method) => {
      const hasValue = deliveryOptions.value.some((option) =>
        option.method === method
      );
      return hasValue ? method : undefined;
    }
  ),
);
watch(deliveryMethod, (value) => {
  emit('update:deliveryMethod', value);
}, { immediate: true });

const is711Ship = computed(() => deliveryMethod.value === 'my-ship-711-cash-on-delivery');

const totalResult = ref({
  commodity: 0,
  packingFee: 0,
});

function next() {
  if (!deliveryMethod.value) {
    $q.notify({
      type: 'negative',
      message: '請選擇運送方式'
    });
    return;
  }

  emit('next');
}
</script>

<style scoped lang="sass">
:deep(.option-item)
  &.q-item--active
    .q-badge
      color: white !important
  .option-item-text
    color: white
</style>
