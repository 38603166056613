import {
  createRouter,
  createWebHistory, RouteRecordRaw
} from 'vue-router'
import {
  accountRoute, ACCOUNT_ROUTE_NAME
} from './account.router';
import {
  groupBuyRoutes, GROUP_BUY_ROUTE_NAME
} from './group-buy.router';
import {
  courseRoutes, COURSE_ROUTE_NAME
} from './course.router';
import {
  fleaMarketRoutes, FLEA_MARKET_ROUTE_NAME
} from './flea-market.router';
import {
  artPieceRoutes, ART_PIECE_ROUTE_NAME
} from './art-piece.router';
import {
  CAREER_ROUTE_NAME, careerRoutes
} from './career.router';
import {
  CAREER_MODEL_ROUTE_NAME, careerModelRoutes
} from './career-model.router';
import {
  CAREER_STANDARD_ROUTE_NAME, careerStandardRoutes
} from './career-standard.router';

import { ValueOf } from 'type-fest';
import { until } from '@vueuse/core';
import { BRAND_ROUTE_NAME, brandRoutes } from './brand.router';

import { useUserStore } from '../stores/user.store';
import { useMainStore } from '../stores/main.store';
import { useQuasar } from 'quasar';

declare module 'vue-router' {
  interface Route {
    name?: string | null
  }

  interface RouteMeta {
    mainColorName?: string;
    header?: {
      class?: string;
      title: string;
      hideBackBtn?: boolean;
    };
    transitionName?: string;
    hideNavTabs?: boolean;
  }
}

export const ROUTE_NAME = {
  HOME: 'home',
  COMMODITY_DETAIL: 'commodity-detail',

  ...ACCOUNT_ROUTE_NAME,
  ...BRAND_ROUTE_NAME,
  ...GROUP_BUY_ROUTE_NAME,
  ...COURSE_ROUTE_NAME,
  ...FLEA_MARKET_ROUTE_NAME,
  ...ART_PIECE_ROUTE_NAME,
  ...CAREER_ROUTE_NAME,
  ...CAREER_MODEL_ROUTE_NAME,
  ...CAREER_STANDARD_ROUTE_NAME,
} as const;
export type RouteName = ValueOf<typeof ROUTE_NAME>;

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: {
      name: ROUTE_NAME.HOME,
    }
  },
  {
    path: `/home`,
    name: ROUTE_NAME.HOME,
    component: () => import('../views/the-home.vue'),
  },
  {
    path: `/commodity/detail/:id`,
    name: ROUTE_NAME.COMMODITY_DETAIL,
    component: () => import('../views/the-commodity-detail.vue'),
  },

  accountRoute,

  ...brandRoutes,
  ...groupBuyRoutes,
  ...courseRoutes,
  ...fleaMarketRoutes,
  ...artPieceRoutes,
  ...careerRoutes,
  ...careerModelRoutes,
  ...careerStandardRoutes,

  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const $q = useQuasar();
  const mainStore = useMainStore();
  const userStore = useUserStore();

  // 如果 deep link 有值，則為最優先
  if (mainStore.deepLinkUrl) {
    const path = mainStore.deepLinkUrl;
    mainStore.setDeepLinkUrl();
    return next({ path })
  }

  if (to.name === ROUTE_NAME.ACCOUNT_REQUIRED_SETTING) {
    return next();
  }

  /** 未登入的使用者，還是可以查看其他頁面 */
  if (!userStore.userInfo) {
    return next();
  }

  /** 檢查是否缺少帳號基本資料，強制使用者輸入 */
  if (userStore.isUserInfoLoading) {
    await until(() => userStore.isUserInfoLoading).toBe(false);

    if (!userStore.userInfo?.nickName) {
      return next({
        name: ROUTE_NAME.ACCOUNT_REQUIRED_SETTING,
      })
    }
  }

  next();
})

export default router
