import { COURSE_ACTUAL_STATE_MAP_BY_KEY, } from '@jgo-idea/types';
import { piped, pipe, reduce, unique } from 'remeda';
import dayjs from 'dayjs';
/** 判斷課程真實狀態。例如：未開始、團購中、已結束 */
export const getCourseStateInfo = piped(({ status, startAt, endAt }) => {
    if (status === 'pending-review') {
        return COURSE_ACTUAL_STATE_MAP_BY_KEY.PENDING_REVIEW;
    }
    if (status === 'terminated') {
        return COURSE_ACTUAL_STATE_MAP_BY_KEY.OVER;
    }
    const currentTimestamp = dayjs().unix();
    if (status === 'reject') {
        return COURSE_ACTUAL_STATE_MAP_BY_KEY.REJECTED;
    }
    if (currentTimestamp > endAt) {
        return COURSE_ACTUAL_STATE_MAP_BY_KEY.OVER;
    }
    if (currentTimestamp < startAt) {
        return COURSE_ACTUAL_STATE_MAP_BY_KEY.NOT_STARTED;
    }
    return COURSE_ACTUAL_STATE_MAP_BY_KEY.IN_PROGRESS;
});
/** 取得課程價格上下限 */
export function getCoursePriceRange(data) {
    return pipe(data.plans, reduce((acc, plan) => {
        plan.expenses.forEach((expense) => {
            acc[0] = Math.min(acc[0], expense.amount);
            acc[1] = Math.max(acc[1], expense.amount);
        });
        return acc;
    }, [Infinity, 0]), unique());
}
