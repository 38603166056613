import { defineConstants } from "./define";
export const { CAREER_EMPLOYMENT_STATUS_KV: CAREER_EMPLOYMENT_STATUS, CAREER_EMPLOYMENT_STATUS_MAP_BY_KEY, CAREER_EMPLOYMENT_STATUS_MAP_BY_VALUE, CAREER_EMPLOYMENT_STATUS_VALUES } = defineConstants([
    {
        key: 'UNEMPLOYED',
        value: 'unemployed',
        label: '待業中',
    },
    {
        key: 'WORKING',
        value: 'working',
        label: '在職',
    },
], 'CAREER_EMPLOYMENT_STATUS');
