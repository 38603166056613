import { defineConstants } from "./define";
/** 二手真實狀態 */
export const { FLEA_MARKET_ACTUAL_STATE_KV: FLEA_MARKET_ACTUAL_STATE, FLEA_MARKET_ACTUAL_STATE_MAP_BY_KEY, FLEA_MARKET_ACTUAL_STATE_MAP_BY_VALUE, FLEA_MARKET_ACTUAL_STATE_VALUES } = defineConstants([
    {
        key: 'PENDING_REVIEW',
        value: 'pending-review',
        label: '待審核',
        colorName: 'negative',
    },
    {
        key: 'OVER',
        value: 'over',
        label: '已結束',
        colorName: 'grey',
    },
    {
        key: 'REJECTED',
        value: 'rejected',
        label: '駁回',
        colorName: 'amber',
    },
    {
        key: 'NOT_STARTED',
        value: 'not-started',
        label: '未開始',
        colorName: 'blue',
    },
    {
        key: 'IN_PROGRESS',
        value: 'in-progress',
        label: '販售中',
        colorName: 'green',
    },
], 'FLEA_MARKET_ACTUAL_STATE');
/** 二手運送方式常數定義 */
export const { FLEA_MARKET_SALE_DELIVERY_METHOD_KV: FLEA_MARKET_SALE_DELIVERY_METHOD, FLEA_MARKET_SALE_DELIVERY_METHOD_MAP_BY_KEY, FLEA_MARKET_SALE_DELIVERY_METHOD_MAP_BY_VALUE, FLEA_MARKET_SALE_DELIVERY_METHOD_VALUES } = defineConstants([
    {
        key: 'HOME',
        value: 'home',
        label: '宅配',
    },
    {
        key: 'SELF_PICKUP',
        value: 'self-pickup',
        label: '面交自取',
    },
    {
        key: 'MY_SHIP_711_CASH_ON_DELIVERY',
        value: 'my-ship-711-cash-on-delivery',
        label: '交貨便 - 貨到付款',
    },
    {
        key: 'MY_SHIP_711_PICKUP',
        value: 'my-ship-711-pickup',
        label: '交貨便 - 取貨',
    },
], 'FLEA_MARKET_SALE_DELIVERY_METHOD');
/** 二手付款方式常數定義 */
export const { FLEA_MARKET_SALE_PAYMENT_METHOD_KV: FLEA_MARKET_SALE_PAYMENT_METHOD, FLEA_MARKET_SALE_PAYMENT_METHOD_MAP_BY_KEY, FLEA_MARKET_SALE_PAYMENT_METHOD_MAP_BY_VALUE, FLEA_MARKET_SALE_PAYMENT_METHOD_VALUES } = defineConstants([
    {
        key: 'REMITTANCE',
        value: 'remittance',
        label: '匯款',
    },
    {
        key: 'CASH_ON_DELIVERY_IN_STORE',
        value: 'cash-on-delivery-in-store',
        label: '門市貨到付款',
    },
], 'FLEA_MARKET_SALE_PAYMENT_METHOD');
export const { FLEA_MARKET_SALE_STATUS_KV: FLEA_MARKET_SALE_STATUS, FLEA_MARKET_SALE_STATUS_MAP_BY_KEY, FLEA_MARKET_SALE_STATUS_MAP_BY_VALUE, FLEA_MARKET_SALE_STATUS_VALUES } = defineConstants([
    {
        key: 'PENDING_REVIEW',
        value: 'pending-review',
        label: '待審核',
        description: '需管理方批准',
    },
    {
        key: 'AVAILABLE',
        value: 'available',
        label: '可用',
        description: '自動依照設定時間開始或結束',
    },
    {
        key: 'TERMINATED',
        value: 'terminated',
        label: '提早終止',
        description: '二手賣家可以提早結束二手',
    },
    {
        key: 'REJECT',
        value: 'reject',
        label: '駁回',
        description: '駁回二手拍賣，二手賣家只能重啟新二手',
    },
], 'FLEA_MARKET_SALE_STATUS');
export const { FLEA_MARKET_SALE_CONDITION_KV: FLEA_MARKET_SALE_CONDITION, FLEA_MARKET_SALE_CONDITION_MAP_BY_KEY, FLEA_MARKET_SALE_CONDITION_MAP_BY_VALUE, FLEA_MARKET_SALE_CONDITION_VALUES } = defineConstants([
    {
        label: '全新',
        key: 'BRAND_NEW',
        value: 'brand-new',
        caption: '從未使用過，就跟新買的一樣。',
    },
    {
        label: '幾乎全新',
        key: 'ALMOST_NEW',
        value: 'almost-new',
        caption: '幾乎跟全新一樣， 9 成新以上。',
    },
    {
        label: '狀況不錯',
        key: 'GOOD_CONDITION',
        value: 'good-condition',
        caption: '保存的不錯，7~8 成新左右。',
    },
    {
        label: '些微瑕疵',
        key: 'MINOR_FLAWS',
        value: 'minor-flaws',
        caption: '有使用的痕跡或瑕疵，5~6 成新左右。',
    },
    {
        label: '尚可',
        key: 'OKAY_ISH',
        value: 'okay-ish',
        caption: '經常使用，3~5 成新左右。',
    },
    {
        label: '可以將就',
        key: 'ACCEPTABLE',
        value: 'acceptable',
        caption: '歷盡滄桑歲月，3 成新以下。',
    },
], 'FLEA_MARKET_SALE_CONDITION');
