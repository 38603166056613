<template>
  <div class="flex-col bg-white rounded-t-lg">
    <q-form
      class="flex-col flex-1"
      @submit="handleSubmit"
    >
      <div
        class=" sticky top-0 text-base p-3 text-center border-b bg-white z-10"
        :class="colorClassMap.text"
      >
        新增交貨便收件資料
      </div>

      <form-item
        title="交貨便收件資料"
        class="px-5 py-4"
      >
        <q-btn
          class=" btn-shadow w-full"
          unelevated
          label="選擇門市"
          @click="handleSelectStore"
        />

        <div class="border !border-[#DDD] rounded mt-3">
          <q-input
            v-model="form.storeName"
            v-bind="groupFieldDefaultProp"
            label="門市店名"
            :rules="notEmptyRule"
          />

          <q-separator />
          <q-input
            v-model="form.storeNumber"
            v-bind="groupFieldDefaultProp"
            label="門市店號"
            :rules="notEmptyRule"
          />

          <q-separator />
          <q-input
            v-model="form.storeAddress"
            v-bind="groupFieldDefaultProp"
            label="門市地址"
            :rules="notEmptyRule"
          />
        </div>
      </form-item>

      <form-item
        title="交貨便收件資料"
        class="flex-col px-5 py-4 gap-3"
      >
        <div class="border !border-[#DDD] rounded">
          <q-input
            v-model="form.receiverName"
            v-bind="groupFieldDefaultProp"
            label="收件人名稱"
            :rules="notEmptyRule"
          />

          <q-separator />
          <q-input
            v-model="form.receiverPhone"
            v-bind="groupFieldDefaultProp"
            label="電話"
            :rules="notEmptyRule"
          />
        </div>
      </form-item>

      <q-space />

      <div class=" flex px-5 py-4 gap-3 bg-white">
        <q-btn
          class=" btn-shadow "
          :class="colorClassMap.text"
          unelevated
          label="取消"
          @click="emit('cancel')"
        />
        <q-btn
          class=" btn-shadow flex-[2]"
          unelevated
          :color="props.color"
          label="保存資料"
          type="submit"
        />
      </div>
    </q-form>
  </div>
</template>

<script setup lang="ts">
import to from 'await-to-js';
import { computed, ref } from 'vue';
import { instance } from '../common/api';
import {
  createRule, notEmpty, isEmail
} from '../common/validator';
import { UserBuyerInfo, UserMyShip711Info } from '@jgo-idea/types';
import { openUsingDialog } from '../common/utils-quasar';

import FormItem from './form-item.vue';
import EcmapForm from './ecmap-form.vue';

import { useUserMyShip711InfoApi } from '@jgo-idea/api';
import { useVModel } from '@vueuse/core';
import { useQuasar } from 'quasar';

interface Props {
  modelValue?: UserMyShip711Info;
  color?: string;
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  color: 'group-buy',
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: NonNullable<Props['modelValue']>): void;
  (e: 'cancel'): void;
}>();

const colorClassMap = computed(() => ({
  text: `text-${props.color}`,
  bg: `bg-${props.color}`,
}));

const modelValue = useVModel(props, 'modelValue');
const userMyShip711InfoApi = useUserMyShip711InfoApi(instance);
const $q = useQuasar();

const groupFieldDefaultProp = {
  color: props.color,
  hideBottomSpace: true,
  borderless: true,
  class: 'mx-3',
}

const form = ref({
  storeName: '',
  storeNumber: '',
  storeAddress: '',
  receiverName: '',
  receiverPhone: '',
})

const notEmptyRule = createRule([notEmpty], '不可為空');

async function handleSubmit() {
  $q.loading.show();
  const [error, result] = await to(
    userMyShip711InfoApi.create(form.value),
  );
  $q.loading.hide();

  if (error) {
    $q.notify({
      type: 'negative',
      message: `新增訂購人資料失敗 : ${error.message}`,
    });
    return;
  }

  modelValue.value = result;
  return result;
}

function handleSelectStore() {
  const dialog = openUsingDialog(
    EcmapForm,
    {
      onCancel() {
        dialog.hide();
      },
      onSubmit(data) {
        form.value.storeAddress = data.storeaddress;
        form.value.storeNumber = data.storeid;
        form.value.storeName = data.storename;
        dialog.hide();

        $q.notify({
          type: 'positive',
          message: `已取得門市資訊`
        });
      },
    },
    undefined,
  );
}
</script>

<style scoped lang="sass">
:deep(.q-field__bottom)
  padding-bottom: 0.75rem
  border-top: 1px solid rgba(#444, 0.1)
</style>
