import { RouteRecordRaw } from 'vue-router'
import { defineConstants } from '@jgo-idea/types';

export const {
  BRAND_ROUTE_NAME_KV: BRAND_ROUTE_NAME,
  BRAND_ROUTE_NAME_LIST,
  BRAND_ROUTE_NAME_VALUES,
} = defineConstants(
  [
    {
      key: 'BRAND_DETAIL',
      value: 'brand-detail'
    },
    {
      key: 'BRAND_RANK',
      value: 'brand-rank'
    },
  ] as const,
  'BRAND_ROUTE_NAME'
);

export const brandRoutes: RouteRecordRaw[] = [
  {
    path: `/brand/detail/:id`,
    name: BRAND_ROUTE_NAME.BRAND_DETAIL,
    component: () => import('../views/the-brand-detail.vue'),
    meta: {
      mainColorName: 'brand'
    }
  },
  {
    path: `/brand-rank`,
    name: BRAND_ROUTE_NAME.BRAND_RANK,
    component: () => import('../views/the-brand-rank.vue'),
  },
]

