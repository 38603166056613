import { useRequest } from './utils';
export function useNotificationApi(instance) {
    const request = useRequest(instance);
    async function subscription(param) {
        return request({
            method: 'post',
            url: `/api/v1/notification/subscription`,
            data: param
        });
    }
    return {
        subscription,
    };
}
