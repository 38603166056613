<template>
  <div class="border-t">
    <q-tabs
      align="justify"
      class="tab bg-white text-[#636363] shadow-none py-1"
      indicator-color="transparent"
    >
      <transition-group-flex name="list-up">
        <!-- 首頁 -->
        <q-route-tab
          key="home"
          :to="getRoute('home')"
          class=" flex-grow-[1]"
          :active-class="activeClass"
        >
          <q-icon
            name="home"
            size="1.5rem"
            class=" mb-1"
          />
          <div class=" text-xs">
            首頁
          </div>
        </q-route-tab>

        <!-- 功能選單 -->
        <q-btn
          class=" !flex-grow-[1] h-full"
          no-caps
          :class="menuBtnClass"
          :active-class="activeClass"
          stack
          unelevated
          @click="toggleMenuBtnVisible()"
        >
          <q-icon
            name="apps"
            size="1.5rem"
            class=" mb-1"
          />
          <div class=" text-xs">
            功能選單
          </div>
        </q-btn>

        <q-btn
          v-if="prevFeatureBtnInfo?.menu"
          :key="prevFeatureBtnInfo.label"
          class=" !flex-grow-[1] h-full "
          no-caps
          :active-class="activeClass"
          stack
          unelevated
        >
          <q-icon
            :name="prevFeatureBtnInfo.iconName"
            size="1.5rem"
            class=" mb-1"
          />
          <div class=" text-xs">
            {{ prevFeatureBtnInfo.label }}功能
          </div>

          <component :is="prevFeatureBtnInfo.menu" />
        </q-btn>

        <!-- 會員功能 -->
        <q-route-tab
          key="account"
          :to="toAccount()"
          class=" flex-grow-[1]"
          :active-class="activeClass"
        >
          <q-icon
            name="person"
            size="1.5rem"
            class=" mb-1"
          />
          <div class=" text-xs">
            會員功能
          </div>
        </q-route-tab>
      </transition-group-flex>

      <q-menu
        v-model="menuBtnVisible"
        no-parent-event
        anchor="top middle"
        self="bottom middle"
        :offset="[0, 20]"
      >
        <div class="p-4">
          <div
            v-for="menuBtnList, i in menuBtnLists"
            :key="i"
            class=""
          >
            <q-btn
              v-for="btn in menuBtnList"
              :key="btn.to"
              stack
              unelevated
              class="px-6 py-2"
              :to="getRoute(btn.to)"
            >
              <q-icon
                :name="btn.iconName"
                :color="btn.iconColor"
                class=" mb-1"
              />
              {{ btn.label }}
            </q-btn>
          </div>
        </div>
      </q-menu>
    </q-tabs>
  </div>
</template>

<script
  setup
  lang="ts"
>
import { ref, type Component } from 'vue';
import { computed } from 'vue';
import {
  RouteName,
  ROUTE_NAME,
} from '../router/router';
import {
  FeatureName,
  FEATURE_MAP_BY_VALUE,
  FEATURE_MAP_BY_KEY,
} from '@jgo-idea/types';
import { QBtn, QMenu, useQuasar } from 'quasar';
import { chunk, map, pipe } from 'remeda';

import GroupBuyMenu from './the-nav-tabs/group-buy-menu.vue';
import CourseMenu from './the-nav-tabs/course-menu.vue';
import FleaMarketMenu from './the-nav-tabs/flea-market-menu.vue';
import ArtPieceMenu from './the-nav-tabs/art-piece-menu.vue';
import CareerStandardMenu from './the-nav-tabs/career-standard-menu.vue';
import CareerModelMenu from './the-nav-tabs/career-model-menu.vue';
import TransitionGroupFlex from './transition-group-flex.vue';

import { useRoute } from 'vue-router';
import { useUserStore } from '../stores/user.store';
import { useMainStore } from '../stores/main.store';
import { useToggle } from '@vueuse/core';

const $q = useQuasar();
const route = useRoute();
const userStore = useUserStore();
const mainStore = useMainStore();

function getRoute(name: RouteName) {
  return { name }
}

/** 每 3 個一組 */
const menuBtnLists = pipe(
  [
    FEATURE_MAP_BY_KEY.GROUP_BUY.key,
    FEATURE_MAP_BY_KEY.COURSE.key,
    FEATURE_MAP_BY_KEY.FLEA_MARKET.key,
    FEATURE_MAP_BY_KEY.ART_PIECE.key,
    FEATURE_MAP_BY_KEY.CAREER_STANDARD.key,
    FEATURE_MAP_BY_KEY.CAREER_MODEL.key,
  ] as const,
  map((name) => ({
    to: ROUTE_NAME[name],
    iconName: FEATURE_MAP_BY_KEY[name].iconName,
    iconColor: FEATURE_MAP_BY_KEY[name].colorName,
    label: FEATURE_MAP_BY_KEY[name].label,
  })),
  chunk(3),
);

const [menuBtnVisible, toggleMenuBtnVisible] = useToggle(false);

const menuBtnClass = computed(() => {
  const colorName = route.meta.mainColorName;
  if (!colorName) {
    return undefined;
  }

  return `text-${route.meta.mainColorName}`;
});

const featureInfoMap: {
  [K in FeatureName]?: {
    menu: Component;
  }
} = {
  'group-buy': {
    menu: GroupBuyMenu,
  },
  'course': {
    menu: CourseMenu,
  },
  'flea-market': {
    menu: FleaMarketMenu,
  },
  'art-piece': {
    menu: ArtPieceMenu,
  },
  'career-standard': {
    menu: CareerStandardMenu,
  },
  'career-model': {
    menu: CareerModelMenu,
  },
}

const prevFeatureBtnInfo = computed(
  () => !mainStore.prevFeature ? undefined : {
    ...FEATURE_MAP_BY_VALUE?.[mainStore.prevFeature],
    ...featureInfoMap?.[mainStore.prevFeature],
  },
);

const activeClass = computed(() =>
  prevFeatureBtnInfo.value?.colorName
    ? `text-${prevFeatureBtnInfo.value?.colorName}`
    : ''
);

function toAccount() {
  if (userStore.userInfo) {
    return getRoute('account-profile');
  }

  return getRoute('account-login');
}
</script>

<style
  scoped
  lang="sass"
>

:deep(.q-tab__indicator)
  height: 3px

.tab
  padding-bottom: constant(safe-area-inset-bottom)
  padding-bottom: env(safe-area-inset-bottom)
</style>
